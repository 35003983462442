import { createSelector } from '@reduxjs/toolkit';
import { PlanID } from '@shared/plan/models/enums';
import { AppState } from '@store';
import { ProfileState } from './state';

const selectFeature = (state: AppState): ProfileState => state.profile;

export class ProfileSelectors {
  public static profile = createSelector(
    selectFeature,
    (state) => state.profile
  );

  public static isFetching = createSelector(
    selectFeature,
    (state) => state.isFetching
  );

  public static isUpdating = createSelector(
    selectFeature,
    (state) => state.isUpdating
  );

  public static extensionLink = createSelector(
    selectFeature,
    (state) => state.extensionLink
  );

  public static isCloseToRecommendationLimit = createSelector(
    selectFeature,
    (state) => !!state.profile?.isCloseToRecommendationLimit
  );

  public static isFreePlan = createSelector(
    selectFeature,
    (state) => state.profile?.plan?.id === PlanID.FREE
  );
}
