import { instanceToPlain } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { map, Observable } from 'rxjs';
import { BaseEntityPlain } from '@shared/base-entity/models';
import { storeRef } from '@store';
import { EntityService } from '../base-entity/service';
import { Product, ProductsPaginationRequest } from './models';
import { ProductsPaginationResponse } from './models/products-pagination-response';

class ProductService extends EntityService<Product> {
  constructor() {
    super({
      endpoint: '/plugin/facade/products',
      entityName: 'product',
      entitySearchRequestConstructor: ProductsPaginationRequest
    });
  }

  public searchProducts(request: ProductsPaginationRequest): Observable<ProductsPaginationResponse> {
    return this.apiService
      .get<ProductsPaginationResponse>(
        this.endpoint,
        instanceToPlain(new ProductsPaginationRequest(omitBy(request, isUndefined)))
      )
      .pipe(
        map((response) => {
          const products = response.products.map((product) => ({
            ...product,
            id: product.shopify_id
          }));

          storeRef.dispatch(
            this.actions.loaded({ items: (products as unknown as Array<BaseEntityPlain<number>>) || [] })
          );

          return { ...response, products };
        })
      );
  }
}

export const productService = new ProductService();
