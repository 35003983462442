import { Button, ButtonGroup, Modal, TextContainer } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useTranslation } from '@shared/i18n';
import './delete-journey-modal.scss';

interface DeleteJourneyModalProps {
  onDeleteClick?: () => void;
  isActive: boolean;
  onCloseModal: () => void;
}

export function DeleteJourneyModal({ isActive, onCloseModal, onDeleteClick }: DeleteJourneyModalProps): ReactElement {
  const translate = useTranslation('SHARED.DELETE_JOURNEY_MODAL');

  const handleDeleteClick = (): void => {
    onCloseModal();
    onDeleteClick();
  };

  return (
    <Modal
      open={isActive}
      onClose={onCloseModal}
      title={translate('TEXT_SURE_TO_DELETE')}>
      <Modal.Section>
        <TextContainer>
          <p>{translate('TEXT_ACTION_UNDONE')}</p>
        </TextContainer>
      </Modal.Section>
      <Modal.Section>
        <div className='delete-journey-modal-buttons'>
          <ButtonGroup>
            <Button onClick={onCloseModal}>{translate('BUTTON_CANCEL')}</Button>
            <Button
              destructive
              onClick={handleDeleteClick}>
              {translate('BUTTON_DELETE')}
            </Button>
          </ButtonGroup>
        </div>
      </Modal.Section>
    </Modal>
  );
}
