import { TopBar, Frame, Heading, Button } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useTranslation } from '@shared/i18n';
import './page-frame.scss';

interface JourneyPageFrameProps {
  children: React.ReactNode;
  isChangesUnsaved: boolean;
  onDiscardClick?: () => void;
  onSaveClick?: () => void;
  isLoading: boolean;
}

export function JourneyPageFrame({
  children,
  onDiscardClick,
  onSaveClick,
  isChangesUnsaved,
  isLoading
}: JourneyPageFrameProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.JOURNEY_PAGE_FRAME');

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={
        <div className='journey-page-frame-tabs'>
          <div className='journey-page-frame-discard'>
            <Button onClick={onDiscardClick}>{translate('BUTTON_DISCARD')}</Button>
          </div>
          <Button
            loading={isLoading}
            onClick={onSaveClick}
            primary>
            {translate('BUTTON_SAVE')}
          </Button>
        </div>
      }
      searchField={<Heading>{isChangesUnsaved && translate('TEXT_UNSAVED_CHANGES')}</Heading>}
    />
  );

  return <Frame topBar={topBarMarkup}>{children}</Frame>;
}
