import React, { ReactElement } from 'react';
import { useController } from 'react-hook-form';
import { ColorPicker } from '@shared/color-picker';
import { FormInputProps } from '@shared/form-input';
import { colorPickerConfig } from './config';

export function FormColorPicker<TForm>({
  control,
  name,
  label
}: FormInputProps<TForm> & { label: string }): ReactElement {
  const { field } = useController({ control, name });

  const handleChange = (color: string): void => {
    field.onChange(color);
  };

  const handleBlur = (): void => {
    if (!field.value) {
      field.onChange(colorPickerConfig.defaultColor);
    }
  };

  return (
    <ColorPicker
      label={label}
      onBlur={handleBlur}
      color={field.value as string}
      onChange={handleChange}
    />
  );
}
