import { Select } from '@shopify/polaris';
import React, { ReactElement, useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { ReactComponent as Close } from '@assets/icons/close.svg';
import { FormWarning } from '@shared/form-warning';
import { useTranslation } from '@shared/i18n';
import { WeightExpressionComparisonType } from '@shared/journey';
import { IncludeType } from '@shared/journey/types/include-type';
import { WeightExpressionAttribute } from '@shared/journey/types/weight-attribute';
import { WeightingExpressionCondition } from '@shared/journey/types/weighting-expression-condition';
import { UpsertJourneyForm } from '../../../../../../shared/forms';
import { AttributesSearch } from '../attributes-search/attributes-search';
import { WeightExpressionTooltip } from '../weight-expression-tooltip';
import './weight-expression-field.scss';

interface WeightExpressionProps {
  control: Control<UpsertJourneyForm>;
  weightExpressionIndex: number;
  answerIndex: number;
  questionIndex: number;
  weightingStatementIndex: number;
  onDelete: (tagIndex: number) => void;
}

export function WeightExpressionField({
  control,
  weightExpressionIndex,
  answerIndex,
  questionIndex,
  weightingStatementIndex,
  onDelete
}: WeightExpressionProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_EXPRESSION_FIELD');

  const [shouldShowError, setShouldShowError] = useState(false);

  const { field: attributeField } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements.${weightingStatementIndex}.expressions.${weightExpressionIndex}.attribute`
  });

  const { field: titleField, fieldState } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements.${weightingStatementIndex}.expressions.${weightExpressionIndex}.value`
  });

  const { field: comparisonTypeTypeFiled } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements.${weightingStatementIndex}.expressions.${weightExpressionIndex}.comparisonType`
  });

  const { field: includeTypeFiled } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements.${weightingStatementIndex}.expressions.${weightExpressionIndex}.includeType`
  });

  const { field: conditionField } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements.${weightingStatementIndex}.expressions.${weightExpressionIndex}.condition`
  });

  const expressionTypeOptions: Array<{ label: string; value: WeightExpressionComparisonType }> =
    attributeField.value === 'product_price'
      ? [
        { label: translate('TEXT_LESS_THAN'), value: 'less' },
        { label: translate('TEXT_MORE_THAN'), value: 'more' },
        { label: translate('TEXT_EXACTLY_MATCHES'), value: 'equals' }
      ]
      : [
        { label: translate('TEXT_EXACTLY_MATCHES'), value: 'equals' },
        { label: translate('TEXT_CONTAINS'), value: 'contains' }
      ];

  const includeTypeOptions: Array<{ label: string; value: IncludeType }> = [
    { label: translate('TEXT_INCLUDE'), value: 'include' },
    { label: translate('TEXT_EXCLUDE'), value: 'exclude' }
  ];

  const conditionOptionsOptions: Array<{ label: string; value: WeightingExpressionCondition }> = [
    { label: translate('TEXT_AND'), value: 'and' },
    { label: translate('TEXT_OR'), value: 'or' }
  ];

  const weightExpressionTypes: Array<{ label: string; value: WeightExpressionAttribute }> = [
    { label: translate('TEXT_PRODUCT_NAME'), value: 'product_title' },
    { label: translate('TEXT_PRODUCT_TAG'), value: 'product_tag' },
    { label: translate('TEXT_PRODUCT_CATEGORY'), value: 'product_type' },
    { label: translate('TEXT_PRODUCT_PRICE'), value: 'product_price' }
  ];

  const handleSelectType = (value: WeightExpressionComparisonType): void => {
    comparisonTypeTypeFiled.onChange(value);
  };

  const handleSelectCondition = (): void => {
    conditionField.onChange(conditionField.value === 'and' ? 'or' : 'and');
  };

  const handleSelectWeightAttributeType = (value: WeightExpressionAttribute): void => {
    attributeField.onChange(value);
    titleField.onChange('');
    comparisonTypeTypeFiled.onChange(value === 'product_price' ? 'less' : 'contains');
  };

  const handleSelectIncludeType = (value: IncludeType): void => {
    includeTypeFiled.onChange(value);
  };

  const handleDeleteClick = (): void => {
    onDelete(weightExpressionIndex);
  };

  useEffect(() => {
    if (weightExpressionIndex === 0 && !!conditionField.value?.length) {
      conditionField.onChange(undefined);
    }
  }, [weightExpressionIndex]);

  useEffect(() => {
    if (titleField.value && !shouldShowError) {
      setShouldShowError(true);
    }
  }, [titleField.value]);

  useEffect(() => {
    if (shouldShowError) {
      setShouldShowError(false);
    }
  }, [attributeField.value]);

  return (
    <div className='attribute-field'>
      <div className='attribute-field-form'>
        {weightExpressionIndex !== 0 && (
          <WeightExpressionTooltip type='condition'>
            <Select
              labelHidden
              labelInline
              label=''
              options={conditionOptionsOptions}
              onChange={handleSelectCondition}
              value={conditionField.value}
            />
          </WeightExpressionTooltip>
        )}
        <WeightExpressionTooltip
          isTriggerShown={weightExpressionIndex === 0}
          type='include'>
          <Select
            labelHidden
            label=''
            options={includeTypeOptions}
            onChange={handleSelectIncludeType}
            value={includeTypeFiled.value}
          />
        </WeightExpressionTooltip>
        <p>{translate('TEXT_PRODUCTS_WITH')}</p>
        <button
          className='attribute-field-close'
          onClick={handleDeleteClick}>
          <Close />
        </button>
      </div>
      <div className='attribute-field-form'>
        <div className='attribute-field-input'>
          <WeightExpressionTooltip
            isTriggerShown={attributeField.value !== 'product_price'}
            type='identify-products'>
            <Select
              labelHidden
              label=''
              options={weightExpressionTypes}
              onChange={handleSelectWeightAttributeType}
              value={attributeField.value}
            />
          </WeightExpressionTooltip>
        </div>
        <div className='attribute-field-input'>
          <WeightExpressionTooltip type='expression-type'>
            <Select
              labelHidden
              label=''
              options={expressionTypeOptions}
              onChange={handleSelectType}
              value={comparisonTypeTypeFiled.value}
            />
          </WeightExpressionTooltip>
        </div>
      </div>
      <div className='attribute-field-input'>
        <AttributesSearch
          onWeightExpressionTitleChange={titleField.onChange}
          weightExpressionTitle={titleField.value}
          weightExpressionAttribute={attributeField.value}
        />
      </div>
      {!!fieldState.error && shouldShowError && (
        <div className='tag-field-warning'>
          <FormWarning>{translate('TEXT_VALIDATION_VALUE_ERROR')}</FormWarning>
        </div>
      )}
    </div>
  );
}
