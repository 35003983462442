import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { TransformDate } from '@shared/class-transformer';

export class RecommendationsAnalyticsStats {
  @Expose({ name: 'recommendations_count' })
  @Type(() => Number)
  public value: number;

  @Expose()
  @Type(() => DateTime)
  @TransformDate()
  public date: DateTime;

  constructor(chartDataItem?: Partial<RecommendationsAnalyticsStats>) {
    Object.assign(this, chartDataItem);
  }
}
