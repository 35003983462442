import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-down.svg';
import './collapse-button.scss';

interface CollapseButtonProps {
  isRotate: boolean;
  onClick: () => void;
}

export function CollapseButton({ isRotate, onClick }: CollapseButtonProps): ReactElement {
  return (
    <button
      onClick={onClick}
      className={clsx('collapse-button', isRotate && 'rotate')}>
      <ArrowDown />
    </button>
  );
}
