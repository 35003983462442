import React, { ReactElement } from 'react';
import { CollapsedSection, CollapsedSectionProps } from '../collapsed-section';
import { StylingCardForm, StylingCardSectionProps } from '../styling-card-form';

export function CollapsedStylingCard({
  title,
  iconContent,
  subtitle,
  ...restProps
}: StylingCardSectionProps & Omit<CollapsedSectionProps, 'children'>): ReactElement {
  return (
    <CollapsedSection
      title={title}
      subtitle={subtitle}
      iconContent={iconContent}>
      <StylingCardForm {...restProps} />
    </CollapsedSection>
  );
}
