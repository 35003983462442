import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { JourneyPageActions } from './actions';
import { JourneyPageState } from './state';

const initialState: JourneyPageState = {
  isCreating: false,
  updatingJourneyID: null
};

export const journeyPageReducer = createReducer(initialState, (builder) => builder
  .addCase(JourneyPageActions.createJourney, (state) => {
    state.isCreating = true;
  })
  .addCase(JourneyPageActions.updateJourney, (state, { payload }) => {
    state.updatingJourneyID = payload.id;
  })
  .addMatcher(isAnyOf(JourneyPageActions.createJourneySuccess, JourneyPageActions.createJourneyFailure), (state) => {
    state.isCreating = false;
  })
  .addMatcher(isAnyOf(JourneyPageActions.updateJourneySuccess, JourneyPageActions.updateJourneyFailure), (state) => {
    state.updatingJourneyID = null;
  }));
