import { Button, Frame, Modal, TextContainer, Toast } from '@shopify/polaris';
import React, { Fragment, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@shared/i18n';
import { ProfileActions, ProfileSelectors } from '@shared/profile';
import { extensionModalConfig } from './extension-modal-config';
import './extension-modal.scss';

interface ExtensionModalProps {
  isVisible?: boolean;
  onCloseModal: () => void;
}

export function ExtensionModal({ isVisible, onCloseModal }: ExtensionModalProps): ReactElement {
  const translate = useTranslation('MAIN.SHARED.EXTENSION_MODAL');
  const extensionLink = useSelector(ProfileSelectors.extensionLink);
  const dispatch = useDispatch();
  const [shouldShowError, setShouldShowError] = useState(false);

  const handleCloseError = (): void => {
    setShouldShowError(false);
  };

  const handleEnableExtensionPress = (): void => {
    window.open(extensionLink);
    dispatch(ProfileActions.setExtensionLink({ extensionLink: null }));
    onCloseModal();
  };

  const handleCloseModal = (): void => {
    if (extensionLink) {
      setShouldShowError(true);
    }
    onCloseModal();
  };

  return (
    <Fragment>
      <Modal
        onClose={handleCloseModal}
        open={isVisible && !!extensionLink}
        title={translate('TEXT_PLEASE_ENABLE')}>
        <Modal.Section>
          <TextContainer>
            <p>{translate('TEXT_REDIRECTED')}</p>
          </TextContainer>
          <div className='extension-modal-button'>
            <Button
              primary
              onClick={handleEnableExtensionPress}>
              {translate('BUTTON_ENABLE')}
            </Button>
          </div>
        </Modal.Section>
      </Modal>
      {shouldShowError && (
        <div className='extension-modal-toast'>
          <Frame>
            <Toast
              content={translate('TEXT_ERROR')}
              onDismiss={handleCloseError}
              duration={extensionModalConfig.errorVisibilityDuration}
              error
            />
          </Frame>
        </div>
      )}
    </Fragment>
  );
}
