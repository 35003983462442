import { Layout } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { configuration } from '@configuration';
import { useTranslation } from '@shared/i18n';
import './explainer-video.scss';

export function ExplainerVideo(): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.EXPLAINER_VIDEO');

  return (
    <Layout>
      <Layout.Section>
        <div className='explainer-video'>
          <p className='text-small'>{translate('TEXT_TITLE')}</p>
          <iframe
            className='explainer-video-video'
            src={configuration.links.explainerVideo}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen'
          />
        </div>
      </Layout.Section>
    </Layout>
  );
}
