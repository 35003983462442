import React, { ReactElement } from 'react';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import './delete-button.scss';

interface DeleteButtonProps {
  onClick: () => void;
}

export function DeleteButton({ onClick }: DeleteButtonProps): ReactElement {
  return (
    <button
      onClick={onClick}
      className='delete-button'>
      <DeleteIcon />
    </button>
  );
}
