import { Button } from '@shopify/polaris';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { configuration } from '@configuration';
import { useTranslation } from '@shared/i18n';
import { InfoCard } from '@shared/info-card';
import './book-call-card.scss';

export function BookCallCard(): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.INFO_SECTION');

  const handleBookCallClick = (): void => {
    window.open(configuration.links.bookCall, '_blank', 'noopener,noreferrer');
  };

  return (
    <InfoCard title={translate('TEXT_BOOK_CALL')}>
      <div className={clsx('info-section-card-content', 'book-call-card', 'text-small')}>
        {translate('TEXT_BOOK_CALL_DESCRIPTION')}
        <Button
          onClick={handleBookCallClick}
          fullWidth>
          {translate('BUTTON_BOOK_CALL')}
        </Button>
      </div>
    </InfoCard>
  );
}
