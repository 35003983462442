import { Expose, Type } from 'class-transformer';
import { RecommendationsAnalyticsStats } from './recommendations-analytics-stats';

export class RecommendationsAnalytics {
  @Expose({ name: 'daily_stats' })
  @Type(() => RecommendationsAnalyticsStats)
  public dailyStats: Array<RecommendationsAnalyticsStats>;

  @Expose()
  public total?: number;

  constructor(analytics?: Partial<RecommendationsAnalytics>) {
    Object.assign(this, analytics);
  }
}
