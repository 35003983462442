import { Button } from '@shopify/polaris';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { overviewPageActions } from '@app/main/overview/shared/store';
import magicWandImage from '@assets/images/magic-wand.png';
import { useTranslation } from '@shared/i18n';
import { InfoCard } from '@shared/info-card';
import './build-quiz-card.scss';

export function BuildQuizCard(): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.INFO_SECTION');
  const dispatch = useDispatch();

  const handleOpenQuizGenerator = (): void => {
    dispatch(overviewPageActions.setIsQuizGeneratorActive({ isQuizGeneratorActive: true }));
  };

  return (
    <InfoCard title={translate('TEXT_BUILD_QUIZ')}>
      <div className={clsx('info-section-card-content', 'build-quiz-card')}>
        <img
          className='build-quiz-card-image'
          src={magicWandImage}
        />
        <Button
          onClick={handleOpenQuizGenerator}
          fullWidth>
          {translate('BUTTON_CREATE_QUIZ')}
        </Button>
      </div>
    </InfoCard>
  );
}
