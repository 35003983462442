import { Expose, Type } from 'class-transformer';
import { SalesAnalyticsStats } from './sales-analytics-stats';

export class SalesAnalytics {
  @Expose({ name: 'daily_stats' })
  @Type(() => SalesAnalyticsStats)
  public dailyStats: Array<SalesAnalyticsStats>;

  @Expose({ name: 'total_revenue' })
  public totalRevenue?: number;

  @Expose()
  public total?: number;

  constructor(analytics: Partial<SalesAnalytics>) {
    Object.assign(this, analytics);
  }
}
