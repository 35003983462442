import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { TransformDate } from '@shared/class-transformer';

export class SalesAnalyticsStats {
  @Expose({ name: 'sales_count' })
  @Type(() => Number)
  public value: number;

  @Expose()
  @Type(() => DateTime)
  @TransformDate()
  public date: DateTime;

  constructor(stats?: Partial<SalesAnalyticsStats>) {
    Object.assign(this, stats);
  }
}
