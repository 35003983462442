import { Expose } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';

export class Tag extends BaseEntity<number> {
  @Expose()
  public title: string;

  constructor(tag: Partial<Tag>) {
    super(tag);
    Object.assign(this, tag);
  }
}
