import { Layout } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { StylingFormHeader, StylingFormHeaderProps } from '../styling-form-header';
import './styling-form-layout.scss';

export function StylingFormLayout({
  children,
  subtitle,
  title
}: StylingFormHeaderProps & { children: React.ReactNode }): ReactElement {
  return (
    <Layout.Section>
      <StylingFormHeader
        title={title}
        subtitle={subtitle}
      />
      <div className='styling-form-layout styling-form'>{children}</div>
    </Layout.Section>
  );
}
