import { of } from 'rxjs';
import { catchError, switchMap, map, filter } from 'rxjs/operators';
import { Epics } from '@store';
import { profileService } from '../service';
import { ProfileActions } from './actions';

export const profileEpics: Epics = {
  fetchProfile: (action$) => action$.pipe(
    filter(ProfileActions.fetchProfile.match),
    switchMap(({ payload }) => profileService.getProfile(payload).pipe(
      map((response) => ProfileActions.fetchProfileSuccess(response)),
      catchError((error) => of(ProfileActions.fetchProfileFailure(error)))
    ))
  )
};
