import clsx from 'clsx';
import React, { Fragment, ReactElement } from 'react';
import { Control, useController } from 'react-hook-form';
import { useOutletContext } from 'react-router';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { useTranslation } from '@shared/i18n';
import { ImageInput } from '@shared/image-input';
import { Journey } from '@shared/journey';
import { useUploadMedia } from '@shared/media';
import './answer-image-input.scss';

interface AnswerImageInputProps {
  questionIndex: number;
  answerIndex: number;
}

export function AnswerImageInput({ questionIndex, answerIndex }: AnswerImageInputProps): ReactElement {
  const { control, editableJourney } = useOutletContext<{
    control: Control<UpsertJourneyForm>;
    editableJourney: Journey;
  }>();

  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.ANSWER_IMAGE_INPUT');

  const image = editableJourney?.questions?.[questionIndex]?.answers?.[answerIndex]?.image;

  const { field: answerImageIDField } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.imageID`
  });

  const { createMedia, isUploading } = useUploadMedia();

  const handleUploadImage = (file: File): void => {
    createMedia(file, (mediaID: number) => answerImageIDField.onChange(mediaID));
  };

  const handleDeleteImage = (): void => {
    answerImageIDField.onChange(null);
  };

  return (
    <Fragment>
      <ImageInput
        accept='.jpg, .jpeg, .png, .svg, .webp'
        isLoading={isUploading}
        onDelete={handleDeleteImage}
        label={translate('TEXT_ADD_IMAGE')}
        onLoad={handleUploadImage}
        imageSource={image?.link}
      />
      <p className={clsx('answer-image-input-note', 'text-x12')}>{translate('TEXT_NOTE')}</p>
    </Fragment>
  );
}
