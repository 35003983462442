import { intersection } from 'lodash';
import { Path } from 'react-hook-form';
import { i18n } from '@shared/i18n';
import { UpsertJourneyForm } from '../forms';

export const getTabsWithErrors = (errorFields: Array<Path<UpsertJourneyForm>>): Array<string> => {
  const surveyFields: Array<Path<UpsertJourneyForm>> = ['questions', 'introductionText', 'introductionTitle', 'name'];
  const stylingErrorFields = errorFields?.filter((field) => !surveyFields.includes(field));
  const tabsWithErrors = [];
  if (intersection(errorFields, surveyFields).length) {
    tabsWithErrors.push(i18n.t('MAIN.JOURNEY.SHARED.TABS.TEXT_QUIZ'));
  }
  if (stylingErrorFields.length) {
    tabsWithErrors.push(i18n.t('MAIN.JOURNEY.SHARED.TABS.TEXT_STYLING'));
  }

  return tabsWithErrors.length ? tabsWithErrors : null;
};
