import { Button } from '@shopify/polaris';
import { nanoid } from 'nanoid';
import React, { ReactElement, useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { useTranslation } from '@shared/i18n';
import { WeightExpression } from '@shared/journey/models/weight-expression';
import { WeightStatement } from '@shared/journey/models/weight-statement';
import { WeightStatementLayout } from './components/weight-statement-layout';
import './weight-statement-list.scss';

interface WeightStatementListProps {
  answerIndex: number;
  questionIndex: number;
  control: Control<UpsertJourneyForm>;
}

export function WeightStatementList({ control, questionIndex, answerIndex }: WeightStatementListProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_STATEMENTS_LIST');

  const { field: weightStatementsField } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements`
  });

  const [isCollapsed, setIsCollapsed] = useState(weightStatementsField.value?.length <= 1);

  const toggleIsCollapsed = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  const visibleStatements = useMemo(
    () => (isCollapsed ? weightStatementsField.value : weightStatementsField.value?.slice(0, 2)),
    [isCollapsed, weightStatementsField.value]
  );

  const handleDeleteStatement = (statementIndex: number): void => {
    weightStatementsField.onChange(weightStatementsField.value.filter((_, i) => i !== statementIndex));
  };

  const handleAddStatementClick = (): void => {
    weightStatementsField.onChange(
      (weightStatementsField.value || []).concat(
        new WeightStatement({
          key: nanoid(),
          weight: 100,
          expressions: [
            new WeightExpression({
              key: nanoid(),
              condition: null,
              includeType: 'include',
              comparisonType: 'contains',
              attribute: 'product_tag'
            })
          ]
        })
      )
    );
    setIsCollapsed(true);
  };

  return (
    <div className='weight-statement-list'>
      {visibleStatements?.map((weightExpression, i) => (
        <div
          className='weight-statement-list-statement-container'
          key={weightExpression.key}>
          <WeightStatementLayout
            onDelete={handleDeleteStatement}
            control={control}
            statementIndex={i}
            answerIndex={answerIndex}
            questionIndex={questionIndex}
          />
          {!isCollapsed && i === 1 && (
            <div className='weight-statement-list-blur-statement'>
              <button
                onClick={toggleIsCollapsed}
                className='button-primary-plain'>
                {translate('BUTTON_SHOW_STATEMENTS', { number: weightStatementsField.value?.length })}
              </button>
            </div>
          )}
        </div>
      ))}
      {isCollapsed && weightStatementsField.value?.length > 1 && (
        <button
          onClick={toggleIsCollapsed}
          className='button-primary-plain'>
          {translate('BUTTON_SHOW_LESS')}
        </button>
      )}
      <div className='weight-statement-list-add-statement'>
        <Button
          onClick={handleAddStatementClick}
          fullWidth>
          {translate(
            weightStatementsField.value?.[0]?.expressions?.length
              ? 'BUTTON_ALIGN_MORE_PRODUCTS'
              : 'BUTTON_ALIGN_PRODUCTS'
          )}
        </Button>
      </div>
    </div>
  );
}
