import React, { ReactElement } from 'react';
import { configuration } from '@configuration';
import { useTranslation } from '@shared/i18n';
import { InfoCard } from '@shared/info-card';
import './explainer-video-card.scss';

export function ExplainerVideoCard(): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.INFO_SECTION');

  return (
    <InfoCard title={translate('TEXT_EXPLAINER_VIDEO')}>
      <div className='info-section-card-content'>
        <iframe
          className='explainer-video-card-video'
          src={configuration.links.explainerVideo}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen'
        />
      </div>
    </InfoCard>
  );
}
