import clsx from 'clsx';
import React, { FunctionComponent, ReactElement, useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { i18n, useTranslation } from '@shared/i18n';
import { ProfileSelectors } from '@shared/profile';
import { AppTooltip } from '@shared/tooltip/tooltip';
import { ConditionForm } from '../condition-form';
import './statement-condition.scss';

interface QuestionConditionProps {
  control: Control<UpsertJourneyForm>;
  questionIndex: number;
  answerIndex: number;
  statementIndex: number;
}

export function StatementCondition({
  control,
  questionIndex,
  answerIndex,
  statementIndex
}: QuestionConditionProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.STATEMENT_CONDITION');

  const isFreePlan = useSelector(ProfileSelectors.isFreePlan);

  const { field: conditionField } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements.${statementIndex}.condition`
  });
  const { field: questionsField } = useController({ control, name: 'questions' });

  const renderTrigger: FunctionComponent<{ onClick: () => void }> = useCallback(
    ({ onClick }) => (
      <AppTooltip
        content={
          isFreePlan ? (
            i18n.t('COMMON.TEXT_UPGRADE_TO_UNLOCK_BRANCHING')
          ) : (
            <span>
              {translate('TEXT_USE_THIS_FUNCTION')} <span className='text-bold'>{translate('TEXT_AND')} </span>
              {translate('TEXT_SPECIFIC_ANSWER')}
            </span>
          )
        }>
        <button
          onClick={onClick}
          disabled={isFreePlan}
          className={clsx('button-primary-plain', isFreePlan && 'button-primary-plain-disabled')}>
          {translate('BUTTON_ADD_CONDITION')}
        </button>
      </AppTooltip>
    ),
    []
  );

  return (
    <div className='statement-condition'>
      <ConditionForm
        renderTrigger={renderTrigger}
        fieldValue={conditionField.value}
        questions={questionsField.value}
        onChange={conditionField.onChange}
      />
    </div>
  );
}
