import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';
import { Plan } from '@shared/plan';

export class User extends BaseEntity<number> {
  @Expose({ name: 'primary_domain' })
  public primaryDomain: string;

  @Expose()
  public name: string;

  @Expose()
  public email: string;

  @Expose({ name: 'is_development_shop' })
  public isDevelopmentShop: boolean;

  @Expose({ name: 'is_recommendation_limit_reached' })
  public isRecommendationLimitReached: boolean;

  @Expose({ name: 'is_close_to_recommendation_limit' })
  public isCloseToRecommendationLimit?: boolean;

  @Expose()
  @Type(() => Plan)
  public plan?: Plan;

  constructor(user: Partial<User>) {
    super(user);
    Object.assign(this, user);
  }
}
