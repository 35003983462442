import { defineAction } from '@store/utils';

export class AuthActions {
  public static saveToken = defineAction<{ token: string }>(
    '[Auth] Save Token'
  );
  public static getDevToken = defineAction(
    '[Auth] Get Dev Token'
  );
}
