import { Exclude, Expose } from 'class-transformer';
import { WeightExpressionComparisonType } from '../types';
import { IncludeType } from '../types/include-type';
import { WeightExpressionAttribute } from '../types/weight-attribute';
import { WeightingExpressionCondition } from '../types/weighting-expression-condition';

export class WeightExpression {
  @Expose()
  public attribute: WeightExpressionAttribute;

  @Expose()
  public value: string;

  @Expose({ name: 'comparison_type' })
  public comparisonType: WeightExpressionComparisonType;

  @Expose({ name: 'include_type' })
  public includeType: IncludeType;

  @Expose()
  public condition?: WeightingExpressionCondition;

  @Exclude()
  public key?: string;

  constructor(model?: Partial<WeightExpression>) {
    Object.assign(this, model);
  }
}
