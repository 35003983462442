import { Button } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useTranslation } from '@shared/i18n';
import { storeRef } from '@store';
import { AuthActions } from '../store';
import './auth-button.scss';

export function AuthButton(): ReactElement {
  const translate = useTranslation('SHARED.AUTH_BUTTON');

  const login = (): void => {
    storeRef.dispatch(AuthActions.getDevToken());
  };

  return (
    <div className='auth-button'>
      <Button onClick={login}>{translate('BUTTON_GET_TOKEN')}</Button>
    </div>
  );
}
