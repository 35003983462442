import { BaseListedEntitySelectors } from '@shared/base-listed-entity-store';
import { AppState } from '@store';
import { Tag } from '../models';
import { TagsState } from './state';

const selectFeature = (state: AppState): TagsState => state.tags;

class TagsSelectors extends BaseListedEntitySelectors<Tag, TagsState> {
  constructor() {
    super(
      selectFeature,
      'tag'
    );
  }
}

export const tagsSelectors = new TagsSelectors();
