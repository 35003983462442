import { values } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { journeyPageEpics } from '@app/main/journey/shared/store';
import { journeySurveyEpics } from '@app/main/journey/survey/shared/store/epics';
import { overviewPageEpics } from '@app/main/overview/shared/store/epics';
import { analyticsEpics } from '@shared/analytics/store';
import { authEpics } from '@shared/auth';
import { profileEpics } from '@shared/profile';
import { tagsEpics } from '@shared/tags/store/epics';

export const rootEpic = combineEpics(
  ...values<Epic>(profileEpics),
  ...values<Epic>(journeyPageEpics),
  ...values<Epic>(overviewPageEpics),
  ...values<Epic>(journeySurveyEpics),
  ...values<Epic>(analyticsEpics),
  ...values<Epic>(authEpics),
  ...values<Epic>(tagsEpics)
);
