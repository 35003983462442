import { createSelector } from '@reduxjs/toolkit';
import { BaseListedEntitySelectors } from '@shared/base-listed-entity-store';
import { Journey } from '@shared/journey';
import { AppState } from '@store';
import { OverviewPageState } from './state';

const selectFeature = (state: AppState): OverviewPageState => state.overviewPage;

class OverviewPageSelectors extends BaseListedEntitySelectors<Journey, OverviewPageState> {
  public isQuizGeneratorActive = createSelector(
    this.selectFeature,
    (state) => state.isQuizGeneratorActive
  );

  constructor() {
    super(
      selectFeature,
      'journey'
    );
  }
}

export const overviewPageSelectors = new OverviewPageSelectors();
