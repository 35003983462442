import clsx from 'clsx';
import React, { ReactElement } from 'react';

interface CardTitleProps {
  children: React.ReactNode;
  className?: string;
}

export function CardTitle({ children, className }: CardTitleProps): ReactElement {
  return <p className={clsx('text-medium', 'text-bold', className)}>{children}</p>;
}
