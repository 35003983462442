import { Autocomplete, Button, ButtonGroup, FormLayout, Modal, RadioButton, Select, Spinner } from '@shopify/polaris';
import { nanoid } from 'nanoid';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { collectionService } from '@shared/collection';
import { FormWarning } from '@shared/form-warning';
import { useTranslation } from '@shared/i18n';
import { SettingUrl } from '@shared/journey/models/setting-url';
import { UrlType } from '@shared/journey/types/url-type';
import { pageService } from '@shared/page';
import { filterRegExp, isUrl } from '@shared/regexp';
import { useSearchData } from '@shared/use-search-data';
import './add-setting-url-modal.scss';

interface AddPageModalProps {
  isActive: boolean;
  onCloseModal: () => void;
  onAddSettingUrl: (settingUrl: SettingUrl) => void;
}

export function AddSettingUrlModal({ isActive, onCloseModal, onAddSettingUrl }: AddPageModalProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SETTINGS.ADD_PAGE_MODAL');
  const [pageUrl, setPageUrl] = useState('');
  const [collection, setCollection] = useState('');
  const [inputType, setInputType] = useState<UrlType>('page');
  const isPageInputType = useMemo(() => inputType === 'page', [inputType]);

  const {
    data: collections = [],
    search: searchCollections,
    isLoading: isLoadingCollections
  } = useSearchData(collectionService);
  const { data: pages = [], search: searchPages, isLoading: isLoadingPages } = useSearchData(pageService);

  const hasPageUrlValidationError = useMemo(() => pageUrl && !isUrl(pageUrl), [pageUrl]);

  const deselectedPages = pages
    .filter((page) => (pageUrl ? page.url.match(filterRegExp(pageUrl)) : true))
    .map((page) => ({ value: page.url, label: page.url }));

  const handleAddClick = (): void => {
    if ((isPageInputType && !pageUrl) || hasPageUrlValidationError || (!isPageInputType && !collection)) {
      return;
    }
    onAddSettingUrl({ type: inputType, content: isPageInputType ? pageUrl : collection, key: nanoid() });
    onCloseModal();
  };

  const handleInputTypeChange = (): void => {
    setInputType(isPageInputType ? 'collection' : 'page');
    isPageInputType ? setPageUrl('') : setCollection('');
  };

  const handleSearchCollections = (): void => {
    if (!collections.length) {
      searchCollections();
    }
  };

  const handleSearchPages = (): void => {
    if (!pages.length && !isLoadingPages) {
      searchPages();
    }
  };

  const handleSelectPage = (selectedPages: Array<string>): void => {
    setPageUrl(selectedPages[0]);
  };

  const pagesTextField = (
    <Autocomplete.TextField
      helpText={hasPageUrlValidationError && <FormWarning>{translate('TEXT_PAGE_URL_VALIDATION')}</FormWarning>}
      onFocus={handleSearchPages}
      autoComplete='off'
      onChange={setPageUrl}
      label={translate('TEXT_PAGE_URL')}
      value={pageUrl}
    />
  );

  useEffect(() => {
    if (!isActive) {
      setCollection('');
      setPageUrl('');
    }
  }, [isActive]);

  return (
    <Modal
      open={isActive}
      onClose={onCloseModal}
      title={translate('TEXT_ADD_PAGE')}>
      <Modal.Section>
        <FormLayout>
          <div className='add-setting-url-modal-radio-buttons'>
            <RadioButton
              onChange={handleInputTypeChange}
              checked={inputType === 'page'}
              label={translate('TEXT_PAGE')}
            />
            <RadioButton
              onChange={handleInputTypeChange}
              checked={inputType === 'collection'}
              label={translate('TEXT_COLLECTION')}
            />
          </div>
          {inputType === 'page' ? (
            <Autocomplete
              loading={isLoadingPages}
              options={deselectedPages}
              selected={[pageUrl]}
              onSelect={handleSelectPage}
              textField={pagesTextField}
            />
          ) : (
            <Select
              onFocus={handleSearchCollections}
              placeholder={translate('TEXT_SELECT_COLLECTION')}
              label={isLoadingCollections ? <Spinner size='small' /> : translate('TEXT_COLLECTION')}
              options={collections}
              onChange={setCollection}
              value={collection}
            />
          )}
        </FormLayout>
      </Modal.Section>
      <Modal.Section>
        <div className='add-setting-url-modal-buttons'>
          <ButtonGroup>
            <Button
              outline
              onClick={onCloseModal}>
              {translate('BUTTON_CANCEL')}
            </Button>
            <Button
              onClick={handleAddClick}
              primary>
              {translate('BUTTON_ADD')}
            </Button>
          </ButtonGroup>
        </div>
      </Modal.Section>
    </Modal>
  );
}
