import { Card, FormLayout, Layout } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { Control, useController } from 'react-hook-form';
import { CustomCard } from '@shared/custom-card';
import { FontPicker, FontPickerID } from '@shared/font-picker';
import { GoogleFontsLabel } from '@shared/google-fonts-label';
import { useTranslation } from '@shared/i18n';
import { UpsertJourneyForm } from '../../../../shared/forms';
import { StylingFormHeader } from '../styling-form-header';
import './fonts-form.scss';

interface FontsFormProps {
  control: Control<UpsertJourneyForm>;
}

export function FontsForm({ control }: FontsFormProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.STYLING.FONTS_FORM');

  const { field: textFontFiled } = useController({ control, name: 'themeTextFont' });
  const { field: titlesFontFiled } = useController({ control, name: 'themeTitlesFont' });
  const { field: buttonsFontFiled } = useController({ control, name: 'themeButtonsFont' });

  const handleTextFontChange = (fontFamily: string): void => {
    textFontFiled.onChange(fontFamily);
  };

  const handleTitlesFontChange = (fontFamily: string): void => {
    titlesFontFiled.onChange(fontFamily);
  };

  const handleButtonsFontChange = (fontFamily: string): void => {
    buttonsFontFiled.onChange(fontFamily);
  };

  return (
    <Layout.Section>
      <StylingFormHeader
        title={translate('TEXT_THEME_FONTS')}
        subtitle={
          <div className='fonts-form-subtitle'>
            <p>{translate('TEXT_SET_FONTS')}</p>
            <p> {translate('TEXT_WE_RECOMMEND')}</p>
          </div>
        }
      />
      <div className='styling-form fonts-form'>
        <div className='styling-card'>
          <Card>
            <Card.Section>
              <FormLayout>
                <FontPicker
                  label={<GoogleFontsLabel label={translate('TEXT_TEXT_FONT')} />}
                  activeFontFamily={textFontFiled.value}
                  onChange={handleTextFontChange}
                  pickerId={FontPickerID.TEXT}
                />
                <FontPicker
                  activeFontFamily={titlesFontFiled.value}
                  onChange={handleTitlesFontChange}
                  label={translate('TEXT_TITLES_FONT')}
                  pickerId={FontPickerID.TITLES}
                />
                <FontPicker
                  activeFontFamily={buttonsFontFiled.value}
                  onChange={handleButtonsFontChange}
                  label={translate('TEXT_BUTTONS_FONT')}
                  pickerId={FontPickerID.BUTTONS}
                />
              </FormLayout>
            </Card.Section>
          </Card>
        </div>
        <div className='styling-card'>
          <CustomCard
            title={translate('TEXT_TITLE')}
            subtitle={translate('BODY_TEXT')}
            titleClassName={`apply-font-${FontPickerID.TITLES}`}
            textClassName={`apply-font-${FontPickerID.TEXT}`}
            buttonClassName={`apply-font-${FontPickerID.BUTTONS}`}
          />
        </div>
      </div>
    </Layout.Section>
  );
}
