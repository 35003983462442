import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';
import { FormProduct, Product } from '@shared/product';
import { FontWeight } from '../enums';
import { TabPosition } from '../types';
import { Question } from './question';
import { SettingUrl } from './setting-url';

export class Journey extends BaseEntity<number> {
  @Expose()
  public name: string;

  @Expose({ name: 'unique_id' })
  public uniqueID: string;

  @Expose({ name: 'introduction_title' })
  public introductionTitle: string;

  @Expose({ name: 'introduction_text' })
  public introductionText: string;

  @Expose({ name: 'is_active' })
  public isActive: boolean;

  @Expose()
  @Type(() => Question)
  public questions: Array<Question>;

  @Expose()
  @Type(() => Product)
  public products: Array<FormProduct>;

  @Expose({ name: 'recommendations_count' })
  public recommendationsCount: number;

  @Expose({ name: 'recommendations_title' })
  public recommendationsTitle: string;

  @Expose({ name: 'recommendations_text' })
  public recommendationsText?: string;

  @Expose({ name: 'recommendations_cta_button_text' })
  public recommendationsCtaButtonText?: string;

  @Expose({ name: 'theme_text_font' })
  public themeTextFont?: string;

  @Expose({ name: 'theme_titles_font' })
  public themeTitlesFont?: string;

  @Expose({ name: 'theme_buttons_font' })
  public themeButtonsFont?: string;

  @Expose({ name: 'engagement_tab_color' })
  public engagementTabColor?: string;

  @Expose({ name: 'engagement_tab_text_color' })
  public engagementTabTextColor?: string;

  @Expose({ name: 'engagement_tab_position' })
  public engagementTabPosition?: TabPosition;

  @Expose({ name: 'engagement_tab_border_radius' })
  public engagementTabBorderRadius?: string;

  @Expose({ name: 'engagement_tab_font' })
  public engagementTabFont?: string;

  @Expose({ name: 'engagement_tab_font_weight' })
  public engagementTabFontWeight?: FontWeight;

  @Expose({ name: 'engagement_tab_font_size' })
  public engagementTabFontSize?: string;

  @Expose({ name: 'engagement_tab_text' })
  public engagementTabText?: string;

  @Expose({ name: 'engagement_tab_bubble_text' })
  public engagementTabBubbleText?: string;

  @Expose({ name: 'engagement_tab_bubble_font' })
  public engagementTabBubbleFont?: string;

  @Expose({ name: 'engagement_tab_bubble_font_weight' })
  public engagementTabBubbleFontWeight?: string;

  @Expose({ name: 'engagement_tab_bubble_font_size' })
  public engagementTabBubbleFontSize?: string;

  @Expose({ name: 'engagement_tab_bubble_cancel_text' })
  public engagementTabBubbleCancelText?: string;

  @Expose({ name: 'engagement_tab_bubble_confirm_text' })
  public engagementTabBubbleConfirmText?: string;

  @Expose({ name: 'engagement_tab_bubble_button_text_color' })
  public engagementTabBubbleButtonTextColor?: string;

  @Expose({ name: 'engagement_tab_bubble_button_background_color' })
  public engagementTabBubbleButtonBackgroundColor?: string;

  @Expose({ name: 'engagement_tab_bubble_border_color' })
  public engagementTabBubbleBorderColor?: string;

  @Expose({ name: 'survey_body_background_color' })
  public surveyBodyBackgroundColor?: string;

  @Expose({ name: 'survey_body_text_color' })
  public surveyBodyTextColor?: string;

  @Expose({ name: 'survey_body_font_size' })
  public surveyBodyFontSize?: string;

  @Expose({ name: 'survey_body_font_weight' })
  public surveyBodyFontWeight?: FontWeight;

  @Expose({ name: 'survey_title_color' })
  public surveyTitleColor?: string;

  @Expose({ name: 'survey_title_font_size' })
  public surveyTitleFontSize?: string;

  @Expose({ name: 'survey_title_font_weight' })
  public surveyTitleFontWeight?: FontWeight;

  @Expose({ name: 'answer_font_size' })
  public answerFontSize?: string;

  @Expose({ name: 'answer_font_weight' })
  public answerFontWeight?: FontWeight;

  @Expose({ name: 'answer_border_radius' })
  public answerBorderRadius?: string;

  @Expose({ name: 'answer_background_color' })
  public answerBackgroundColor?: string;

  @Expose({ name: 'answer_text_color' })
  public answerTextColor?: string;

  @Expose({ name: 'answer_border_color' })
  public answerBorderColor?: string;

  @Expose({ name: 'button_background_color' })
  public buttonBackgroundColor?: string;

  @Expose({ name: 'button_background_hover_color' })
  public buttonBackgroundHoverColor?: string;

  @Expose({ name: 'button_text_color' })
  public buttonTextColor?: string;

  @Expose({ name: 'button_font_size' })
  public buttonFontSize?: string;

  @Expose({ name: 'button_font_weight' })
  public buttonFontWeight?: FontWeight;

  @Expose({ name: 'button_border_radius' })
  public buttonBorderRadius?: string;

  @Expose({ name: 'next_button_text' })
  public nextButtonText?: string;

  @Expose({ name: 'previous_button_text' })
  public previousButtonText?: string;

  @Expose({ name: 'restart_button_text' })
  public restartButtonText?: string;

  @Expose({ name: 'settings_urls' })
  @Type(() => SettingUrl)
  public settingsUrls?: Array<SettingUrl>;

  @Expose({ name: 'is_ai_generated' })
  public isAiGenerated?: boolean;

  @Expose({ name: 'should_use_shop_styling' })
  public shouldUseShopStyling?: boolean;

  constructor(journey: Partial<Journey>) {
    super(journey);
    Object.assign(this, journey);
  }
}
