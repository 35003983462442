import { RangeSlider, Tooltip } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { ReactComponent as Info } from '@assets/icons/info.svg';
import { configuration } from '@configuration';
import { FormWarning } from '@shared/form-warning';
import { useTranslation } from '@shared/i18n';
import './statement-weight.scss';

interface StatementWeightProps {
  onChange: (value: number) => void;
  value: number;
  errorMessage: string;
}

export function StatementWeight({ onChange, value, errorMessage }: StatementWeightProps): ReactElement {
  const translation = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.STATEMENT_WEIGHT');

  return (
    <div className='statement-weight'>
      <div className='statement-weight-form'>
        <div className='statement-weight-input'>
          <RangeSlider
            label={
              <div className='statement-weight-input-label'>
                <Tooltip content={translation('TEXT_TOOLTIP')}>
                  <div>
                    <Info />
                  </div>
                </Tooltip>
                {translation('TEXT_WEIGHT')}{' '}
                <a
                  className='statement-weight-link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={configuration.links.answerWeightInfo}>
                  {translation('TEXT_MORE_ON_ANSWER_WEIGHT')}
                </a>
              </div>
            }
            value={Number(value)}
            onChange={onChange}
            prefix={<p>{translation('TEXT_SLIDER_PREFIX')}</p>}
            suffix={<p>{translation('TEXT_SLIDER_SUFFIX')}</p>}
            step={5}
            output
          />
        </div>
      </div>
      <FormWarning>{errorMessage}</FormWarning>
    </div>
  );
}
