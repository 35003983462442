import { Layout } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { BookCallCard } from './components/book-call-card';
import { BuildQuizCard } from './components/build-quiz-card/build-quiz-card';
import { ExplainerVideoCard } from './components/explainer-video-card';
import './info-section.scss';

export function InfoSection(): ReactElement {
  return (
    <Layout>
      <Layout.Section>
        <div className='info-section-divider' />
        <div className='info-section-content'>
          <div className='info-section-card'>
            <BuildQuizCard />
          </div>
          <div className='info-section-card'>
            <BookCallCard />
          </div>
          <div className='info-section-card'>
            <ExplainerVideoCard />
          </div>
        </div>
      </Layout.Section>
    </Layout>
  );
}
