import { of } from 'rxjs';
import { catchError, exhaustMap, filter, mergeMap } from 'rxjs/operators';
import { overviewPageActions } from '@app/main/overview/shared/store';
import { AnalyticsTimescale } from '@shared/analytics/enums';
import { AnalyticsActions } from '@shared/analytics/store';
import { ProfileActions } from '@shared/profile';
import { AppActions, Epics } from '@store';
import { authService } from '../service';
import { AuthActions } from './actions';

export const authEpics: Epics = {
  getDevToken: (action$) => action$.pipe(
    filter(AuthActions.getDevToken.match),
    filter(() => process.env.REACT_APP_ENV === 'local'),
    exhaustMap(() => authService.getDevToken().pipe(
      mergeMap((response) => [
        AuthActions.saveToken({ token: response }),
        AnalyticsActions.loadAnalytics({ timescale: AnalyticsTimescale.THREE_MONTH }),
        overviewPageActions.loadItems({ page: 1 }),
        ProfileActions.fetchProfile({ relations: ['plan'] })
      ]),
      catchError(() => of(AppActions.noop()))
    ))
  )
};
