export const variables = {
  colors: {
    white: '#FFFFFF',
    blue: '#5B9DFF',
    darkBlue: '#2563EB',
    dark: '#202223'
  },
  fontSizes: {
    default: '16',
    large: '24',
    small: '14'
  },
  borderRadius: {
    default: '8'
  }
};
