import { Card, Collapsible } from '@shopify/polaris';
import React, { Fragment, ReactElement, useState } from 'react';
import { CardHeader, CardHeaderProps } from '@app/main/journey/shared/components/card-header';
import { CollapseButton } from '@shared/collapse-button';
import './collapsed-section.scss';

export interface CollapsedSectionProps extends CardHeaderProps {
  children: React.ReactNode;
  subtitle?: string;
}

export function CollapsedSection({ title, iconContent, children, subtitle }: CollapsedSectionProps): ReactElement {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const toggleCollapse = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Card.Section>
      <CardHeader
        title={title}
        actionsContent={
          <CollapseButton
            isRotate={isCollapsed}
            onClick={toggleCollapse}
          />
        }
        iconContent={iconContent}
      />
      <Collapsible
        id='basic-collapsible'
        open={!isCollapsed}>
        <Fragment>
          {subtitle && <p className='collapsed-section-subtitle'>{subtitle}</p>}
          <div className='collapsed-section-form'>{children}</div>
        </Fragment>
      </Collapsible>
    </Card.Section>
  );
}
