import { Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { configuration } from '@configuration';
import { apiService } from '@shared/api/service';
import { RefreshTokenResponse } from './models';

class AuthService {
  private tokenRefresh$?: Observable<RefreshTokenResponse>;

  public refreshToken(): Observable<RefreshTokenResponse> {
    if (!this.tokenRefresh$) {
      this.tokenRefresh$ = apiService.get(configuration.api.refreshTokenEndpoint).pipe(
        share(),
        tap(() => {
          this.tokenRefresh$ = null;
        })
      );
    }

    return this.tokenRefresh$;
  }

  public getDevToken(): Observable<string> {
    return apiService.get('dev-shop-token');
  }
}

export const authService = new AuthService();
