import { Layout } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ProfileSelectors } from '@shared/profile';
import './footer.scss';

export function AppFooter(): ReactElement {
  const profile = useSelector(ProfileSelectors.profile);

  if (!profile?.name) {
    return null;
  }

  return (
    <Layout>
      <div className='footer'>{profile.name}</div>
    </Layout>
  );
}
