import 'reflect-metadata';
import React, { Fragment, ReactElement, useEffect } from 'react';
import { Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';
import { AuthActions } from '@shared/auth/store';
import { AppFooter } from '@shared/footer';
import { ProfileActions } from '@shared/profile';
import { RecommendationsLimitBanner } from '@shared/recommendations-limit-banner';
import { RecommendationsLimitModal } from '@shared/recommendations-limit-modal';
import { useUsetiful } from '@shared/usetiful';
import { storeRef } from '@store';
import { Billing } from './main/billing';
import { JourneyPage } from './main/journey';
import { JourneySettings } from './main/journey/settings';
import { JourneyStyling } from './main/journey/styling';
import { JourneySurvey } from './main/journey/survey';
import { OverviewPage } from './main/overview';
import { overviewPageFacade } from './main/overview/facade';

function App(): ReactElement {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleUpgradePlanClick = (): void => {
    navigate('/billing');
  };

  useUsetiful();

  useEffect(() => {
    storeRef.dispatch(AuthActions.saveToken({ token: searchParams.get('token') }));
    storeRef.dispatch(ProfileActions.fetchProfile({ relations: ['plan'] }));
    storeRef.dispatch(ProfileActions.setExtensionLink({ extensionLink: searchParams.get('extension_link') }));
    overviewPageFacade.loadItems();
  }, []);

  return (
    <Fragment>
      <RecommendationsLimitBanner onUpgradeClick={handleUpgradePlanClick} />
      <Routes>
        <Route
          path='/'
          element={<OverviewPage />}
        />
        <Route
          path='/journey/:id'
          element={<JourneyPage />}>
          <Route
            path='quiz'
            element={<JourneySurvey />}
          />
          <Route
            path='styling'
            element={<JourneyStyling />}
          />
          <Route
            path='settings'
            element={<JourneySettings />}
          />
        </Route>
        <Route
          path='charge/:result'
          element={<Billing />}
        />
        <Route
          path='billing'
          element={<Billing />}
        />
      </Routes>
      <AppFooter />
      <RecommendationsLimitModal onUpgradePlanPress={handleUpgradePlanClick} />
    </Fragment>
  );
}

export default App;
