import axios, { AxiosResponse } from 'axios';
import { instanceToPlain } from 'class-transformer';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { quizGeneratorConfig } from './config';
import { QuizGeneratorProduct } from './models/quiz-generator-product';
import { QuizGeneratorRequest } from './models/quiz-generator-request';

class QuizGeneratorService {
  public async generate(params: {
    shop: string;
    quizName: string;
    products: Array<QuizGeneratorProduct>;
  }): Promise<AxiosResponse<UpsertJourneyForm, any>> {
    const request = instanceToPlain(new QuizGeneratorRequest(params));

    return axios.post<UpsertJourneyForm>(quizGeneratorConfig.apiUrl, request);
  }
}

export const quizGeneratorService = new QuizGeneratorService();
