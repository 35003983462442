import { CancelSmallMinor } from '@shopify/polaris-icons';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { CustomButton } from '@shared/custom-button';
import { CustomStylesProps } from '@shared/custom-card';
import { FontPickerID } from '@shared/font-picker';
import { useTranslation } from '@shared/i18n';
import { TabPosition } from '@shared/journey';
import './engagement-card.scss';
import { transformFontSize } from '@shared/journey/utils/transform-font-size';

interface EngagementCardProps {
  position: TabPosition;
  tabText?: string;
  bubbleText?: string;
  cancelButtonText: string;
  confirmButtonText: string;
  buttonsStyles: CustomStylesProps;
  bubbleStyles: CustomStylesProps;
  tabStyles: CustomStylesProps;
}

export function EngagementCard({
  position,
  tabText,
  bubbleText,
  cancelButtonText,
  confirmButtonText,
  bubbleStyles,
  buttonsStyles,
  tabStyles
}: EngagementCardProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.STYLING.ENGAGEMENT_CARD');

  return (
    <div className='engagement-card'>
      <div
        className='engagement-card-bubble'
        style={{ borderColor: bubbleStyles.borderColor }}>
        <div className='engagement-card-cancel'>
          <CancelSmallMinor />
        </div>
        <p
          style={{
            fontSize: `${transformFontSize(bubbleStyles.fontSize)}px`,
            lineHeight: `${transformFontSize(bubbleStyles.fontSize)}px`
          }}
          className={clsx(
            'engagement-card-title',
            `apply-font-${FontPickerID.BUBBLE}`,
            `font-${bubbleStyles.fontWeight}`
          )}>
          {bubbleText || translate('TEXT_NEED_HELP')}
        </p>
        <div className='engagement-card-buttons'>
          <CustomButton
            backgroundColor={buttonsStyles.backgroundColor}
            color={buttonsStyles.color}
            text={cancelButtonText}
            textClassName='engagement-card-custom-button engagement-card-cancel-button'
          />
          <CustomButton
            backgroundColor={buttonsStyles.backgroundColor}
            color={buttonsStyles.color}
            text={confirmButtonText}
            textClassName='engagement-card-custom-button'
          />
        </div>
      </div>
      <div
        style={{
          borderRadius: `${tabStyles.borderRadius}px`,
          fontSize: `${transformFontSize(tabStyles.fontSize)}px`,
          lineHeight: `${transformFontSize(tabStyles.fontSize)}px`,
          backgroundColor: tabStyles.backgroundColor,
          color: tabStyles.color
        }}
        className={clsx(
          'engagement-card-tab',
          `apply-font-${FontPickerID.ENGAGEMENT}`,
          `font-${tabStyles.fontWeight}`,
          position
        )}>
        {tabText || translate('TEXT_HELP_ME_CHOOSE')}
      </div>
    </div>
  );
}
