import { Expose, Type } from 'class-transformer';
import { QuestionType } from '../types';
import { Answer } from './answer';
import { Condition } from './condition';

export class Question {
  @Expose()
  public title: string;

  @Expose()
  public text: string;

  @Expose()
  public type: QuestionType;

  @Expose()
  @Type(() => Answer)
  public answers: Array<Answer>;

  @Expose()
  @Type(() => Condition)
  public condition: Array<Condition>;

  @Expose({ name: 'id_within_journey' })
  public idWithinJourney: string;

  constructor(question?: Partial<Question>) {
    Object.assign(this, question);
  }
}
