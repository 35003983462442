import { Expose } from 'class-transformer';

export class ProductsPagination {
  @Expose({ name: 'end_cursor' })
  public endCursor: string;

  @Expose({ name: 'has_next_page' })
  public hasNextPage: boolean;

  constructor(pagination?: Partial<ProductsPagination>) {
    Object.assign(this, pagination);
  }
}
