import { Button, Card, Layout, Toast } from '@shopify/polaris';
import { nanoid } from 'nanoid';
import React, { ReactElement, useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useOutletContext, useLocation } from 'react-router-dom';
import { CustomCard } from '@shared/custom-card';
import { FormInput } from '@shared/form-input';
import { useTranslation } from '@shared/i18n';
import { Question } from '@shared/journey';
import { UpsertJourneyForm } from '../shared/forms';
import { DefaultRecommendations } from './shared/components/default-recommendations';
import { QuestionCard } from './shared/components/question-card';
import { RecommendationsRange } from './shared/components/recommendations-range';
import './survey.scss';

export function JourneySurvey(): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY');

  const [isDraggable, setIsDraggable] = useState(false);
  const [draggingQuestion, setDraggingQuestion] = useState<{ question: Partial<Question>; index: number }>();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const location = useLocation();

  const { control } = useOutletContext<{ control: Control<UpsertJourneyForm> }>();
  const { field: titleField } = useController({ control, name: 'introductionTitle' });
  const { field: textField } = useController({ control, name: 'introductionText' });
  const { field: questionsField } = useController({ control, name: 'questions' });

  const handleAddQuestion = (): void => {
    questionsField.onChange(
      (questionsField.value || []).concat(
        new Question({
          type: 'radio',
          idWithinJourney: nanoid()
        })
      )
    );
  };

  const toggleSuccessMessage = (): void => {
    setShowSuccessMessage(!showSuccessMessage);
  };

  const toggleIsDraggable = (isDraggable: boolean): void => {
    setIsDraggable(isDraggable);
  };

  const handleDragStart = (question: Partial<Question>, index: number): (() => void) => {
    return () => {
      setDraggingQuestion({ question, index });
    };
  };

  const handleDrop = (question: Partial<Question>, index: number): (() => void) => {
    return () => {
      questionsField.onChange(
        questionsField.value.map((q, i) => {
          if (i === index) {
            return draggingQuestion.question;
          }
          if (i === draggingQuestion.index) {
            return question;
          }

          return q;
        })
      );
    };
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  const handleDragEnd = (): void => {
    setDraggingQuestion(null);
  };

  useEffect(() => {
    if (location.state?.generated) {
      toggleSuccessMessage();
    }
  }, []);

  return (
    <div className='survey journey-tab-layout page-layout'>
      <Layout>
        <Layout.Section>
          <div className='survey-form'>
            <div className='survey-name'>
              <Card title={translate('TEXT_NAME')}>
                <Card.Section>
                  <FormInput<UpsertJourneyForm>
                    name='name'
                    control={control}
                    label={translate('TEXT_JOURNEY_NAME')}
                    placeholder={translate('TEXT_NEW_JOURNEY')}
                    autoComplete='off'
                  />
                </Card.Section>
              </Card>
            </div>
          </div>
          <div className='survey-form'>
            <div className='survey-card'>
              <Card title={translate('TEXT_INTRODUCTION')}>
                <Card.Section>
                  <FormInput<UpsertJourneyForm>
                    name='introductionTitle'
                    control={control}
                    label={translate('TEXT_TITLE')}
                    placeholder={translate('TEXT_WELCOME')}
                    autoComplete='off'
                  />
                  <div className='survey-input'>
                    <FormInput<UpsertJourneyForm>
                      name='introductionText'
                      control={control}
                      multiline={4}
                      label={translate('TEXT_TEXT')}
                      autoComplete='off'
                    />
                  </div>
                </Card.Section>
              </Card>
            </div>
            <div className='survey-card'>
              <CustomCard
                title={titleField.value || translate('TEXT_WELCOME')}
                subtitle={textField.value}
              />
            </div>
          </div>
          {questionsField.value?.map((question, index) => (
            <div
              key={question.idWithinJourney}
              className='survey-form'>
              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop(question, index)}
                onDragStart={handleDragStart(question, index)}
                onDragEnd={handleDragEnd}
                draggable={isDraggable}
                className='survey-card'>
                <QuestionCard
                  control={control}
                  index={index}
                  isDragging={draggingQuestion?.index === index}
                  onToggleIsDraggable={toggleIsDraggable}
                />
              </div>
              <div className='survey-card'>
                <CustomCard
                  isTextAnswer={question.type === 'text'}
                  title={question.title || translate('QUESTION_CARD.TEXT_QUESTION_TITLE')}
                  subtitle={question.text}
                  answers={questionsField.value[index].answers}
                />
              </div>
            </div>
          ))}
          <Button onClick={handleAddQuestion}>{translate('BUTTON_ADD_QUESTION')}</Button>
        </Layout.Section>
        <Layout.Section>
          <DefaultRecommendations control={control} />
        </Layout.Section>
      </Layout>
      <div className='survey-recommendations-range'>
        <Layout>
          <Layout.Section oneThird>
            <RecommendationsRange control={control} />
          </Layout.Section>
        </Layout>
      </div>
      {showSuccessMessage && (
        <Toast
          content={translate('GENERATOR_NOTIFICATION.TEXT_SUCCESS')}
          onDismiss={toggleSuccessMessage}
          duration={3500}
        />
      )}
    </div>
  );
}
