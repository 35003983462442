import { Exclude, Expose, Type } from 'class-transformer';
import { Condition } from './condition';
import { WeightExpression } from './weight-expression';

export class WeightStatement {
  @Expose()
  @Type(() => WeightExpression)
  public expressions: Array<WeightExpression>;

  @Expose()
  @Type(() => Condition)
  public condition?: Array<Condition>;

  @Expose()
  public weight: number;

  @Exclude()
  public key?: string;

  constructor(model?: Partial<WeightStatement>) {
    Object.assign(this, model);
  }
}
