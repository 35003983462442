import { Observable } from 'rxjs';
import { apiService } from '@shared/api';

class CollectionService {
  public search(): Observable<Array<string>> {
    return apiService.get<Array<string>>('/plugin/facade/collections');
  }
}

export const collectionService = new CollectionService();
