import { createSelector } from '@reduxjs/toolkit';
import { BaseListedEntitySelectors } from '@shared/base-listed-entity-store';
import { Product } from '@shared/product';
import { AppState } from '@store';
import { JourneySurveyState } from './state';

const selectFeature = (state: AppState): JourneySurveyState => state.journeySurvey;

class JourneySurveySelectors extends BaseListedEntitySelectors<Product, JourneySurveyState> {
  public productsPagination = createSelector(
    this.selectFeature,
    (state) => state.productsPagination
  );

  constructor() {
    super(
      selectFeature,
      'product'
    );
  }
}

export const journeySurveySelectors = new JourneySurveySelectors();
