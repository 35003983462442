import React, { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { ReactComponent as Close } from '@assets/icons/close.svg';
import './image-input.scss';
import { AppSpinner } from '@shared/spinner';

interface ImageInputProps {
  label: string;
  onLoad: (image: File) => void;
  onDelete: () => void;
  imageSource?: string;
  isLoading?: boolean;
  accept?: string;
}

export function ImageInput({ label, onDelete, onLoad, imageSource, isLoading, accept }: ImageInputProps): ReactElement {
  const [image, setImage] = useState(imageSource);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      onLoad(file);
    }
  };

  const handleClearClick = (): void => {
    if (!isLoading) {
      onDelete();
      fileInputRef.current.value = '';
      setImage(null);
    }
  };

  return (
    <div className='image-input'>
      <p>{label}</p>
      <div className='image-input-container'>
        <input
          ref={fileInputRef}
          type='file'
          accept={accept || 'image/*'}
          onChange={handleImageChange}
        />
      </div>
      {!!image && (
        <div className='image-input-image-container'>
          <div
            onClick={handleClearClick}
            className='image-input-close'>
            <Close />
          </div>
          <img
            src={image}
            className='image-input-image'
          />
          {!!isLoading && (
            <div className='image-input-loading-placeholder'>
              <AppSpinner size='small' />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
