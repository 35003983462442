import { Card, TextField } from '@shopify/polaris';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useTranslation } from '@shared/i18n';
import { Answer } from '@shared/journey';
import { PageTitle } from '@shared/page-title';
import { CustomButton } from '../custom-button';
import { transformFontSize } from '../journey/utils/transform-font-size';
import './custom-card.scss';

export type CustomStylesProps = Pick<
  React.CSSProperties,
  'backgroundColor' | 'color' | 'borderColor' | 'borderRadius' | 'fontSize' | 'fontWeight' | 'fontFamily'
>;

interface CustomCardProps {
  title: string;
  subtitle: string;
  isTextAnswer?: boolean;
  answers?: Array<Answer>;
  bodyStyles?: CustomStylesProps;
  answerStyles?: CustomStylesProps;
  titleStyle?: CustomStylesProps;
  buttonStyles?: {
    nextButtonText?: string;
    previousButtonText?: string;
    backgroundColorHover?: string;
  } & CustomStylesProps;
  isStyleEditingMode?: boolean;
  titleClassName?: string;
  textClassName?: string;
  buttonClassName?: string;
}

export function CustomCard({
  title,
  subtitle,
  isTextAnswer,
  answers,
  titleStyle,
  bodyStyles,
  answerStyles,
  buttonStyles,
  isStyleEditingMode,
  textClassName,
  titleClassName,
  buttonClassName
}: CustomCardProps): ReactElement {
  const translate = useTranslation('SHARED.CUSTOM_CARD');
  const { nextButtonText, previousButtonText } = buttonStyles || {};

  return (
    <Card>
      <div
        style={{ backgroundColor: bodyStyles?.backgroundColor }}
        className='custom-card'>
        <Card.Section>
          <div className={clsx('custom-card-title', titleClassName)}>
            <PageTitle {...titleStyle}>{title}</PageTitle>
          </div>
          <p
            style={{
              fontSize: `${transformFontSize(bodyStyles?.fontSize)}px`,
              lineHeight: `${transformFontSize(bodyStyles?.fontSize)}px`,
              color: bodyStyles?.color
            }}
            className={clsx('custom-card-subtitle', `font-${bodyStyles?.fontWeight}`, textClassName)}>
            {subtitle}
          </p>
          {isTextAnswer ? (
            <div className='custom-card-text-answer'>
              <TextField
                label=''
                autoComplete='off'
                value={answers?.[0].text}
              />
            </div>
          ) : (
            !!answers?.length && (
              <div className='custom-card-answer-buttons'>
                {answers.map((answer) => (
                  <div
                    key={`button${answer.idWithinJourney}`}
                    className='custom-card-custom-button col-50'>
                    <CustomButton
                      textClassName={buttonClassName}
                      fullWidth={true}
                      text={answer.text || translate('BUTTON_ANSWER_TEXT')}
                      isOutline={true}
                      {...answerStyles}
                    />
                  </div>
                ))}
              </div>
            )
          )}
          <div className='custom-card-actions'>
            {answers ? (
              <div className='custom-card-action-buttons '>
                <CustomButton
                  textClassName={clsx('custom-card-action-button', buttonClassName)}
                  text={previousButtonText || translate('BUTTON_PREVIOUS')}
                  {...buttonStyles}
                />
                <CustomButton
                  textClassName={clsx('custom-card-action-button', buttonClassName)}
                  isDisabled={isStyleEditingMode}
                  text={nextButtonText || translate('BUTTON_NEXT')}
                  {...buttonStyles}
                />
              </div>
            ) : (
              <CustomButton
                textClassName={buttonClassName}
                text={translate('BUTTON_START')}
              />
            )}
          </div>
        </Card.Section>
      </div>
    </Card>
  );
}
