import { Heading } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import './styling-form-header.scss';

export interface StylingFormHeaderProps {
  title: string;
  subtitle: string | ReactElement;
}

export function StylingFormHeader({ title, subtitle }: StylingFormHeaderProps): ReactElement {
  return (
    <div className='styling-form-header'>
      <div className='styling-form-header-card'>
        <Heading>{title}</Heading>
        {subtitle}
      </div>
    </div>
  );
}
