import { instanceToPlain, plainToInstance } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiService } from '../api';
import { Analytics } from './models/analytics';
import { AnalyticsRequest } from './models/analytics-request';

class AnalyticsService {
  public search(params: AnalyticsRequest): Observable<Analytics> {
    const request = new AnalyticsRequest(omitBy<AnalyticsRequest>(params, isUndefined));

    return apiService
      .get<Analytics>('/analytics-server/facade/statistics', instanceToPlain<AnalyticsRequest>(request))
      .pipe(map((response) => plainToInstance(Analytics, response)));
  }
}

export const analyticsService = new AnalyticsService();
