import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonGroup, Page } from '@shopify/polaris';
import { debounce, isEqual } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { Path, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useEntitySelector } from '@shared/base-entity/hooks';
import { useTranslation } from '@shared/i18n';
import { Journey, JourneyRelations } from '@shared/journey';
import { journeyService } from '@shared/journey/service';
import { PaginationRequest } from '@shared/pagination';
import { ProfileSelectors } from '@shared/profile';
import { AppSpinner } from '@shared/spinner';
import { AppActions, storeRef } from '@store';
import { JourneyActivateButton } from '../shared/journey-activate-button';
import { JourneyCardBadge } from '../shared/journey-card-badge';
import { JourneyPageFrame, JourneyPageTabs } from './shared/components';
import { UpsertJourneyForm } from './shared/forms';
import { JourneyPageActions } from './shared/store';
import { JourneyPageSelectors } from './shared/store/selectors';
import { getTabsWithErrors, omitFormKeys } from './shared/utils';
import './journey.scss';

export function JourneyPage(): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY');

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const isCreating = useSelector(JourneyPageSelectors.isCreating);
  const isUpdating = !!useSelector(JourneyPageSelectors.updatingJourneyID);
  const isUpserting = isCreating || isUpdating;
  const profile = useSelector(ProfileSelectors.profile);
  const { item: editableJourney, isLoading } = useEntitySelector<Journey, PaginationRequest<JourneyRelations>>({
    params: {
      relations: [
        'questions',
        'questions.answers',
        'questions.answers.weight_statements.expressions',
        'products',
        'questions.answers.image'
      ]
    },
    id: Number(id),
    entityService: journeyService,
    onError: () => {
      storeRef.dispatch(AppActions.noop());
    }
  }); //TODO add error handling

  const form = useForm<UpsertJourneyForm>({
    defaultValues: {
      ...new UpsertJourneyForm(),
      settingsUrls: [{ type: 'page', content: `https://${profile.primaryDomain}` }]
    },
    resolver: yupResolver(UpsertJourneyForm.validationSchema),
    mode: 'onTouched'
  });

  const tabsWithErrors = getTabsWithErrors(Object.keys(form.formState.errors) as Array<Path<UpsertJourneyForm>>);
  const isChangesUnsaved =
    !!form.formState.submitCount &&
    !isLoading &&
    !isEqual(omitFormKeys(form.watch()), omitFormKeys(new UpsertJourneyForm(editableJourney)));

  const { handleSubmit } = form;

  const handleCreateJourneySuccess = (id: number): void => {
    navigate(`/journey/${id}/quiz`);
  };

  const handleSubmitFrom = (values: UpsertJourneyForm): void => {
    dispatch(
      editableJourney
        ? JourneyPageActions.updateJourney({ ...values, id: editableJourney.id })
        : JourneyPageActions.createJourney({ journey: values, onSuccess: handleCreateJourneySuccess })
    );
  };

  const debouncedSubmit = debounce(() => handleSubmit(handleSubmitFrom)(), 1000);

  const handleDiscard = (): void => {
    form.reset(new UpsertJourneyForm(editableJourney));
  };

  const toggleActivation = (): void => {
    form.setValue('isActive', !form.watch('isActive'));
  };

  const handleGoBack = (): void => {
    navigate('/');
  };

  const handlePreviewClick = (): void => {
    window.open(
      `${process.env.REACT_APP_PREVIEW_URL}?client=${profile?.name}&branch=${editableJourney?.uniqueID}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  useEffect(() => {
    if (!isNaN(Number(id)) && !editableJourney) {
      navigate('/');
    }
  }, [id]); //TODO add not found page

  useEffect(() => {
    if (editableJourney?.id) {
      form.reset(new UpsertJourneyForm(editableJourney));
    }
  }, [isLoading]);

  useEffect(() => {
    const subscription = form.watch(() => {
      if (!isLoading && !isUpserting) {
        debouncedSubmit();
      }
    });

    return () => subscription.unsubscribe();
  }, [isLoading, isUpserting, editableJourney?.id]);

  useEffect(() => {
    if (isChangesUnsaved && !isLoading && !isUpserting && !!editableJourney) {
      debouncedSubmit();
    }
  }, [isLoading, editableJourney?.id, isChangesUnsaved, !isUpserting]);

  return (
    <JourneyPageFrame
      isLoading={isCreating || isUpdating}
      isChangesUnsaved={isChangesUnsaved}
      onSaveClick={handleSubmit(handleSubmitFrom)}
      onDiscardClick={handleDiscard}>
      <Page
        title={editableJourney ? editableJourney.name : translate('TEXT_NEW_JOURNEY')}
        primaryAction={
          <ButtonGroup>
            <p className='journey-header-text'>
              {translate('TEXT_THIS_JOURNEY')}{' '}
              <span className='journey-header-text-bold'>
                {translate(form.watch('isActive') ? 'TEXT_ACTIVE' : 'TEXT_INACTIVE')}
              </span>
            </p>
            <JourneyActivateButton
              isActive={form.watch('isActive')}
              onClick={toggleActivation}
            />
            {!!editableJourney?.id && (
              <Button
                onClick={handlePreviewClick}
                plain
                monochrome
                removeUnderline>
                {translate('BUTTON_PREVIEW')}
              </Button>
            )}
          </ButtonGroup>
        }
        titleMetadata={<JourneyCardBadge isActive={form.watch('isActive')} />}
        breadcrumbs={[{ onAction: handleGoBack }]}>
        <JourneyPageTabs tabsWithErrors={tabsWithErrors} />
        {isLoading ? (
          <AppSpinner
            className='journey-spinner'
            size='large'
          />
        ) : (
          <Outlet context={{ control: form.control, editableJourney }} />
        )}
      </Page>
    </JourneyPageFrame>
  );
}
