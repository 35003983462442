import { isAnyOf } from '@reduxjs/toolkit';
import { of } from 'rxjs';
import { map, catchError, filter, switchMap } from 'rxjs/operators';
import { AppActions, Epics } from '@store';
import { analyticsService } from '../service';
import { AnalyticsActions } from './actions';

export const analyticsEpics: Epics = {
  loadAnalytics: (action$) => action$.pipe(
    filter(AnalyticsActions.loadAnalytics.match),
    switchMap((action) => analyticsService.search(action.payload).pipe(
      map((response) => AnalyticsActions.loadAnalyticsSuccess(response)),
      catchError(() => of(AnalyticsActions.loadAnalyticsFailure()))
    ))
  ),

  handleFailure: (action$) => action$.pipe(
    filter(isAnyOf(AnalyticsActions.loadAnalyticsFailure)),
    map(() => AppActions.noop()) //TODO add error handling
  )
};
