import { BaseListedEntityActions } from '@shared/base-listed-entity-store';
import { defineAction } from '@store/utils';
import { OverviewPageState } from './state';

class OverviewPageActions extends BaseListedEntityActions<OverviewPageState> {
  public setIsQuizGeneratorActive = defineAction<{ isQuizGeneratorActive: boolean }>(
    `[${this.storeTag}] Set Is Quiz Generator Active`
  );

  constructor() {
    super(
      'Overview Page'
    );
  }
}

export const overviewPageActions = new OverviewPageActions();
