import React, { Fragment, ReactElement, useMemo, PropsWithChildren } from 'react';
import { useTranslation } from '@shared/i18n';
import { AppTooltip, AppTooltipProps } from '@shared/tooltip/tooltip';

interface WeightExpressionTooltipProps {
  type: 'identify-products' | 'include' | 'condition' | 'expression-type';
}

export function WeightExpressionTooltip({
  type,
  children,
  isTriggerShown = true
}: PropsWithChildren<WeightExpressionTooltipProps> & Pick<AppTooltipProps, 'isTriggerShown'>): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_EXPRESSION_TOOLTIP');

  const content = useMemo(() => {
    switch (type) {
      case 'include':
        return (
          <Fragment>
            {translate('TEXT_CHOOSE_TO_INCLUDE')}
            <p>
              {translate('TEXT_CHOOSE')} <span className='text-bold'>{translate('TEXT_INCLUDE')}</span>{' '}
              {translate('TEXT_IF_WANT_INCLUDE')}
            </p>
            <p>
              {translate('TEXT_CHOOSE')} <span className='text-bold'>{translate('TEXT_EXCLUDE')}</span>{' '}
              {translate('TEXT_GIVE_THE_ANSWER_WEIGHTING')}
            </p>
          </Fragment>
        );
      case 'condition':
        return (
          <Fragment>
            {translate('TEXT_IF_YOU_WANT_MULTIPLE_PRODUCTS')}
            <p>
              {translate('TEXT_USE')} <span className='text-bold'>{translate('TEXT_OR')}</span>{' '}
              {translate('TEXT_SIMPLY_ADD_ATTRIBUTES')}
            </p>
            <p>
              {translate('TEXT_USE')} <span className='text-bold'>{translate('TEXT_AND')}</span>{' '}
              {translate('TEXT_ADD_FOR_BOTH')}
            </p>
          </Fragment>
        );
      case 'identify-products':
        return (
          <Fragment>
            {translate('TEXT_CHOOSE_HOW_TO_IDENTIFY')}
            <p>
              <span className='text-bold'>{translate('TEXT_PRODUCT_TAG')}</span> {translate('TEXT_TAG_USES')}
            </p>
            <p>
              <span className='text-bold'>{translate('TEXT_PRODUCT_NAME')}</span> {translate('TEXT_PRODUCT_NAME_IS')}
            </p>
            <p>
              <span className='text-bold'>{translate('TEXT_PRODUCT_CATEGORY')}</span>{' '}
              {translate('TEXT_PRODUCT_CATEGORY_IS')}
            </p>
          </Fragment>
        );
      case 'expression-type':
        return (
          <Fragment>
            <p>
              {translate('TEXT_USE')} <span className='text-bold'>{translate('TEXT_EXACTLY_MATCHES')}</span>{' '}
              {translate('TEXT_TO_RECOMMEND_EXACT_PRODUCTS')}
            </p>{' '}
            <p>
              {translate('TEXT_USE')} <span className='text-bold'>{translate('TEXT_CONTAINS')}</span>{' '}
              {translate('TEXT_TO_RECOMMEND_PRODUCTS')}
            </p>
          </Fragment>
        );
      default:
        return '';
    }
  }, [type]);

  return (
    <AppTooltip
      isTriggerShown={isTriggerShown}
      content={content}>
      {children}
    </AppTooltip>
  );
}
