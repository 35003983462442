import { PaginationRequest } from '@shared/pagination';
import { EntityService } from '../base-entity/service';
import { Journey } from './models';
import { JourneyRelations } from './types';

export class JourneyService extends EntityService<Journey, PaginationRequest<JourneyRelations>> {
  constructor() {
    super({
      endpoint: '/journeys',
      entityName: 'journey'
    });
  }
}

export const journeyService = new JourneyService();
