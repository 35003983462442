import { Expose, Type } from 'class-transformer';
import { Product } from './product';

export class ProductsPaginationResponse {
  @Expose()
  @Type(() => Product)
  public products?: Array<Product & { shopify_id: number }>;

  @Expose({ name: 'end_cursor' })
  public endCursor: string;

  @Expose({ name: 'has_next_page' })
  public hasNextPage: boolean;

  constructor(response: Partial<ProductsPaginationResponse>) {
    Object.assign(this, response);
  }
}
