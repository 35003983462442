import { AxiosError } from 'axios';
import { defineAction } from '@store/utils';
import { UpsertJourneyForm } from '../forms';

export class JourneyPageActions {
  public static createJourney = defineAction<{ journey: UpsertJourneyForm; onSuccess: (
    id: number) => void }>(
    '[Journey Page] Create Journey'
    );

  public static createJourneyFailure = defineAction<AxiosError>(
    '[Journey Page] Create Journey Failure'
  );

  public static createJourneySuccess = defineAction<{ id: number; onSuccess: (
    id: number) => void }>(
    '[Journey Page] Create Journey Success'
    );

  public static updateJourney = defineAction<UpsertJourneyForm & { id: number }>(
    '[Journey Page] Update Journey'
  );

  public static updateJourneyFailure = defineAction<AxiosError>(
    '[Journey Page] Update Journey Failure'
  );

  public static updateJourneySuccess = defineAction(
    '[Journey Page] Update Journey Success'
  );
}
