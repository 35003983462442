import { I18n } from 'i18n-js';
import translation from '@assets/i18n/en.json';
import { configuration } from '@configuration';

export const i18n = new I18n({
  en: translation,
  defaultLocale: configuration.language.default,
  locale: configuration.language.default,
  enableFallback: true
});
