import { Card, Heading, Layout } from '@shopify/polaris';
import React, { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsTimescale } from '@shared/analytics/enums';
import { AnalyticsActions } from '@shared/analytics/store';
import { AnalyticsSelectors } from '@shared/analytics/store/selectors';
import { useTranslation } from '@shared/i18n';
import { currencyFormat } from '@shared/i18n/utils/currency-format';
import { AppSpinner } from '@shared/spinner';
import { storeRef } from '@store';
import { AnalyticsChart } from '../analytics-chart';
import { PeriodTabs } from '../period-tabs/period-tabs';
import { StatisticCard } from '../statistic-card';
import './analytics-charts-group.scss';

export function AnalyticsChartsGroup(): ReactElement {
  const translate = useTranslation('SHARED.ANALYTICS');
  const { recommendations, sales } = useSelector(AnalyticsSelectors.analytics);
  const isLoading = useSelector(AnalyticsSelectors.isLoading);
  const [activeTimescale, setActiveTimescale] = useState(AnalyticsTimescale.THREE_MONTH);

  const emptyState = useMemo(
    () => (
      <Layout.Section>
        <Card sectioned>
          <div className='empty-state'>
            {translate('TEXT_ALL_YOUR_DATA')} <br />
            {translate('TEXT_GET_FIRST_QUIZ')}
          </div>
        </Card>
      </Layout.Section>
    ),
    []
  );

  useEffect(() => {
    storeRef.dispatch(
      AnalyticsActions.loadAnalytics({
        timescale: activeTimescale
      })
    );
  }, [activeTimescale]);

  return (
    <div id='analytics'>
      <Layout>
        <Layout.Section>
          <div className='analytics-charts-group-header'>
            <Heading>{translate('TEXT_ANALYTICS')}</Heading>
            <PeriodTabs
              activePeriod={activeTimescale}
              onPeriodClick={setActiveTimescale}
            />
          </div>
        </Layout.Section>
        {isLoading ? (
          <Layout.Section>
            <AppSpinner
              className='analytics-charts-group-spinner'
              size='large'
            />
          </Layout.Section>
        ) : !sales?.dailyStats?.length && !recommendations?.dailyStats?.length ? (
          emptyState
        ) : (
          <Fragment>
            {!recommendations?.dailyStats?.length ? (
              emptyState
            ) : (
              <Layout.Section fullWidth={true}>
                <div className='analytics-charts-group-section'>
                  <div className='analytics-charts-group-statistics'>
                    <StatisticCard
                      containerClassName='analytics-charts-group-recommendation-card'
                      value={recommendations?.total}
                      title={translate('TEXT_RECOMMENDATIONS_MADE')}
                    />
                  </div>
                  <div className='analytics-charts-group-chart'>
                    <AnalyticsChart
                      data={recommendations?.dailyStats}
                      title={translate('TEXT_RECOMMENDATIONS_BY_DAY')}
                    />
                  </div>
                </div>
              </Layout.Section>
            )}
            {!sales?.dailyStats?.length ? (
              emptyState
            ) : (
              <Layout.Section fullWidth={true}>
                <div className='analytics-charts-group-section'>
                  <div className='analytics-charts-group-chart'>
                    <AnalyticsChart
                      data={sales?.dailyStats}
                      title={translate('TEXT_SALES_BY_DAY')}
                    />
                  </div>
                  <div className='analytics-charts-group-statistics'>
                    <StatisticCard
                      containerClassName='analytics-charts-group-sales-card'
                      value={sales?.total}
                      title={translate('TEXT_ASSISTED_SALES')}
                    />
                    <StatisticCard
                      containerClassName='analytics-charts-group-sales-card'
                      value={currencyFormat(sales?.totalRevenue)}
                      title={translate('TEXT_ASSISTED_REVENUE')}
                    />
                  </div>
                </div>
              </Layout.Section>
            )}
          </Fragment>
        )}
      </Layout>
    </div>
  );
}
