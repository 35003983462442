import { Select, SelectProps } from '@shopify/polaris';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import './inline-select.scss';

interface InlineSelectProps extends SelectProps {
  containerClassName?: string;
}

export function InlineSelect({ label, containerClassName, ...restProps }: InlineSelectProps): ReactElement {
  return (
    <div className={clsx('inline-select', containerClassName)}>
      {label}
      <div className='inline-select-input'>
        <Select
          labelHidden
          label=''
          {...restProps}
        />
      </div>
    </div>
  );
}
