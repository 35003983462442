import { Card, Icon, Tooltip } from '@shopify/polaris';
import { QuestionMarkMajor } from '@shopify/polaris-icons';
import clsx from 'clsx';
import React, { FunctionComponent, ReactElement, useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { i18n, useTranslation } from '@shared/i18n';
import { ProfileSelectors } from '@shared/profile';
import { ConditionForm } from '../condition-form';

interface QuestionConditionProps {
  control: Control<UpsertJourneyForm>;
  index: number;
}

export function QuestionCondition({ control, index }: QuestionConditionProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.QUESTION_CONDITION');

  const isFreePlan = useSelector(ProfileSelectors.isFreePlan);

  const { field: conditionField } = useController({ control, name: `questions.${index}.condition` });
  const { field: questionsField } = useController({ control, name: 'questions' });

  const renderTrigger: FunctionComponent<{ onClick: () => void }> = useCallback(
    ({ onClick }) => (
      <div className='question-condition-form-add-condition'>
        <button
          disabled={isFreePlan}
          className={clsx('button-primary-plain', isFreePlan && 'button-primary-plain-disabled')}
          onClick={onClick}>
          {translate('BUTTON_ADD_CONDITION')}
        </button>
        <div className='question-condition-form-tooltip'>
          <Tooltip
            content={isFreePlan ? i18n.t('COMMON.TEXT_UPGRADE_TO_UNLOCK_BRANCHING') : translate('BUTTON_TOOLTIP')}>
            <Icon
              source={QuestionMarkMajor}
              color='interactive'
            />
          </Tooltip>
        </div>
      </div>
    ),
    [isFreePlan]
  );

  return (
    <Card.Section>
      <ConditionForm
        renderTrigger={renderTrigger}
        fieldValue={conditionField.value}
        questions={questionsField.value}
        onChange={conditionField.onChange}
      />
    </Card.Section>
  );
}
