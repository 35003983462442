import { Exclude, Expose } from 'class-transformer';
import { ConditionType } from '../types';

export class Condition {
  @Expose()
  public type: ConditionType;

  @Expose()
  public value?: string;

  @Exclude()
  public key?: string;

  constructor(condition?: Partial<Condition>) {
    Object.assign(this, condition);
  }
}
