import { Card, Collapsible, FormLayout } from '@shopify/polaris';
import React, { ReactElement, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { CollapseButton } from '@shared/collapse-button';
import { DeleteButton } from '@shared/delete-button';
import { FormInput } from '@shared/form-input';
import { useTranslation } from '@shared/i18n';
import { CardHeader } from '../../../../shared/components/card-header';
import { UpsertJourneyForm } from '../../../../shared/forms';
import { AnswerImageInput } from '../answer-image-input';
import { WeightStatementList } from '../weight-statement-list/weight-statement-list';
import './answer-card.scss';

interface AnswerCardProps {
  control: Control<UpsertJourneyForm>;
  questionIndex: number;
  answerIndex: number;
}

export function AnswerCard({ control, answerIndex, questionIndex }: AnswerCardProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.ANSWER_CARD');

  const { field: answerField } = useController({ control, name: `questions.${questionIndex}.answers.${answerIndex}` });
  const { field: answersField } = useController({ control, name: `questions.${questionIndex}.answers` });

  const handleDeleteClick = (): void => {
    answersField.onChange(answersField.value.filter((_, i) => i !== answerIndex));
  };

  const toggleCollapse = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Card.Section>
      <CardHeader
        title={translate('TEXT_ANSWER_TITLE', { number: answerIndex + 1, answer: answerField.value.text || '' })}
        iconContent={<DeleteButton onClick={handleDeleteClick} />}
        actionsContent={
          <CollapseButton
            isRotate={isCollapsed}
            onClick={toggleCollapse}
          />
        }
      />
      <Collapsible
        id='basic-collapsible'
        expandOnPrint={true}
        open={!isCollapsed}>
        <div className='answer-card-form'>
          <FormLayout>
            <FormInput
              value={answersField.value[answerIndex].text}
              autoComplete='off'
              name={`questions.${questionIndex}.answers.${answerIndex}.text`}
              control={control}
              label={translate('TEXT_ANSWER')}
            />
            <AnswerImageInput
              answerIndex={answerIndex}
              questionIndex={questionIndex}
            />
            <WeightStatementList
              control={control}
              answerIndex={answerIndex}
              questionIndex={questionIndex}
            />
          </FormLayout>
        </div>
      </Collapsible>
    </Card.Section>
  );
}
