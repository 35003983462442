import { useSelector } from 'react-redux';
import { BaseListedEntityFacade } from '@shared/base-listed-entity-store/facade';
import { Product, ProductsPagination, ProductsPaginationRequest } from '@shared/product';
import { storeRef } from '@store';
import { journeySurveyActions, JourneySurveyState } from './shared/store';
import { journeySurveySelectors } from './shared/store/selectors';

class JourneySurveyFacade extends BaseListedEntityFacade<
  JourneySurveyState,
  Product,
  typeof journeySurveyActions,
  typeof journeySurveySelectors
> {
  constructor() {
    super(journeySurveyActions, journeySurveySelectors);
  }

  public loadProducts(request?: ProductsPaginationRequest): void {
    storeRef.dispatch(this.actions.loadItems(request));
  }

  public changeProductsSearchQuery(query: string): void {
    storeRef.dispatch(this.actions.changeProductsSearchQuery({ query }));
  }

  public get productsPagination(): ProductsPagination {
    return useSelector(this.selectors.productsPagination);
  }
}

export const journeySurveyFacade = new JourneySurveyFacade();
