import { Layout } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { Control } from 'react-hook-form';
import { useOutletContext } from 'react-router';
import { UpsertJourneyForm } from '../shared/forms';
import { EngagementForm } from './shared/components/engagement-form';
import { FontsForm } from './shared/components/fonts-form';
import { SurveyStylesForm } from './shared/components/survey-styles-form';
import './styling.scss';

export function JourneyStyling(): ReactElement {
  const { control } = useOutletContext<{ control: Control<UpsertJourneyForm> }>();

  return (
    <div className='styling journey-tab-layout page-layout'>
      <Layout>
        <FontsForm control={control} />
        <EngagementForm control={control} />
        <SurveyStylesForm control={control} />
      </Layout>
    </div>
  );
}
