import { Observable } from 'rxjs';
import { apiService } from '@shared/api';

class ProductTypeService {
  public search(): Observable<Array<string>> {
    return apiService.get<Array<string>>('/plugin/facade/product-types');
  }
}

export const productTypeService = new ProductTypeService();
