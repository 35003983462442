import { Tag } from '@shopify/polaris';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from '@shared/i18n';
import { InlineSelect } from '@shared/inline-select';
import { Question } from '@shared/journey';
import './question-select.scss';

interface QuestionSelectProps {
  questions?: Array<Question>;
  isFirst?: boolean;
  onQuestionSelect: (index: number, value: string) => void;
  questionValue: string;
  index: number;
}

export function QuestionSelect({
  questions,
  isFirst,
  onQuestionSelect,
  questionValue,
  index
}: QuestionSelectProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.QUESTION_CONDITION.QUESTION_SELECT');

  const handleSelectQuestion = (selected: string): void => {
    onQuestionSelect(index, selected);
  };

  const selectOptions = useMemo(
    () => questions?.map((question) => ({ label: question.title, value: question.idWithinJourney })),
    [questions]
  );

  useEffect(() => {
    if (!questionValue) {
      handleSelectQuestion(questions?.[0]?.idWithinJourney);
    }
  }, [questions]);

  return (
    <InlineSelect
      containerClassName='question-select'
      label={
        isFirst ? (
          <p className='question-select-label'>{translate('TEXT_IF_ANSWER')}</p>
        ) : (
          <Tag>{translate('TEXT_THE_ANSWER')}</Tag>
        )
      }
      placeholder={translate('TEXT_QUESTION')}
      options={selectOptions}
      onChange={handleSelectQuestion}
      value={questionValue}
    />
  );
}
