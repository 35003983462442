import { ActionList, Button, ButtonGroup, Card, Popover } from '@shopify/polaris';
import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { JourneyPageActions } from '@app/main/journey/shared/store';
import { JourneyPageSelectors } from '@app/main/journey/shared/store/selectors';
import { overviewPageFacade } from '@app/main/overview/facade';
import { JourneyActivateButton } from '@app/main/shared/journey-activate-button';
import { JourneyCardBadge } from '@app/main/shared/journey-card-badge';
import { DeleteJourneyModal } from '@shared/delete-journey-modal';
import { useTranslation } from '@shared/i18n';
import { Journey } from '@shared/journey';
import { storeRef } from '@store';
import './journey-card.scss';

interface JourneyCardProps {
  journey: Journey;
}

export function JourneyCard({ journey }: JourneyCardProps): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.JOURNEY_CARD');

  const [isActivePopover, setIsActivePopover] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const updatingJourneyID = useSelector(JourneyPageSelectors.updatingJourneyID);

  const navigate = useNavigate();

  const handleEditClick = (): void => {
    navigate(`./journey/${journey.id}/quiz`);
  };

  const toggleModal = (): void => {
    setIsActiveModal(!isActiveModal);
  };

  const handleConfirmDelete = (): void => {
    setIsActiveModal(false);
    overviewPageFacade.deleteItem(journey.id);
  };

  const togglePopover = (): void => {
    setIsActivePopover(!isActivePopover);
  };

  const toggleActivation = (): void => {
    storeRef.dispatch(JourneyPageActions.updateJourney({ ...journey, isActive: !journey.isActive }));
  };

  return (
    <div className='journey-card col-50'>
      <DeleteJourneyModal
        isActive={isActiveModal}
        onCloseModal={toggleModal}
        onDeleteClick={handleConfirmDelete}
      />
      <Card>
        <Card.Header title={journey.name}>
          <JourneyCardBadge isActive={journey.isActive} />
        </Card.Header>
        <Card.Section>
          <div className='journey-card-actions'>
            <ButtonGroup>
              <Popover
                active={isActivePopover}
                activator={
                  <Button
                    disclosure={isActivePopover ? 'up' : 'down'}
                    onClick={togglePopover}
                    monochrome
                    plain
                    removeUnderline>
                    {translate('BUTTON_MORE_ACTIONS')}
                  </Button>
                }
                onClose={togglePopover}>
                <ActionList
                  actionRole='menuitem'
                  items={[
                    {
                      content: translate('BUTTON_EDIT'),
                      suffix: <div className='journey-card-extra-space' />,
                      onAction: handleEditClick
                    },
                    { content: translate('BUTTON_DELETE'), onAction: toggleModal }
                  ]}
                />
              </Popover>
              <JourneyActivateButton
                isLoading={updatingJourneyID === journey.id}
                isActive={journey.isActive}
                onClick={toggleActivation}
              />
            </ButtonGroup>
          </div>
        </Card.Section>
      </Card>
    </div>
  );
}
