import { Expose, Type } from 'class-transformer';
import { RecommendationsAnalytics } from './recommendations-analytics';
import { SalesAnalytics } from './sales-analytics';

export class Analytics {
  @Expose()
  @Type(() => RecommendationsAnalytics)
  public recommendations?: RecommendationsAnalytics;

  @Expose()
  @Type(() => SalesAnalytics)
  public sales?: SalesAnalytics;

  constructor(analytics?: Partial<Analytics>) {
    Object.assign(this, analytics);
  }
}
