import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';

export function TransformDate(
  { format, withoutTime }: { format?: 'SQL' | 'ISO'; withoutTime: boolean } = { format: 'ISO', withoutTime: false }
  // eslint-disable-next-line @typescript-eslint/ban-types
): Function {
  const toPlain = Transform(
    ({ value }) => {
      if (DateTime.isDateTime(value)) {
        const utcValue = value.toUTC();

        switch (format) {
          case 'SQL':
            return withoutTime ? utcValue.toSQLDate() : utcValue.toSQL();
          case 'ISO':
          default:
            return withoutTime ? utcValue.toISODate() : utcValue.toISO();
        }
      }

      return value;
    },
    { toPlainOnly: true }
  );

  const toClass = Transform(
    ({ value }) => {
      if (!value || DateTime.isDateTime(value)) {
        return value;
      }

      switch (format) {
        case 'SQL':
          return DateTime.fromSQL(value);
        case 'ISO':
        default:
          return DateTime.fromISO(value);
      }
    },
    { toClassOnly: true }
  );

  return (target: any, key: string): void => {
    toPlain(target, key);
    toClass(target, key);
  };
}
