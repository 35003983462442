import { Modal, Layout, TextField, Frame, Toast, Spinner, Text, Checkbox } from '@shopify/polaris';
import { delay } from 'lodash';
import React, { Fragment, ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { JourneyPageActions } from '@app/main/journey/shared/store';
import { journeySurveyFacade } from '@app/main/journey/survey/facade';
import { useTranslation } from '@shared/i18n';
import { ProfileSelectors } from '@shared/profile';
import { quizGeneratorService } from '@shared/quiz-generator';
import { overviewPageActions } from '../../store';
import { overviewPageSelectors } from '../../store/selectors';
import './quiz-generator.scss';

export function QuizGenerator(): ReactElement {
  const [quizName, setQuizName] = useState('');
  const [quizLoading, setQuizLoading] = useState(false);
  const [errorActive, setErrorActive] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [shouldUseShopStyling, setShouldUseShopStyling] = useState(true);
  const profile = useSelector(ProfileSelectors.profile);
  const isQuizGeneratorActive = useSelector(overviewPageSelectors.isQuizGeneratorActive);
  const { items: products, isLoading } = journeySurveyFacade;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.QUIZ_GENERATOR_MODAL');

  const handleClose = (): void => {
    dispatch(overviewPageActions.setIsQuizGeneratorActive({ isQuizGeneratorActive: false }));
  };

  useEffect(() => {
    if (profile) {
      journeySurveyFacade.loadItems();
    }
  }, [profile]);

  useEffect(() => {
    if (!quizLoading || loadingMessageIndex >= translate('LOADING_MESSAGES').length - 1) {
      return;
    }
    delay(() => setLoadingMessageIndex(loadingMessageIndex + 1), 8000);
  }, [quizLoading, loadingMessageIndex]);

  const handleOnSuccess = (id: number): void => {
    handleClose();
    navigate(`/journey/${id}/quiz`, { state: { generated: true } });
  };

  const handleOnError = (): void => {
    handleClose();
    setErrorActive(true);
  };

  const handleGenerateQuiz = async (): Promise<void> => {
    try {
      const shop = profile.name;
      setQuizLoading(true);
      const { data: journey } = await quizGeneratorService.generate({
        shop,
        quizName,
        products: products.map((product) => ({ title: product.title, productType: product.productType }))
      });

      dispatch(
        JourneyPageActions.createJourney({
          journey: { ...journey, isAiGenerated: true, shouldUseShopStyling },
          onSuccess: handleOnSuccess
        })
      );
    } catch (err) {
      handleOnError();
    } finally {
      setQuizLoading(false);
      handleClose();
    }
  };

  const quizFormMarkup = (): ReactElement => (
    <Layout>
      <Layout.Section>
        <TextField
          label={translate('TEXT_FIELD_LABEL')}
          value={quizName}
          onChange={setQuizName}
          placeholder={translate('TEXT_FIELD_PLACEHOLDER')}
          autoComplete='off'
          maxLength={50}
        />
        <div className='quiz-generator-store-style-checkbox'>
          <Checkbox
            onChange={setShouldUseShopStyling}
            disabled={isLoading || quizLoading}
            checked={shouldUseShopStyling}
            label={translate('TEXT_STORE_STYLING_CHECKBOX_LABEL')}
          />
        </div>
      </Layout.Section>
    </Layout>
  );

  const loadingMarkup = (): ReactElement => (
    <Layout>
      <Layout.Section>
        <div className='quiz-generator-text'>
          <Spinner size='large' />
        </div>
      </Layout.Section>
      {quizLoading && (
        <Layout.Section>
          <div className='quiz-generator-text'>
            <Text
              variant='bodyLg'
              as='p'>
              {translate('LOADING_MESSAGES')[loadingMessageIndex]}
            </Text>
          </div>
        </Layout.Section>
      )}
      <Layout.Section>
        <p className='quiz-generator-text'>
          {isLoading ? translate('TEXT_GENERATOR_LOADING') : translate('TEXT_QUIZ_LOADING')}
        </p>
      </Layout.Section>
    </Layout>
  );

  return (
    <Fragment>
      <Modal
        open={isQuizGeneratorActive}
        title={translate('TEXT_TITLE')}
        onClose={handleClose}
        primaryAction={{
          content: translate('TEXT_BUTTON'),
          onAction: handleGenerateQuiz,
          disabled: quizLoading || isLoading
        }}>
        <Modal.Section>{quizLoading || isLoading ? loadingMarkup() : quizFormMarkup()}</Modal.Section>
      </Modal>
      <div className='quiz-generator-toast'>
        {errorActive && (
          <Frame>
            <Toast
              content={translate('TEXT_ERROR')}
              onDismiss={() => setErrorActive(false)}
              duration={3500}
              error
            />
          </Frame>
        )}
      </div>
    </Fragment>
  );
}
