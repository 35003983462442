import { Expose } from 'class-transformer';
import { BaseEntity } from '../../base-entity/models';

export class Media extends BaseEntity<number> {
  @Expose()
  public link: string;

  constructor(model: Partial<Media>) {
    super(model);
    Object.assign(this, model);
  }
}
