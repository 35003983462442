import { BaseListedEntityActions } from '@shared/base-listed-entity-store';
import { ProductsPaginationResponse } from '@shared/product/models/products-pagination-response';
import { defineAction } from '@store/utils';
import { JourneySurveyState } from './state';

class JourneySurveyActions extends BaseListedEntityActions<JourneySurveyState> {
  public changeProductsSearchQuery = defineAction<{ query: string }>(
    `[${this.storeTag}] Change ProductsSearch Query`
  );

  public loadProductsSuccess = defineAction<ProductsPaginationResponse>(
    `[${this.storeTag}] Load Products Success`
  );

  constructor() {
    super(
      'Journey Survey'
    );
  }
}

export const journeySurveyActions = new JourneySurveyActions();
