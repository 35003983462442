import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from '@shared/i18n';
import { InlineSelect } from '@shared/inline-select';
import { Answer, ConditionType } from '@shared/journey';
import './answer-select.scss';

interface AnswerSelectProps {
  answers?: Array<Answer>;
  onAnswerSelect: (index: number, value: string) => void;
  onToggleHasCondition: (index: number) => void;
  index: number;
  value: string;
  type: ConditionType;
}

export function AnswerSelect({
  answers,
  onAnswerSelect,
  index,
  value,
  onToggleHasCondition,
  type
}: AnswerSelectProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.QUESTION_CONDITION.ANSWER_SELECT');

  const handleAnswerSelect = (selected: string): void => {
    onAnswerSelect(index, selected);
  };

  const handleToggleHasCondition = (): void => {
    onToggleHasCondition(index);
  };

  const answerOptions = useMemo(
    () => answers?.map((answer) => ({ label: answer?.text, value: answer?.idWithinJourney })),
    [answers]
  );

  const conditionOptions = [
    { label: translate('TEXT_HAS'), value: 'has' },
    { label: translate('TEXT_DOES_NOT_HAVE'), value: 'doesNotHave' }
  ];

  useEffect(() => {
    if (!value) {
      onAnswerSelect(index, answers?.[0]?.idWithinJourney);
    }
  }, [answers]);

  return (
    <div className='answer-select'>
      <InlineSelect
        label=''
        options={conditionOptions}
        onChange={handleToggleHasCondition}
        value={type}
      />
      <InlineSelect
        label=''
        options={answerOptions}
        onChange={handleAnswerSelect}
        value={value}
      />
    </div>
  );
}
