import { Layout, Card, Heading, Button } from '@shopify/polaris';
import React, { Fragment, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { overviewPageFacade } from '@app/main/overview/facade';
import { useTranslation } from '@shared/i18n';
import { AppSpinner } from '@shared/spinner';
import { JourneyCard } from '../journey-card';
import './your-journeys.scss';

interface YourJourneysProps {
  isJourneysLoading: boolean;
}

export function YourJourneys({ isJourneysLoading }: YourJourneysProps): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.JOURNEY_BLOCK');
  const navigate = useNavigate();

  const { items } = overviewPageFacade;

  const handleCreateJourneyClick = (): void => {
    navigate('/journey/new/quiz');
  };

  return (
    <Layout>
      <Layout.Section fullWidth>
        <div className='your-journeys-header'>
          <Heading>{translate('TEXT_TITLE')}</Heading>
          {!!items.length && (
            <Button
              primary
              onClick={handleCreateJourneyClick}>
              {translate('BUTTON_CREATE')}
            </Button>
          )}
        </div>
      </Layout.Section>
      {isJourneysLoading ? (
        <Layout.Section>
          <AppSpinner
            className='your-journeys-spinner'
            size='large'
          />
        </Layout.Section>
      ) : (
        <Fragment>
          <Layout.Section>
            {items.length ? (
              <div className='your-journeys-cards'>
                {items.map((journey) => (
                  <JourneyCard
                    key={journey.id}
                    journey={journey}
                  />
                ))}
              </div>
            ) : (
              <Card sectioned>
                <div className='empty-state'>
                  {translate('TEXT_YOUR_QUIZZES')} <br />
                  {translate('TEXT_GENERATE_QUIZ')}
                </div>
              </Card>
            )}
          </Layout.Section>
        </Fragment>
      )}
    </Layout>
  );
}
