import { AxiosError } from 'axios';
import { User } from '@shared/user';
import { defineAction } from '@store/utils';
import { ProfileRequest } from '../models';

export class ProfileActions {
  public static fetchProfile = defineAction<ProfileRequest>(
    '[Profile] Fetch Profile'
  );

  public static fetchProfileSuccess = defineAction<User>(
    '[Profile] Fetch Profile Success'
  );

  public static fetchProfileFailure = defineAction<AxiosError>(
    '[Profile] Fetch Profile Failure'
  );
  public static setExtensionLink = defineAction<{ extensionLink: string | null }>(
    '[Profile] Set Extension Link'
  );
}
