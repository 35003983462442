import { Spinner, SpinnerProps } from '@shopify/polaris';
import React, { ReactElement } from 'react';

interface AppSpinnerProps extends SpinnerProps {
  className?: string;
}

export function AppSpinner({ className, size }: AppSpinnerProps): ReactElement {
  return (
    <div className={className}>
      <Spinner size={size} />
    </div>
  );
}
