import { Button, Card, FormLayout, ResourceList } from '@shopify/polaris';
import clsx from 'clsx';
import { uniqBy } from 'lodash';
import React, { ReactElement, useCallback, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { ResourceListItem } from '@app/main/journey/shared/components/resource-list-item';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { CardTitle } from '@shared/card-title';
import { FormInput } from '@shared/form-input';
import { useTranslation } from '@shared/i18n';
import { Product } from '@shared/product';
import { journeySurveyFacade } from '../../../facade';
import { ProductsSelect } from '../products-select';
import './default-recommendations.scss';

interface DefaultRecommendationsProps {
  control: Control<UpsertJourneyForm>;
}

export function DefaultRecommendations({ control }: DefaultRecommendationsProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.DEFAULT_RECOMMENDATIONS');
  const [isProductsModalOpen, setIsProductsModalOpen] = useState(false);
  const { items } = journeySurveyFacade;

  const { field: productsField } = useController({ control, name: 'products' });

  const selectedProductsIDs = productsField.value?.map((product) => String(product?.shopifyID)) || [];

  const handleProductsModalToggle = (): void => {
    setIsProductsModalOpen(!isProductsModalOpen);
  };

  const handleRemoveProducts = (id: number | string): void => {
    productsField.onChange(productsField.value?.filter((product) => product.shopifyID !== id));
  };

  const handleSelectProducts = (selectedIDs: Array<string>): void => {
    const uniqProducts = uniqBy(items.concat(productsField.value as Array<Product>), 'shopifyID');
    const selectedProducts = uniqProducts.filter((product) => selectedIDs.includes(String(product.shopifyID)));
    productsField.onChange(selectedProducts);
  };

  const renderItem = useCallback(
    (item: Product) => {
      return (
        <ResourceListItem
          image={item.image}
          id={item.shopifyID}
          title={item.title}
          onRemoveClick={handleRemoveProducts}
        />
      );
    },
    [productsField.value]
  );

  return (
    <Card sectioned>
      <div className='default-recommendations'>
        <div className='default-recommendations-row'>
          <CardTitle>{translate('TEXT_RECOMMENDATIONS')}</CardTitle>
          <p className='default-recommendations-description'>{translate('TEXT_RECOMMENDATIONS_DESCRIPTION')}</p>
          <FormLayout>
            <FormInput<UpsertJourneyForm>
              autoComplete='off'
              label={translate('TEXT_RECOMMENDATIONS_TITLE')}
              name='recommendationsTitle'
              control={control}
            />
            <FormInput<UpsertJourneyForm>
              autoComplete='off'
              label={translate('TEXT_OPTIONAL_TEXT')}
              name='recommendationsText'
              control={control}
            />
            <FormInput<UpsertJourneyForm>
              autoComplete='off'
              label={translate('TEXT_CALL_TO_ACTION')}
              name='recommendationsCtaButtonText'
              control={control}
            />
            <ProductsSelect
              selectedProducts={selectedProductsIDs}
              onSelectProducts={handleSelectProducts}
              onToggleModal={handleProductsModalToggle}
              isOpen={isProductsModalOpen}
            />
          </FormLayout>
        </div>
        <div className='default-recommendations-row'>
          <div className={clsx(!!productsField.value.length && 'default-recommendations-products-list')}>
            <ResourceList<Partial<Product>>
              items={productsField.value}
              renderItem={renderItem}
            />
          </div>
          <Button onClick={handleProductsModalToggle}>{translate('BUTTON_ADD_PRODUCTS')}</Button>
        </div>
      </div>
    </Card>
  );
}
