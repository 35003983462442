import { Exclude, Expose } from 'class-transformer';

export class Page {
  @Expose()
  public url: string;

  @Expose()
  public handle?: string;

  @Exclude()
  public key?: string;

  constructor(page?: Partial<Page>) {
    Object.assign(this, page);
  }
}
