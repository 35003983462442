import clsx from 'clsx';
import React, { Fragment, ReactElement } from 'react';
import { useTranslation } from '@shared/i18n';
import { WeightExpressionComparisonType } from '@shared/journey';
import { WeightExpression } from '@shared/journey/models/weight-expression';
import { IncludeType } from '@shared/journey/types/include-type';
import { WeightExpressionAttribute } from '@shared/journey/types/weight-attribute';
import { WeightingExpressionCondition } from '@shared/journey/types/weighting-expression-condition';

interface WeightExpressionStringProps {
  weightExpressions: Array<WeightExpression>;
}

export function WeightExpressionString({ weightExpressions }: WeightExpressionStringProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_EXPRESSION_STRING');

  const getConditionTranslation = (condition: WeightingExpressionCondition): string => {
    if (condition) {
      return condition === 'and' ? translate('TEXT_AND') : translate('TEXT_OR');
    } else {
      return '';
    }
  };

  const getIncludeTypeTranslation = (type: IncludeType): string => {
    return type === 'exclude' ? translate('TEXT_EXCLUDE') : translate('TEXT_INCLUDE');
  };

  const getValueConditionTranslation = (type: Omit<WeightExpressionComparisonType, 'contains'>): string => {
    switch (type) {
      case 'less':
        return translate('TEXT_LESS_THAN');
      case 'more':
        return translate('TEXT_MORE_THAN');
      case 'equals':
        return translate('TEXT_EQUALS_TO');
    }
  };

  const getAttributeTranslation = (attribute: WeightExpressionAttribute): string => {
    switch (attribute) {
      case 'product_tag':
        return translate('TEXT_TAG');
      case 'product_title':
        return translate('TEXT_NAME');
      case 'product_type':
        return translate('TEXT_CATEGORY');
      case 'product_price':
        return translate('TEXT_PRICE');
    }
  };

  const getExpressionString = (expression: WeightExpression, index: number): ReactElement => {
    return (
      <span>
        {getConditionTranslation(expression.condition)}{' '}
        <span className={clsx(index === 0 && 'text-capitalize')}>
          {getIncludeTypeTranslation(expression.includeType)}
        </span>{' '}
        {translate('TEXT_PRODUCTS_WITH')}{' '}
        {expression.attribute === 'product_price' ? (
          <Fragment>
            {translate('TEXT_PRICE')} {getValueConditionTranslation(expression.comparisonType)}{' '}
            <span className='text-bold'>{expression?.value}</span>{' '}
          </Fragment>
        ) : (
          <Fragment>
            <span className='text-bold'>{expression?.value}</span> {getAttributeTranslation(expression.attribute)}{' '}
          </Fragment>
        )}
      </span>
    );
  };

  return <Fragment>{weightExpressions?.map((expression, index) => getExpressionString(expression, index))}</Fragment>;
}
