import { instanceToPlain, plainToInstance } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@shared/user';
import { apiService } from '../api';
import { ProfileRequest } from './models';

class ProfileService {
  public getProfile(params?: ProfileRequest): Observable<User> {
    const request = new ProfileRequest(omitBy<ProfileRequest>(params, isUndefined));

    return apiService
      .get<User>('/profile', instanceToPlain<ProfileRequest>(request))
      .pipe(map((response) => plainToInstance(User, response)));
  }
}

export const profileService = new ProfileService();
