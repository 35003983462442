import { useCallback, useState } from 'react';
import { lastValueFrom, Observable, takeUntil } from 'rxjs';
import { useComponentLifecycle } from '@shared/use-component-lifecycle';

export const useSearchData = <TData, TRequest = any>(service: {
  search: (params?: TRequest) => Observable<TData>;
}): typeof result => {
  const { destroyed$ } = useComponentLifecycle();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchError, setIsFetchError] = useState<boolean>();
  const [data, setData] = useState<TData>();

  const search = useCallback(
    async (params?: TRequest): Promise<void> => {
      setIsLoading(true);
      try {
        const data = await lastValueFrom(service.search(params).pipe(takeUntil(destroyed$)));
        setData(data);
        setIsLoading(false);
        setIsFetchError(false);
      } catch (error) {
        setIsLoading(false);
        setIsFetchError(true);
      }
    },
    [setIsLoading, isLoading]
  );

  const result = { isLoading, isFetchError, search, data, setIsFetchError, setData };

  return result;
};
