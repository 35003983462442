import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { AnalyticsActions } from './actions';
import { AnalyticsState } from './state';

const initialState: AnalyticsState = {
  isLoading: true,
  analytics: {}
};

export const analyticsReducer = createReducer(initialState, (builder) => builder
  .addCase(AnalyticsActions.loadAnalytics, (state) => {
    if (!state.analytics?.recommendations && !state.analytics?.sales) {
      state.isLoading = true;
    }
  })
  .addCase(AnalyticsActions.loadAnalyticsSuccess, (state, { payload }) => {
    state.analytics = merge(state.analytics, payload);
    state.isLoading = false;
  })
  .addMatcher(isAnyOf(AnalyticsActions.loadAnalyticsFailure), (state) => {
    state.isLoading = false;
  }));
