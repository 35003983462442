import { Button, ButtonGroup } from '@shopify/polaris';
import { nanoid } from 'nanoid';
import React, { ReactElement, useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { JourneyPageSelectors } from '@app/main/journey/shared/store/selectors';
import { useTranslation } from '@shared/i18n';
import { WeightExpression } from '@shared/journey/models/weight-expression';
import { WeightStatement } from '@shared/journey/models/weight-statement';
import { StatementCondition } from '../../../statement-condition';
import { StatementWeight } from '../statement-weight';
import { WeightExpressionField } from '../weight-expression-field';
import './weight-statement-form.scss';

export interface WeightStatementFormProps {
  statementIndex: number;
  answerIndex: number;
  questionIndex: number;
  control: Control<UpsertJourneyForm>;
  onDelete: (index: number) => void;
  onFinishEditing: () => void;
}

export function WeightStatementFrom({
  statementIndex,
  answerIndex,
  questionIndex,
  control,
  onDelete,
  onFinishEditing
}: WeightStatementFormProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_STATEMENT_FORM');

  const isUpdating = !!useSelector(JourneyPageSelectors.updatingJourneyID);
  const isCreating = useSelector(JourneyPageSelectors.isCreating);

  const [initialFieldsValues, setInitialFieldsValues] = useState<WeightStatement>();

  const { field: expressionsField } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements.${statementIndex}.expressions`
  });

  const { field: weightField, fieldState: weightFieldState } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.weightStatements.${statementIndex}.weight`
  });

  const handelSave = (): void => {
    setInitialFieldsValues({
      expressions: expressionsField?.value?.map((expression) => new WeightExpression(expression)),
      weight: weightField.value
    });
    onFinishEditing();
  };

  const handleDeleteExpression = (expressionIndex: number): void => {
    expressionsField.onChange(expressionsField.value.filter((_, i) => i !== expressionIndex));
  };

  const handleCancel = (): void => {
    expressionsField.onChange(initialFieldsValues.expressions);
    weightField.onChange(initialFieldsValues.weight);
    onFinishEditing();
  };

  const handleDeleteStatement = (): void => {
    onDelete(statementIndex);
  };

  const handleWeightChange = (value: number): void => {
    weightField.onChange(value);
  };

  const handleAddAttributeClick = (): void => {
    expressionsField.onChange(
      (expressionsField.value || []).concat(
        new WeightExpression({
          key: nanoid(),
          condition: expressionsField.value?.length ? 'and' : null,
          includeType: 'include',
          comparisonType: 'contains',
          attribute: 'product_tag'
        })
      )
    );
  };

  useEffect(() => {
    setInitialFieldsValues({
      expressions: expressionsField?.value?.map((expression) => new WeightExpression(expression)),
      weight: weightField.value
    });
  }, [isUpdating, isCreating]);

  return (
    <div className='weight-statement-form'>
      <p className='weight-statement-form-title'>{translate('TEXT_STATEMENT', { number: statementIndex + 1 })}</p>
      <StatementCondition
        control={control}
        questionIndex={questionIndex}
        answerIndex={answerIndex}
        statementIndex={statementIndex}
      />
      <StatementWeight
        errorMessage={weightFieldState.error?.message}
        onChange={handleWeightChange}
        value={weightField.value}
      />
      {!!expressionsField.value?.length && (
        <div className='weight-statement-form-expressions'>
          {expressionsField.value?.map((expression, index) => (
            <WeightExpressionField
              onDelete={handleDeleteExpression}
              control={control}
              key={expression.key}
              weightingStatementIndex={statementIndex}
              answerIndex={answerIndex}
              questionIndex={questionIndex}
              weightExpressionIndex={index}
            />
          ))}
        </div>
      )}
      <button
        onClick={handleAddAttributeClick}
        className='weight-statement-form-add-attributes button-primary-plain'>
        {translate('BUTTON_ADD_MORE_PRODUCTS')}
      </button>
      <div className='weight-statement-form-actions'>
        <ButtonGroup>
          <Button onClick={handleDeleteStatement}>{translate('BUTTON_REMOVE_STATEMENT')}</Button>
          <Button onClick={handleCancel}>{translate('BUTTON_CANCEL')}</Button>
          <Button
            primary
            onClick={handelSave}>
            {translate('BUTTON_SAVE')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
