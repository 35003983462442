import { createReducer } from '@reduxjs/toolkit';
import { baseEntityStoreReducer, baseListedInitialState } from '@shared/base-listed-entity-store';
import { tagsActions } from './actions';
import { TagsState } from './state';

const initialState: TagsState = {
  ...baseListedInitialState,
  filters: { perPage: 250 }
};

export const tagsReducer = createReducer(initialState, (builder) => baseEntityStoreReducer(initialState, tagsActions, builder));
