import { createReducer } from '@reduxjs/toolkit';
import { ProfileActions } from './actions';
import { ProfileState } from './state';

const initialState: ProfileState = {
  profile: null,
  isFetching: false,
  isUpdating: false,
  extensionLink: null
};

export const profileReducer = createReducer(initialState, (builder) => [
  builder
    .addCase(ProfileActions.fetchProfile, (state) => {
      state.isFetching = true;
    })
    .addCase(ProfileActions.fetchProfileSuccess, (state, { payload }) => {
      state.profile = payload;
      state.isFetching = false;
    })
    .addCase(ProfileActions.fetchProfileFailure, (state) => {
      state.isFetching = false;
    })
    .addCase(ProfileActions.setExtensionLink, (state, { payload }) => {
      state.extensionLink = payload.extensionLink;
    })
]);
