import { Exclude, Expose } from 'class-transformer';
import { UrlType } from '../types';

export class SettingUrl {
  @Expose()
  public type: UrlType;

  @Expose()
  public content: string;

  @Exclude()
  public key?: string;

  constructor(settingUrl?: Partial<SettingUrl>) {
    Object.assign(this, settingUrl);
  }
}
