import { Badge } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useTranslation } from '@shared/i18n';

interface JourneyCardBadgeProps {
  isActive: boolean;
}

export function JourneyCardBadge({ isActive }: JourneyCardBadgeProps): ReactElement {
  const translate = useTranslation('MAIN.SHARED.JOURNEY_CARD_BADGE');

  return <Badge status={isActive ? 'success' : 'new'}>{translate(isActive ? 'TEXT_ACTIVE' : 'TEXT_INACTIVE')}</Badge>;
}
