import { useState } from 'react';
import { lastValueFrom, throwError, takeUntil, finalize } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { useComponentLifecycle } from '@shared/use-component-lifecycle';
import { Media } from '../models';
import { mediaService } from '../service';

export const useUploadMedia = (): {
  createMedia: (file: File, onSuccess?: (mediaID: number) => void) => void;
  isUploading: boolean;
  media?: Media;
} => {
  const [media, setMedia] = useState<Media>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { destroyed$ } = useComponentLifecycle();

  const createMedia = (file: File, onSuccess?: (mediaID: number) => void): void => {
    const formData = new FormData();
    formData.append('file', file);
    setIsUploading(true);
    lastValueFrom(
      mediaService.create(formData).pipe(
        tap((response) => {
          setMedia(response);
          onSuccess?.(response.id);
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        takeUntil(destroyed$),
        finalize(() => {
          setIsUploading(false);
        })
      )
    );
  };

  return { createMedia, isUploading, media };
};
