import { Expose } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';

export class Product extends BaseEntity<number> {
  @Expose({ name: 'shopify_id' })
  public shopifyID: number;

  @Expose()
  public title: string;

  @Expose()
  public image?: string;

  @Expose({ name: 'product_type' })
  public productType?: string;

  constructor(product: Partial<Product>) {
    super(product);
    Object.assign(this, product);
  }
}
