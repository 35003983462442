import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from '@app/app';
import { initStore } from '@store/store';
import '@shopify/polaris/build/esm/styles.css';
import './styles/globals.scss';

const store = initStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider i18n={enTranslations}>
        <Provider store={store}>
          <App />
        </Provider>
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);
