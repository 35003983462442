import { Tooltip } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { ReactComponent as Info } from '@assets/icons/info.svg';
import './tooltip.scss';

export interface AppTooltipProps {
  content: React.ReactNode;
  children?: React.ReactNode;
  isTriggerShown?: boolean;
}

export function AppTooltip({ children, content, isTriggerShown = true }: AppTooltipProps): ReactElement {
  return (
    <div className='tooltip'>
      <Tooltip content={<div className='tooltip-container'>{content}</div>}>
        {isTriggerShown && (
          <div className='tooltip-icon'>
            <Info />
          </div>
        )}
      </Tooltip>
      <div className='tooltip-content'>{children}</div>
    </div>
  );
}
