import { Badge, Button, Card } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Checked } from '@assets/icons/checked.svg';
import { useTranslation } from '@shared/i18n';
import { currencyFormat } from '@shared/i18n/utils/currency-format';
import { PlanID } from '@shared/plan/models/enums';
import { planConfig } from '@shared/plan/plan-config';
import { isArchivedPlan } from '@shared/plan/utils/is-deprecated-plan';
import { ProfileSelectors } from '@shared/profile';
import { getBillingCardInfo, upgradePlan } from '../../utils';
import './billing-card.scss';

interface BillingCardProps {
  planID: PlanID;
}

export function BillingCard({ planID }: BillingCardProps): ReactElement {
  const translate = useTranslation('MAIN.BILLING.BILLING_CARD');

  const { title, options, price, recommendations, pricePerRecommendation, image } = getBillingCardInfo(planID);
  const profile = useSelector(ProfileSelectors.profile);
  const canDowngrade =
    !isArchivedPlan(profile?.plan?.id) && planConfig[profile?.plan?.id]?.grade > planConfig[planID]?.grade;
  const isCurrent = profile?.plan?.id === planID;

  const handleUpgradeClick = (): void => {
    upgradePlan(planID, profile.name);
  };

  return (
    <div className='billing-card'>
      <Card.Section>
        <div className='billing-card-header'>
          <div className='billing-card-title'>
            <p className='billing-card-title-text text-bold'>{title}</p>
            {isCurrent && <Badge status='success'>{translate('TEXT_CURRENT')}</Badge>}
          </div>
          <img
            className='billing-card-shopify-img'
            src={image}
          />
          <div className='billing-card-text-description'>
            <span className='text-bold'>{recommendations}</span> {translate('TEXT_RECOMMENDATIONS_PER_MONTH')}
            {!!pricePerRecommendation && (
              <p>{translate('TEXT_PER_PEC_OVER', { value: currencyFormat(pricePerRecommendation) })}</p>
            )}
          </div>
        </div>
      </Card.Section>
      <Card.Section>
        <div className='billing-card-options'>
          {options?.map((option) => (
            <div
              className='billing-card-option'
              key={`${title + option}`}>
              <div className='billing-card-checked-icon'>
                <Checked />
              </div>
              {option}
            </div>
          ))}
        </div>
      </Card.Section>
      {planID !== PlanID.FREE && (
        <div className='billing-card-footer'>
          <Card.Section subdued>
            {!!price && (
              <p className='billing-card-price'>
                <span className='text-bold'>${price}</span> {translate('TEXT_PER_MONTH')}
              </p>
            )}
            <Button
              disabled={isCurrent}
              onClick={handleUpgradeClick}
              primary={!canDowngrade}>
              {canDowngrade ? translate('BUTTON_DOWNGRADE') : translate('BUTTON_UPGRADE')}
            </Button>
          </Card.Section>
        </div>
      )}
    </div>
  );
}
