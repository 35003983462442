import { Card, Select } from '@shopify/polaris';
import React, { ReactElement, useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { FontPicker, FontPickerID } from '@shared/font-picker';
import { FormInput } from '@shared/form-input';
import { i18n, useTranslation } from '@shared/i18n';
import { TabPosition } from '@shared/journey';
import { UnitsInput } from '@shared/units-input';
import { UpsertJourneyForm } from '../../../../shared/forms';
import { CollapsedStylingCard } from '../collapsed-styling-card';
import { EngagementCard } from '../engagement-card';
import { EngagementFormBubble } from '../engagement-form-bubble';
import { ColorInput, StylingFormTextInput } from '../styling-card-form';
import { StylingFormLayout } from '../styling-form-layout';

interface EngagementFormProps {
  control: Control<UpsertJourneyForm>;
}

export function EngagementForm({ control }: EngagementFormProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.STYLING.ENGAGEMENT_FORM');

  const tabColorInputs: Array<ColorInput> = [
    { label: translate('TEXT_TAB_COLOR'), name: 'engagementTabColor' },
    { label: translate('TEXT_TAB_TEXT_COLOR'), name: 'engagementTabTextColor' }
  ];

  const positionOptions: Array<{ label: string; value: TabPosition }> = [
    { label: translate('TEXT_LEFT'), value: 'left' },
    { label: translate('TEXT_RIGHT'), value: 'right' }
  ];

  const tabTextInputs: Array<StylingFormTextInput> = [{ name: 'engagementTabText', label: translate('TAB_TEXT') }];

  const { field: tabPositionField } = useController({ control, name: 'engagementTabPosition' });
  const { field: tabFontField } = useController({ control, name: 'engagementTabFont' });
  const { field: tabBorderRadiusField } = useController({ control, name: 'engagementTabBorderRadius' });
  const { field: tabColorField } = useController({ control, name: 'engagementTabColor' });
  const { field: tabTextColorField } = useController({ control, name: 'engagementTabTextColor' });
  const { field: tabFontWeightField } = useController({ control, name: 'engagementTabFontWeight' });
  const { field: tabFontSizeField } = useController({ control, name: 'engagementTabFontSize' });
  const { field: tabTextField } = useController({ control, name: 'engagementTabText' });

  const { field: bubbleTextField } = useController({ control, name: 'engagementTabBubbleText' });
  const { field: bubbleFontField } = useController({ control, name: 'engagementTabBubbleFont' });
  const { field: bubbleFontSizeField } = useController({ control, name: 'engagementTabBubbleFontSize' });
  const { field: bubbleFontWeightField } = useController({ control, name: 'engagementTabBubbleFontWeight' });
  const { field: bubbleCancelTextField } = useController({ control, name: 'engagementTabBubbleCancelText' });
  const { field: bubbleConfirmTextField } = useController({ control, name: 'engagementTabBubbleConfirmText' });
  const { field: bubbleButtonTextColorField } = useController({ control, name: 'engagementTabBubbleButtonTextColor' });
  const { field: bubbleButtonBackgroundColorField } = useController({
    control,
    name: 'engagementTabBubbleButtonBackgroundColor'
  });
  const { field: bubbleBorderColorField } = useController({
    control,
    name: 'engagementTabBubbleBorderColor'
  });

  const handleTabPositionSelect = (): void => {
    tabPositionField.onChange(tabPositionField.value === 'left' ? 'right' : 'left');
  };

  const tabPositionFields = useMemo(() => {
    return (
      <div
        key='engagementTabBorderRadius'
        className='inline-form-group'>
        <div className='col-50'>
          <Select
            label={translate('TEXT_POSITION')}
            options={positionOptions}
            onChange={handleTabPositionSelect}
            value={tabPositionField.value}
          />
        </div>
        <UnitsInput units={i18n.t('COMMON.TEXT_PX')}>
          <FormInput<UpsertJourneyForm>
            min={0}
            autoComplete='off'
            type='number'
            name='engagementTabBorderRadius'
            control={control}
            label={translate('TEXT_BORDER_RADIUS')}
          />
        </UnitsInput>
      </div>
    );
  }, [tabPositionField.value]);

  const tabFontPickerInput = useMemo(() => {
    return (
      <FontPicker
        key={FontPickerID.ENGAGEMENT}
        onChange={tabFontField.onChange}
        activeFontFamily={tabFontField.value}
        pickerId={FontPickerID.ENGAGEMENT}
      />
    );
  }, [tabFontField.value]);

  return (
    <StylingFormLayout
      title={translate('TEXT_ENGAGEMENT_TAB')}
      subtitle={translate('TEXT_CHOOSE_HOW')}>
      <div className='styling-card'>
        <Card>
          <CollapsedStylingCard
            textInputs={tabTextInputs}
            fontSizePath='engagementTabFontSize'
            fontWeightPath='engagementTabFontWeight'
            extraFields={[tabPositionFields, tabFontPickerInput]}
            colorInputs={tabColorInputs}
            control={control}
            title={translate('TEXT_TAB')}
            subtitle={translate('TEXT_TAB_SUBTITLE')}
          />
          <CollapsedStylingCard
            title={translate('TEXT_BUBBLE')}
            control={control}
            colorInputs={[{ label: translate('TEXT_BUBBLE_BORDER_COLOR'), name: 'engagementTabBubbleBorderColor' }]}
            textInputs={[{ name: 'engagementTabBubbleText', label: translate('TEXT_BUBBLE_TEXT') }]}
          />
          <EngagementFormBubble
            control={control}
            bubbleFont={bubbleFontField.value}
            onChangeBubbleFont={bubbleFontField.onChange}
          />
        </Card>
      </div>
      <div className='styling-card'>
        <EngagementCard
          tabText={tabTextField.value}
          bubbleText={bubbleTextField.value}
          position={tabPositionField.value}
          cancelButtonText={bubbleCancelTextField.value}
          confirmButtonText={bubbleConfirmTextField.value}
          tabStyles={{
            borderRadius: tabBorderRadiusField.value,
            backgroundColor: tabColorField.value,
            fontSize: tabFontSizeField.value,
            color: tabTextColorField.value,
            fontWeight: tabFontWeightField.value
          }}
          bubbleStyles={{
            fontSize: bubbleFontSizeField.value,
            fontWeight: bubbleFontWeightField.value,
            borderColor: bubbleBorderColorField.value
          }}
          buttonsStyles={{
            color: bubbleButtonTextColorField.value,
            backgroundColor: bubbleButtonBackgroundColorField.value
          }}
        />
      </div>
    </StylingFormLayout>
  );
}
