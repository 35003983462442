import React, { ReactElement } from 'react';
import './form-warning.scss';

interface FormWarningProps {
  children: React.ReactNode;
}

export function FormWarning({ children }: FormWarningProps): ReactElement {
  return <span className='form-warning'>{children}</span>;
}
