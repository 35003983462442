import { AxiosRequestConfig } from 'axios';
import { AuthSelectors } from '@shared/auth/store/selectors';
import { storeRef } from '@store';

export const tokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (config.headers.Authorization) {
    return config;
  }

  const token = AuthSelectors.token(storeRef.getState());

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};
