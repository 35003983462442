import { Tabs } from '@shopify/polaris';
import { TabDescriptor } from '@shopify/polaris/build/ts/latest/src/components/Tabs/types';
import React, { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@shared/i18n';
import { useRouteLastChild } from '@shared/use-route-last-child';
import './page-tabs.scss';

interface JourneyPageHeaderProps {
  tabsWithErrors: Array<string>;
}

export function JourneyPageTabs({ tabsWithErrors }: JourneyPageHeaderProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.TABS');
  const navigate = useNavigate();

  const tabContent = useCallback(
    (label: string) => {
      const shouldShowWarningBadge = tabsWithErrors?.includes(label);

      return (
        <div className='journey-page-tabs-tab-label'>
          {label} {shouldShowWarningBadge && <div className='journey-page-tabs-warning-badge' />}
        </div>
      );
    },
    [tabsWithErrors]
  );

  const tabs: Array<TabDescriptor> = [
    {
      id: 'quiz',
      content: tabContent(translate('TEXT_QUIZ'))
    },
    {
      id: 'styling',
      content: tabContent(translate('TEXT_STYLING'))
    },
    {
      id: 'settings',
      content: tabContent(translate('TEXT_SETTINGS'))
    }
  ];

  const handleTabChange = (selectedTabIndex: number): void => {
    navigate(`./${tabs[selectedTabIndex].id}`);
  };

  const routeLastChild = useRouteLastChild();
  const selectedTab = tabs.findIndex((tab) => tab.id === routeLastChild);

  return (
    <div className='journey-page-tabs'>
      <Tabs
        tabs={tabs}
        selected={selectedTab || 0}
        onSelect={handleTabChange}
      />
    </div>
  );
}
