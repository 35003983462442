import { Button, ButtonGroup } from '@shopify/polaris';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import React, { ReactElement } from 'react';
import { useTranslation } from '@shared/i18n';
import { Condition } from '@shared/journey/models/condition';
import './condition-tools.scss';

interface ConditionToolsProps {
  conditionArray: Array<Condition>;
  onSetConditionArray: (conditionArray: Array<Condition>) => void;
  onSave: () => void;
  onCancel: () => void;
}

export function ConditionTools({
  conditionArray,
  onSetConditionArray,
  onCancel,
  onSave
}: ConditionToolsProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.QUESTION_CONDITION.CONDITION_TOOLS');

  const lastCondition = conditionArray?.[conditionArray?.length - 1];

  const isAnswerToDisabled =
    lastCondition?.type === 'answerTo' || lastCondition?.type === 'has' || lastCondition?.type === 'doesNotHave';
  const isOrAndDisabled =
    lastCondition?.type === 'and' ||
    lastCondition?.type === 'or' ||
    lastCondition?.type === 'answerTo' ||
    conditionArray?.length === 0 ||
    !conditionArray;
  const isHasDisabled =
    lastCondition?.type === 'has' ||
    lastCondition?.type === 'doesNotHave' ||
    conditionArray?.length === 0 ||
    !conditionArray;

  const handleAnswerToPress = (): void => {
    onSetConditionArray((conditionArray || [])?.concat({ type: 'answerTo', value: '', key: nanoid() }));
  };

  const handleHasPress = (): void => {
    onSetConditionArray(conditionArray?.concat({ type: 'has', value: '', key: nanoid() }));
  };

  const handleAndPress = (): void => {
    onSetConditionArray(conditionArray?.concat({ type: 'and', key: nanoid() }));
  };

  const handleOrPress = (): void => {
    onSetConditionArray(conditionArray?.concat({ type: 'or', key: nanoid() }));
  };

  const handleRemoveCondition = (): void => {
    onSetConditionArray(null);
  };

  return (
    <div className='condition-tools'>
      <div className='condition-tools-conditions'>
        <button
          disabled={isAnswerToDisabled}
          onClick={handleAnswerToPress}
          className={clsx('button-primary-plain', isAnswerToDisabled && 'condition-tools-disabled-condition')}>
          {translate('TEXT_ANSWER_TO')}
        </button>
        <button
          disabled={isOrAndDisabled}
          onClick={handleAndPress}
          className={clsx('button-primary-plain', isOrAndDisabled && 'condition-tools-disabled-condition')}>
          {translate('TEXT_AND')}
        </button>
        <button
          disabled={isOrAndDisabled}
          onClick={handleOrPress}
          className={clsx('button-primary-plain', isOrAndDisabled && 'condition-tools-disabled-condition')}>
          {translate('TEXT_OR')}
        </button>
        <button
          disabled={isHasDisabled}
          onClick={handleHasPress}
          className={clsx('button-primary-plain', isHasDisabled && 'condition-tools-disabled-condition')}>
          {translate('TEXT_HAS')}
        </button>
      </div>
      <div className='condition-tools-actions'>
        <ButtonGroup>
          <Button
            onClick={handleRemoveCondition}
            destructive
            plain>
            {translate('BUTTON_REMOVE_CONDITION')}
          </Button>
          <Button onClick={onCancel}>{translate('BUTTON_CANCEL')}</Button>
          <Button
            primary
            onClick={onSave}>
            {translate('BUTTON_SAVE')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
