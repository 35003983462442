import { Avatar, Button, ResourceItem, Text } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useTranslation } from '@shared/i18n';
import './resource-list-item.scss';

interface ProductItemProps {
  id: number | string;
  title: string;
  subtitle?: string;
  image?: string;
  onClick?: () => void;
  onRemoveClick?: (id: number | string) => void;
}

export function ResourceListItem({
  id,
  title,
  subtitle,
  image,
  onClick,
  onRemoveClick
}: ProductItemProps): ReactElement {
  const translation = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.PRODUCT_ITEM');

  const handleClick = (): void => {
    onClick?.();
  };

  const handleRemoveClick = (): void => {
    onRemoveClick(id);
  };

  return (
    <ResourceItem
      onClick={handleClick}
      verticalAlignment='center'
      media={
        image && (
          <Avatar
            shape='square'
            source={image}
          />
        )
      }
      id={String(id)}>
      <div className='product-item-card'>
        <div className='product-item-info'>
          <Text
            variant='bodyMd'
            fontWeight='bold'
            as='h3'>
            {title}
          </Text>
          {subtitle}
        </div>
        {!!onRemoveClick && (
          <Button
            onClick={handleRemoveClick}
            destructive
            plain>
            {translation('BUTTON_REMOVE')}
          </Button>
        )}
      </div>
    </ResourceItem>
  );
}
