import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ProfileSelectors } from '@shared/profile';
import { usetifulConfig } from '../config';

export const useUsetiful = (): void => {
  const isExecuted = useRef(false);
  const profile = useSelector(ProfileSelectors.profile);

  useEffect(() => {
    if (!isExecuted.current && !!profile?.name) {
      const script1 = document.createElement('script');
      script1.innerHTML = `window.usetifulTags = { userId: "${profile.name}" };`;

      const script2 = document.createElement('script');
      script2.innerHTML = usetifulConfig.script;

      document.body.appendChild(script1);
      document.body.appendChild(script2);
      isExecuted.current = true;
    }
  }, [profile?.name]);
};
