import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { subscriptionConfig } from '@shared/billing';
import { useTranslation } from '@shared/i18n';
import { currencyFormat } from '@shared/i18n/utils/currency-format';
import { isArchivedPlan } from '@shared/plan/utils/is-deprecated-plan';
import { ProfileSelectors } from '@shared/profile';
import { AppTooltip } from '@shared/tooltip/tooltip';
import './billing-subscription.scss';

export function BillingSubscription(): ReactElement {
  const translate = useTranslation('MAIN.BILLING.BILLING_SUBSCRIPTION');
  const profile = useSelector(ProfileSelectors.profile);
  const isProfileFetching = useSelector(ProfileSelectors.isFetching);

  if (isProfileFetching) {
    return null;
  }

  return (
    <div className='billing-subscription'>
      <p>
        {translate('TEXT_YUO_ARE_SUBSCRIBED')}{' '}
        <span className='text-bold'>{`${profile?.plan?.name} ${translate('TEXT_PLAN')}`}</span>
      </p>
      {isArchivedPlan(profile?.plan?.id) && (
        <div className='billing-subscription-archived'>
          <span className='text-bold'>{translate('TEXT_ARCHIVED')}</span>
          <AppTooltip
            content={
              <div className='billing-subscription-tooltip'>
                <span>
                  {translate('TEXT_YUO_ARE_SUBSCRIBED_ARCHIVED')}
                  <br />
                  <span className='text-bold'>
                    {profile?.plan?.name} {translate('TEXT_PLAN')}{' '}
                    {translate('TEXT_PER_MONTH', {
                      price: currencyFormat(subscriptionConfig[profile?.plan?.id]?.price)
                    })}
                  </span>
                </span>
                <p>{translate('TEXT_ARCHIVED_PLAN_INFO')}</p>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
}
