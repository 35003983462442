import { Button, Card, Modal, Tag, Text } from '@shopify/polaris';
import React, { Fragment, FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { ReactComponent as Close } from '@assets/icons/close.svg';
import { useTranslation } from '@shared/i18n';
import { Question } from '@shared/journey';
import { Condition } from '@shared/journey/models/condition';
import { AnswerSelect } from './components/answer-select';
import { ConditionString } from './components/condition-string';
import { ConditionTools } from './components/condition-tools';
import { QuestionSelect } from './components/question-select';
import './condition-form.scss';

interface ConditionFormProps {
  questions: Array<Question>;
  onChange: (value: Array<Condition>) => void;
  renderTrigger?: FunctionComponent<{ onClick: () => void }>;
  fieldValue?: Array<Condition>;
}

export function ConditionForm({ questions, fieldValue, onChange, renderTrigger }: ConditionFormProps): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conditionArray, setConditionArray] = useState<Array<Condition>>(fieldValue || null);
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.QUESTION_CONDITION');
  const filteredQuestions = questions?.filter((question) => question.type !== 'text' && !!question.answers?.length);

  const getRelatedQuestion = useCallback(
    (index: number) => {
      const newArray = [...conditionArray];

      return newArray
        .slice(0, index)
        .reverse()
        .find((condition) => condition.type === 'answerTo');
    },
    [conditionArray]
  );

  const handleToggleModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSelect = (index: number, value: string): void => {
    const newCondition = [...conditionArray];
    newCondition[index] = { ...newCondition[index], value };
    setConditionArray(newCondition);
  };

  const toggleHasCondition = (index: number): void => {
    const newCondition = [...conditionArray];
    newCondition[index] = { ...newCondition[index], type: newCondition[index].type === 'has' ? 'doesNotHave' : 'has' };
    setConditionArray(newCondition);
  };
  const handleDeleteCondition = (): void => {
    if (conditionArray.length === 1) {
      setConditionArray(null);
    } else {
      setConditionArray(conditionArray.slice(0, -1));
    }
  };

  const handleCancel = (): void => {
    setConditionArray(fieldValue);
  };

  const handleSave = (): void => {
    onChange(conditionArray);
    handleToggleModal();
  };

  useEffect(() => {
    if (!isModalOpen) {
      setConditionArray(fieldValue || []);
    }
  }, [isModalOpen, setConditionArray]);

  if (filteredQuestions.length < 1 && !fieldValue) {
    return null;
  }

  return (
    <Fragment>
      {fieldValue?.length > 0 ? (
        <div className='question-condition-form-edit-condition'>
          <div className='question-condition-form-condition-string'>
            <ConditionString
              questions={questions}
              conditionArray={fieldValue}
            />
          </div>
          <div className='question-condition-form-edit-button'>
            <Button onClick={handleToggleModal}>{translate('BUTTON_EDIT')}</Button>
          </div>
        </div>
      ) : (
        renderTrigger({ onClick: handleToggleModal })
      )}
      <Modal
        title={translate('TEXT_CONDITION')}
        open={isModalOpen}
        onClose={handleToggleModal}>
        <Card.Section>
          <div className='question-condition-form-constructor-field'>
            {(!conditionArray || conditionArray?.length === 0) && (
              <div className='question-condition-form-empty-condition'>
                <Text
                  as='p'
                  variant='headingMd'>
                  {translate('TEXT_EMPTY_CONDITION')}
                </Text>
              </div>
            )}
            {conditionArray?.map((item, index) => {
              switch (item.type) {
                case 'answerTo':
                  return (
                    <QuestionSelect
                      key={item.key}
                      questions={filteredQuestions}
                      onQuestionSelect={handleSelect}
                      isFirst={index === 0}
                      questionValue={item.value}
                      index={index}
                    />
                  );
                case 'doesNotHave':
                case 'has':
                  return (
                    <AnswerSelect
                      key={item.key}
                      index={index}
                      type={item.type}
                      onToggleHasCondition={toggleHasCondition}
                      value={item.value}
                      answers={
                        filteredQuestions?.find(
                          (question) => question?.idWithinJourney === getRelatedQuestion(index)?.value
                        )?.answers
                      }
                      onAnswerSelect={handleSelect}
                    />
                  );
                case 'or':
                  return <Tag key={item.key}>{translate('TEXT_OR')}</Tag>;
                case 'and':
                  return <Tag key={item.key}>{translate('TEXT_AND')}</Tag>;
                default:
                  return null;
              }
            })}
            {conditionArray?.length > 0 && (
              <button onClick={handleDeleteCondition}>
                <Close />
              </button>
            )}
          </div>
          <ConditionTools
            onCancel={handleCancel}
            onSave={handleSave}
            conditionArray={conditionArray}
            onSetConditionArray={setConditionArray}
          />
        </Card.Section>
      </Modal>
    </Fragment>
  );
}
