import { Expose } from 'class-transformer';
import { BaseEntity } from '@shared/base-entity/models';
import { PlanID } from './enums';

export class Plan extends BaseEntity<PlanID> {
  @Expose()
  public name: string;

  @Expose()
  public price: string;

  constructor(plan: Partial<Plan>) {
    super(plan);
    Object.assign(this, plan);
  }
}
