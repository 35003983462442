import { Autocomplete } from '@shopify/polaris';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { journeySurveyFacade } from '@app/main/journey/survey/facade';
import { WeightExpressionAttribute } from '@shared/journey/types/weight-attribute';
import { productTypeService } from '@shared/product-type';
import { filterRegExp } from '@shared/regexp';
import { tagsFacade } from '@shared/tags/facade';
import { tagsActions } from '@shared/tags/store';
import { useSearchData } from '@shared/use-search-data';
import { journeySurveyActions } from '../../../../store';

interface AttributesSearchProps {
  weightExpressionTitle: string;
  weightExpressionAttribute: WeightExpressionAttribute;
  onWeightExpressionTitleChange: (value: string) => void;
}

export function AttributesSearch({
  weightExpressionTitle,
  weightExpressionAttribute,
  onWeightExpressionTitleChange
}: AttributesSearchProps): ReactElement {
  const [searchQuery, setSearchQuery] = useState<string>(weightExpressionTitle);
  const [isFocused, setIsFocused] = useState(false);

  const isProductsSearch = weightExpressionAttribute === 'product_title';
  const isTagsSearch = weightExpressionAttribute === 'product_tag';
  const isProductTypesSearch = weightExpressionAttribute === 'product_type';

  const dispatch = useDispatch();

  const {
    search: searchProductTypes,
    isLoading: isProductTypesLoading,
    data: productTypes = []
  } = useSearchData<Array<string>>(productTypeService);

  const handleSearchProductTypes = (): void => {
    searchProductTypes();
  };

  const {
    items: products,
    productsPagination,
    isLoading: isProductsLoading,
    filter: productsFilter
  } = journeySurveyFacade;

  const {
    items: tags,
    pagination: tagsPagination,
    hasNextPage: tagsHasNextPage,
    filter: tagsFilter,
    isLoading: isTagsLoading
  } = tagsFacade;

  const selectOptions = useMemo(() => {
    switch (weightExpressionAttribute) {
      case 'product_tag':
        return tags?.map((tag) => tag.title);
      case 'product_title':
        return products?.map((product) => product.title);
      case 'product_type':
        return productTypes;
      default:
        return [];
    }
  }, [weightExpressionAttribute, tags, products, productTypes]);

  const deselectedOptions = selectOptions
    ?.filter((attribute) => (searchQuery ? attribute.match(filterRegExp(searchQuery)) : true))
    .map((attribute) => ({ value: attribute, label: attribute }));

  const handleChangeQuery = (query: string): void => {
    setSearchQuery(query);
    if (isProductTypesSearch && weightExpressionTitle !== null) {
      onWeightExpressionTitleChange(null);

      return;
    }

    onWeightExpressionTitleChange(query);
  };
  const handleSelectAttribute = (selectedTagIDs: Array<string>): void => {
    setSearchQuery(selectedTagIDs?.[0]);
    onWeightExpressionTitleChange(selectedTagIDs?.[0]);
  };

  const handleFocus = (): void => {
    setSearchQuery(weightExpressionTitle);
    setIsFocused(true);
  };

  const handleBlur = (): void => {
    setIsFocused(false);
  };

  const onLoadMore = (): void => {
    if (isProductsSearch && !isProductsLoading && productsPagination.hasNextPage) {
      journeySurveyFacade.loadProducts({ afterCursor: productsPagination.endCursor });
    } else if (tagsHasNextPage && isTagsSearch) {
      tagsFacade.loadItems(tagsPagination.currentPage + 1);
    }
  };

  useEffect(() => {
    if (isFocused) {
      if (isTagsSearch && !tags.length) {
        tagsFacade.loadItems();
      } else if (isProductsSearch && !products.length) {
        journeySurveyFacade.loadProducts();
      } else if (isProductTypesSearch && !productTypes.length) {
        handleSearchProductTypes();
      }
    }
  }, [isFocused]);

  useEffect(() => {
    if (isProductsSearch && !!productsFilter?.title) {
      dispatch(journeySurveyActions.resetFilter());
    } else if (isTagsSearch && !!tagsFilter?.query) {
      dispatch(tagsActions.resetFilter());
    }
  }, [weightExpressionAttribute]);

  useEffect(() => {
    setSearchQuery(weightExpressionTitle);
  }, [weightExpressionTitle]);

  useEffect(() => {
    if (isProductsSearch) {
      journeySurveyFacade.changeProductsSearchQuery(searchQuery);
    } else if (isTagsSearch) {
      tagsFacade.changeSearchQuery(searchQuery);
    }
  }, [searchQuery]);

  const textField = (
    <Autocomplete.TextField
      onFocus={handleFocus}
      onBlur={handleBlur}
      labelHidden
      autoComplete='off'
      onChange={handleChangeQuery}
      label=''
      value={searchQuery}
    />
  );

  return (
    <Autocomplete
      loading={isProductTypesLoading || isProductsLoading || isTagsLoading}
      willLoadMoreResults={true}
      onLoadMoreResults={onLoadMore}
      options={weightExpressionAttribute === 'product_price' ? [] : deselectedOptions}
      selected={[weightExpressionTitle]}
      onSelect={handleSelectAttribute}
      textField={textField}
    />
  );
}
