import { FontWeight } from './enums';

export const journeyConfig = {
  defaultValues: {
    fontWeight: FontWeight.REGULAR,
    recommendationsCount: 3
  },
  recommendationsCountMin: 1,
  recommendationsCountMax: 6
};
