import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { finalize, lastValueFrom, takeUntil } from 'rxjs';
import { EntityRequest } from '@shared/base-entity/models';
import { EntityService } from '@shared/base-entity/service';
import { entityStoreSelectors } from '@shared/base-entity/store';
import { useComponentLifecycle } from '@shared/use-component-lifecycle';
import { Entity } from '../config';

export function useEntitySelector<
  TEntity extends Entity,
  TEntityRequest = EntityRequest,
  TEntityService extends EntityService = EntityService
>(props: {
  id: number;
  entityService: TEntityService;
  params?: TEntityRequest;
  onError?: (error: AxiosError) => void;
}): typeof result {
  const { id, entityService, params, onError } = props;
  const item: TEntity = useSelector(entityStoreSelectors[entityService.entityName].item(id) as any);
  const [isLoading, setIsLoading] = useState(!!id);
  const { destroyed$ } = useComponentLifecycle();

  const loadItem = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    lastValueFrom(
      entityService.get(id, params).pipe(
        finalize(() => {
          setIsLoading(false);
        }),
        takeUntil(destroyed$)
      ),
      { defaultValue: null }
    ).catch((error: AxiosError) => {
      onError?.(error);
    });
  }, [entityService]);

  useEffect(() => {
    if (!isNaN(Number(id))) {
      loadItem();
    }
  }, []);

  const result = {
    isLoading: isLoading,
    isRefreshing: isLoading,
    item,
    loadItem
  };

  return result;
}
