import { Button } from '@shopify/polaris';
import React, { Fragment, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { ExtensionModal } from '@app/main/shared/extension-modal/extension-modal';
import { useTranslation } from '@shared/i18n';
import { ProfileSelectors } from '@shared/profile';

interface JourneyActivateButtonProps {
  isActive: boolean;
  onClick: () => void;
  isLoading?: boolean;
}

export function JourneyActivateButton({ isActive, onClick, isLoading }: JourneyActivateButtonProps): ReactElement {
  const translate = useTranslation('MAIN.SHARED.JOURNEY_ACTIVATE_BUTTON');
  const [isExtensionModalVisible, setIsExtensionModalVisible] = useState(false);
  const extensionLink = useSelector(ProfileSelectors.extensionLink);

  const handleCloseExtensionModal = (): void => {
    setIsExtensionModalVisible(false);
  };

  const handleActiveJourney = (): void => {
    if (!!extensionLink && !isActive) {
      setIsExtensionModalVisible(true);

      return;
    }
    onClick();
  };

  return (
    <Fragment>
      <Button
        loading={isLoading}
        primary={isActive}
        onClick={handleActiveJourney}>
        {translate(isActive ? 'BUTTON_DEACTIVATE' : 'BUTTON_ACTIVATE')}
      </Button>
      <ExtensionModal
        onCloseModal={handleCloseExtensionModal}
        isVisible={isExtensionModalVisible}
      />
    </Fragment>
  );
}
