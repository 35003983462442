import starterImage from '@assets/images/shopify-logo-starter.png';
import shopifyPlusLogo from '@assets/images/shopify-plus-logo.png';
import { subscriptionConfig } from '@shared/billing/config';
import { i18n } from '@shared/i18n';
import { PlanID } from '@shared/plan/models/enums';

interface CardInfo {
  title: string;
  recommendations: number;
  image: string;
  options: Array<string>;
  pricePerRecommendation?: number;
  price?: number;
}

export const getBillingCardInfo = (planID: PlanID): CardInfo => {
  const translatePath = 'MAIN.BILLING.BILLING_CARD';

  switch (planID) {
    case PlanID.FREE:
      return {
        title: i18n.t(translatePath + '.TEXT_FREE'),
        recommendations: subscriptionConfig[planID].recommendations,
        image: starterImage,
        options: [
          i18n.t(translatePath + '.TEXT_AI_PERSONALIZED_RECS'),
          i18n.t(translatePath + '.TEXT_EMAIL_SUPPORT'),
          i18n.t(translatePath + '.TEXT_BASIC_ANALYTICS'),
          i18n.t(translatePath + '.TEXT_SINGLE_QUESTION_QUIZ')
        ]
      };
    case PlanID.STARTER:
      return {
        title: i18n.t(translatePath + '.TEXT_STARTER'),
        recommendations: subscriptionConfig[planID].recommendations,
        pricePerRecommendation: subscriptionConfig[planID].pricePerRecommendation,
        price: subscriptionConfig[planID].price,
        image: starterImage,
        options: [
          i18n.t(translatePath + '.TEXT_AI_PERSONALIZED_RECS'),
          i18n.t(translatePath + '.TEXT_EMAIL_SUPPORT'),
          i18n.t(translatePath + '.TEXT_ANALYTICS_DASHBOARD'),
          i18n.t(translatePath + '.TEXT_DYNAMIC_EDITOR'),
          i18n.t(translatePath + '.TEXT_UNLIMITED_PRODUCTS_AND_BRANCHES')
        ]
      };
    case PlanID.GROWTH:
      return {
        title: i18n.t(translatePath + '.TEXT_GROWTH'),
        recommendations: subscriptionConfig[planID].recommendations,
        pricePerRecommendation: subscriptionConfig[planID].pricePerRecommendation,
        price: subscriptionConfig[planID].price,
        image: starterImage,
        options: [
          i18n.t(translatePath + '.TEXT_AI_PERSONALIZED_RECS'),
          i18n.t(translatePath + '.TEXT_EMAIL_LIVE_SUPPORT'),
          i18n.t(translatePath + '.TEXT_ADVANCED_ANALYTICS_DASHBOARD'),
          i18n.t(translatePath + '.TEXT_DYNAMIC_EDITOR'),
          i18n.t(translatePath + '.TEXT_UNLIMITED_PRODUCTS_AND_BRANCHES')
        ]
      };
    case PlanID.SHOPIFY_PLUS:
      return {
        title: i18n.t(translatePath + '.TEXT_SHOPIFY_PLUS'),
        recommendations: subscriptionConfig[planID].recommendations,
        pricePerRecommendation: subscriptionConfig[planID].pricePerRecommendation,
        price: subscriptionConfig[planID].price,
        image: shopifyPlusLogo,
        options: [
          i18n.t(translatePath + '.TEXT_AI_PERSONALIZED_RECS'),
          i18n.t(translatePath + '.TEXT_DEDICATED_MANAGER'),
          i18n.t(translatePath + '.TEXT_ADVANCED_ANALYTICS_DASHBOARD'),
          i18n.t(translatePath + '.TEXT_CUSTOM_ANALYTICS'),
          i18n.t(translatePath + '.TEXT_DYNAMIC_EDITOR'),
          i18n.t(translatePath + '.TEXT_UNLIMITED_PRODUCTS_AND_BRANCHES'),
          i18n.t(translatePath + '.TEXT_FULL_ONBOARDING')
        ]
      };
    default:
      break;
  }
};
