import { isAnyOf } from '@reduxjs/toolkit';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, filter, tap } from 'rxjs/operators';
import { overviewPageActions } from '@app/main/overview/shared/store';
import { Journey } from '@shared/journey';
import { journeyService } from '@shared/journey/service';
import { AppActions, Epics } from '@store';
import { JourneyPageActions } from './actions';

export const journeyPageEpics: Epics = {
  createJourney: (action$) => action$.pipe(
    filter(JourneyPageActions.createJourney.match),
    exhaustMap((action) => journeyService.create(new Journey(action.payload.journey)).pipe(
      map((response) => JourneyPageActions.createJourneySuccess({ onSuccess: action.payload.onSuccess, id: response.id })),
      catchError((error) => of(JourneyPageActions.createJourneyFailure(error)))
    ))
  ),

  createJourneySuccess: (action$) => action$.pipe(
    filter(JourneyPageActions.createJourneySuccess.match),
    tap(({ payload }) => payload.onSuccess(payload.id)),
    map(() => overviewPageActions.refreshItems({ page: 1 }))
  ),

  updateJourney: (action$) => action$.pipe(
    filter(JourneyPageActions.updateJourney.match),
    exhaustMap((action) => journeyService.update(new Journey(action.payload)).pipe(
      map(() => JourneyPageActions.updateJourneySuccess()),
      catchError((error) => of(JourneyPageActions.updateJourneyFailure(error)))
    ))
  ),

  updateJourneySuccess: (action$) => action$.pipe(
    filter(JourneyPageActions.updateJourneySuccess.match),
    map(() => overviewPageActions.refreshItems({ page: 1 }))
  ),

  handleFailure: (action$) => action$.pipe(
    filter(isAnyOf(JourneyPageActions.updateJourneyFailure, JourneyPageActions.createJourneyFailure)),
    map(() => AppActions.noop()) //TODO add error handling
  )
};
