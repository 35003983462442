import { AxiosError } from 'axios';
import { Entity } from '@shared/base-entity/config';
import { EntityPartial } from '@shared/base-entity/types';
import { PaginationResponse } from '@shared/pagination';
import { defineAction } from '@store/utils';
import { BaseListedEntityState } from './state';

export abstract class BaseListedEntityActions<
  TState extends BaseListedEntityState = BaseListedEntityState,
  TEntity extends Entity = Entity
> {
  public resetState = defineAction(
    `[${this.storeTag}] Reset state`
  );

  public refreshItems = defineAction<{ page?: number } & TState['filters']>(
    `[${this.storeTag}] Refresh items`
  );

  public loadItems = defineAction<{ page?: number } & TState['filters']>(
    `[${this.storeTag}] Load items`
  );

  public loadItemsSuccess = defineAction<PaginationResponse<TEntity>>(
    `[${this.storeTag}] Load items success`
  );

  public loadItemsFailure = defineAction<AxiosError<{ error?: string }>>(
    `[${this.storeTag}] Load items failure`
  );

  public changeFilter = defineAction<TState['filters']>(
    `[${this.storeTag}] Change filter`
  );

  public resetFilter = defineAction(
    `[${this.storeTag}] Reset filter`
  );

  public changeSearchQuery = defineAction<{ query: string }>(
    `[${this.storeTag}] Change search query`
  );

  public deleteItem = defineAction<{ id: number; onSuccess?: (
  ) => void }>(`[${this.storeTag}] Delete item`
    );

  public deleteItemSuccess = defineAction<{ item: { id: TEntity['id'] } & Partial<TEntity>; onSuccess?: (
  ) => void }>(
    `[${this.storeTag}] Delete item success`
    );

  public deleteItemFailure = defineAction<{ item: TEntity; error: AxiosError<{ error?: string }> }>(
    `[${this.storeTag}] Delete item failure`
  );

  public updateItem = defineAction<{ item: EntityPartial<TEntity> }>(
    `[${this.storeTag}] Update item`
  );

  public updateItemSuccess = defineAction<{ item: EntityPartial<TEntity> }>(
    `[${this.storeTag}] Update item success`
  );

  public updateItemFailure = defineAction<{ item: EntityPartial<TEntity>; error: AxiosError<{ error?: string }> }>(
    `[${this.storeTag}] Update item failure`
  );

  constructor(
public readonly storeTag: string
  ) {}
}
