import { Button, Page } from '@shopify/polaris';
import clsx from 'clsx';
import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnalyticsChartsGroup } from '@shared/analytics/components/analytics-charts-group';
import { AuthButton } from '@shared/auth';
import { CurrentPlan } from '@shared/current-plan';
import { CustomButton } from '@shared/custom-button';
import { useTranslation } from '@shared/i18n';
import { AppSpinner } from '@shared/spinner';
import { overviewPageFacade } from './facade';
import { ExplainerVideo } from './shared/components/explainer-video';
import { InfoSection } from './shared/components/info-section';
import { QuizGenerator } from './shared/components/quiz-generator/quiz-generator';
import { UpgradePlan } from './shared/components/upgrade-plan';
import { WelcomeCard } from './shared/components/welcome-card';
import { YourJourneys } from './shared/components/your-journeys';
import './overview.scss';

export function OverviewPage(): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW');

  const { items, isLoading } = overviewPageFacade;
  const hasActiveQuiz = useMemo(() => !!items?.find((quiz) => quiz.isActive), [items]);

  const navigate = useNavigate();

  const handleCreateQuizClick = (): void => {
    navigate('/journey/new/quiz');
  };

  const handleManagePlansClick = (): void => {
    navigate('./billing');
  };

  if (isLoading) {
    return (
      <div className='overview-spinner'>
        <AppSpinner />
      </div>
    );
  }

  return (
    <Page
      title={translate(hasActiveQuiz ? 'TEXT_TITLE' : 'TEXT_GET_STARTED')}
      secondaryActions={
        <Button
          primary
          onClick={handleCreateQuizClick}>
          {translate('BUTTON_CREATE_QUIZ')}
        </Button>
      }
      primaryAction={
        hasActiveQuiz ? (
          <div className='overview-header-actions'>
            <p>
              {translate('TEXT_YOUR_PLAN')} <CurrentPlan />
            </p>
            <CustomButton
              onClick={handleManagePlansClick}
              textClassName='overview-plans-button'
              text={translate('BUTTON_MANAGE_PLANS')}
            />
          </div>
        ) : (
          <div className={clsx('text-x14', 'overview-link-button', 'text-bold')}>
            <a
              className='overview-link'
              href='#analytics'>
              {translate('BUTTON_VIEW_DASHBOARD')}
            </a>
          </div>
        )
      }>
      {process.env.REACT_APP_ENV === 'local' && <AuthButton />}
      <div className='overview page-layout'>
        <WelcomeCard />
        {!hasActiveQuiz && <ExplainerVideo />}
        <UpgradePlan />
        <YourJourneys isJourneysLoading={isLoading} />
        <AnalyticsChartsGroup />
        {hasActiveQuiz && <InfoSection />}
      </div>
      <QuizGenerator />
    </Page>
  );
}
