import React, { ReactElement } from 'react';
import { CardTitle } from '@shared/card-title';
import './card-header.scss';

export interface CardHeaderProps {
  title: string;
  iconContent?: React.ReactNode;
  actionsContent?: React.ReactNode;
}

export function CardHeader({ title, iconContent, actionsContent }: CardHeaderProps): ReactElement {
  return (
    <div className='card-header'>
      <div className='card-header-actions'>
        {!!iconContent && <div className='card-header-icon'>{iconContent}</div>}
        <CardTitle>{title}</CardTitle>
      </div>
      <div className='card-header-actions'>{actionsContent}</div>
    </div>
  );
}
