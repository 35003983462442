import { Observable } from 'rxjs';
import { apiService } from '@shared/api';
import { Page } from './models';

class PageService {
  public search(): Observable<Array<Page>> {
    return apiService.get<Array<Page>>('/plugin/facade/pages');
  }
}

export const pageService = new PageService();
