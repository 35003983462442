import { Card } from '@shopify/polaris';
import { nanoid } from 'nanoid';
import React, { ReactElement } from 'react';
import { Control, useController } from 'react-hook-form';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { CustomCard } from '@shared/custom-card';
import { FontPickerID } from '@shared/font-picker';
import { useTranslation } from '@shared/i18n';
import { Answer } from '@shared/journey';
import { CollapsedStylingCard } from '../collapsed-styling-card';
import { ColorInput, StylingFormTextInput } from '../styling-card-form';
import { StylingFormLayout } from '../styling-form-layout';

interface SurveyFormProps {
  control: Control<UpsertJourneyForm>;
}

export function SurveyStylesForm({ control }: SurveyFormProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.STYLING.SURVEY_FORM');

  const titleColorInputs: Array<ColorInput> = [{ label: translate('TEXT_TITLE_COLOR'), name: 'surveyTitleColor' }];
  const bodyColorInputs: Array<ColorInput> = [
    { label: translate('TEXT_BACKGROUND_COLOR'), name: 'surveyBodyBackgroundColor' },
    { label: translate('TEXT_COLOR'), name: 'surveyBodyTextColor' }
  ];
  const answersColorInputs: Array<ColorInput> = [
    { label: translate('TEXT_ANSWER_BACKGROUND'), name: 'answerBackgroundColor' },
    { label: translate('TEXT_ANSWER_TEXT_COLOR'), name: 'answerTextColor' },
    { label: translate('TEXT_BORDER_COLOR'), name: 'answerBorderColor' }
  ];

  const buttonsColorInputs: Array<ColorInput> = [
    { label: translate('TEXT_BUTTON_BACKGROUND'), name: 'buttonBackgroundColor' },
    { label: translate('TEXT_BUTTON_HOVER'), name: 'buttonBackgroundHoverColor' },
    { label: translate('TEXT_BUTTON_COLOR'), name: 'buttonTextColor' }
  ];

  const answers = [
    { text: translate('TEXT_YES'), idWithinJourney: nanoid() },
    { text: translate('TEXT_NO'), idWithinJourneyrf: nanoid() }
  ] as Array<Answer>;

  const buttonsTextInputs: Array<StylingFormTextInput> = [
    { name: 'nextButtonText', label: translate('TEXT_NEXT_BUTTON') },
    { name: 'previousButtonText', label: translate('TEXT_PREVIOUS_BUTTON') },
    { name: 'restartButtonText', label: translate('TEXT_RESTART_BUTTON') }
  ];

  const { field: titleColorField } = useController({ control, name: 'surveyTitleColor' });
  const { field: titleFontSizeField } = useController({ control, name: 'surveyTitleFontSize' });
  const { field: titleFontWeightField } = useController({ control, name: 'surveyTitleFontWeight' });

  const { field: surveyBodyBackgroundColorField } = useController({ control, name: 'surveyBodyBackgroundColor' });
  const { field: surveyBodyTextColorField } = useController({ control, name: 'surveyBodyTextColor' });
  const { field: surveyBodyFontWeightField } = useController({ control, name: 'surveyBodyFontWeight' });
  const { field: surveyBodyFontSizeField } = useController({ control, name: 'surveyBodyFontSize' });

  const { field: answerBackgroundColorField } = useController({ control, name: 'answerBackgroundColor' });
  const { field: answerTextColorField } = useController({ control, name: 'answerTextColor' });
  const { field: answerBorderColorField } = useController({ control, name: 'answerBorderColor' });
  const { field: answerBorderRadiusField } = useController({ control, name: 'answerBorderRadius' });
  const { field: answerFontSizeField } = useController({ control, name: 'answerFontSize' });
  const { field: answerFontWeightField } = useController({ control, name: 'answerFontWeight' });

  const { field: buttonBackgroundColorField } = useController({ control, name: 'buttonBackgroundColor' });
  const { field: buttonBackgroundHoverColorField } = useController({ control, name: 'buttonBackgroundHoverColor' });
  const { field: buttonTextColorField } = useController({ control, name: 'buttonTextColor' });
  const { field: buttonFontSizeField } = useController({ control, name: 'buttonFontSize' });
  const { field: buttonFontWeightField } = useController({ control, name: 'buttonFontWeight' });
  const { field: buttonBorderRadiusField } = useController({ control, name: 'buttonBorderRadius' });
  const { field: nextButtonTextField } = useController({ control, name: 'nextButtonText' });
  const { field: previousButtonTextField } = useController({ control, name: 'previousButtonText' });

  return (
    <StylingFormLayout
      title={translate('TEXT_QUIZ')}
      subtitle={translate('TEXT_FINE_TUNE')}>
      <div className='styling-card'>
        <Card>
          <CollapsedStylingCard
            fontWeightPath='surveyTitleFontWeight'
            fontSizePath='surveyTitleFontSize'
            colorInputs={titleColorInputs}
            control={control}
            title={translate('TEXT_TITLE_STYLES')}
          />
          <CollapsedStylingCard
            fontWeightPath='surveyBodyFontWeight'
            fontSizePath='surveyBodyFontSize'
            colorInputs={bodyColorInputs}
            control={control}
            title={translate('TEXT_BODY_STYLES')}
          />
          <CollapsedStylingCard
            fontWeightPath='answerFontWeight'
            fontSizePath='answerFontSize'
            colorInputs={answersColorInputs}
            borderRadiusPath='answerBorderRadius'
            control={control}
            title={translate('TEXT_ANSWER_STYLES')}
          />
          <CollapsedStylingCard
            textInputs={buttonsTextInputs}
            fontWeightPath='buttonFontWeight'
            fontSizePath='buttonFontSize'
            colorInputs={buttonsColorInputs}
            borderRadiusPath='buttonBorderRadius'
            control={control}
            title={translate('TEXT_BUTTON_STYLES')}
          />
        </Card>
      </div>
      <div className='styling-card'>
        <CustomCard
          answers={answers}
          titleStyle={{
            color: titleColorField.value,
            fontSize: titleFontSizeField.value,
            fontWeight: titleFontWeightField.value
          }}
          bodyStyles={{
            backgroundColor: surveyBodyBackgroundColorField.value,
            color: surveyBodyTextColorField.value,
            fontWeight: surveyBodyFontWeightField.value,
            fontSize: surveyBodyFontSizeField.value
          }}
          answerStyles={{
            backgroundColor: answerBackgroundColorField.value,
            color: answerTextColorField.value,
            borderColor: answerBorderColorField.value,
            borderRadius: answerBorderRadiusField.value,
            fontSize: answerFontSizeField.value,
            fontWeight: answerFontWeightField.value
          }}
          buttonStyles={{
            backgroundColor: buttonBackgroundColorField.value,
            backgroundColorHover: buttonBackgroundHoverColorField.value,
            color: buttonTextColorField.value,
            fontSize: buttonFontSizeField.value,
            fontWeight: buttonFontWeightField.value,
            borderRadius: buttonBorderRadiusField.value,
            nextButtonText: nextButtonTextField.value,
            previousButtonText: previousButtonTextField.value
          }}
          title={translate('TEXT_DO_YOU')}
          subtitle={translate('TEXT_PRODUCTS')}
          titleClassName={`apply-font-${FontPickerID.TITLES}`}
          textClassName={`apply-font-${FontPickerID.TEXT}`}
          buttonClassName={`apply-font-${FontPickerID.BUTTONS}`}
        />
      </div>
    </StylingFormLayout>
  );
}
