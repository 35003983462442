import { isEmpty, isNumber, isUndefined, omit, omitBy } from 'lodash';
import { UpsertJourneyForm } from '../forms';

export const omitFormKeys = (values: UpsertJourneyForm): Partial<UpsertJourneyForm> => {
  const filteredValues = omitBy(
    {
      ...values,
      questions: values.questions?.map((question) => ({
        text: question.text || null,
        title: question.title,
        type: question.type,
        condition:
          question.condition?.map((condition) => omitBy(omit({ ...condition, value: condition.value || null }, ['key']), isUndefined)) || null,
        answers:
          question.answers?.map((answer) => ({
            text: answer.text,
            weightExpressions: answer?.weightStatements
              ? answer.weightStatements?.map((weightStatement) => ({
                weight: weightStatement.weight ? Number(weightStatement.weight) : 0,
                condition:
                    weightStatement.condition?.map((condition) => omitBy(omit({ ...condition, value: condition.value || null }, ['key']), isUndefined)) || null,
                expressions: weightStatement.expressions
                  ? weightStatement.expressions.map((expression) => ({
                    value: expression?.value,
                    attribute: expression?.attribute,
                    includeType: expression?.includeType,
                    comparisonType: expression.comparisonType,
                    condition: expression.condition || null
                  }))
                  : []
              }))
              : [],
            imageID: answer.imageID || null
          })) || []
      })),
      recommendationsTitle: values.recommendationsTitle || null,
      recommendationsText: values.recommendationsText || null,
      recommendationsCtaButtonText: values.recommendationsCtaButtonText || null,
      products: values.products?.map((product) => ({
        shopifyID: product.shopifyID
      })),
      settingsUrls: values?.settingsUrls?.map((settingUrl) => ({
        content: settingUrl.content,
        type: settingUrl.type
      })),
      isAiGenerated: values?.isAiGenerated || false,
      shouldUseShopStyling: values?.shouldUseShopStyling || false
    },
    (value) => isEmpty(value) && !isNumber(value)
  );

  return { ...filteredValues, isActive: values.isActive };
};
