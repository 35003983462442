import { Select, SelectProps } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export interface FormSelectProps<T = FieldValues> extends SelectProps {
  name: Path<T>;
  control: Control<T>;
}
export function FormSelect<T = FieldValues>({ name, control, ...restProps }: FormSelectProps<T>): ReactElement {
  const { field } = useController({ control, name });

  return (
    <Select
      onChange={field.onChange}
      {...restProps}
    />
  );
}
