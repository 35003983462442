import { Expose } from 'class-transformer';

export class QuizGeneratorProduct {
  @Expose()
  public title: string;

  @Expose({ name: 'product_type' })
  public productType?: string;

  constructor(product: Partial<QuizGeneratorProduct>) {
    Object.assign(this, product);
  }
}
