import React, { ReactElement } from 'react';
import './units-input.scss';

interface UnitsInputProps {
  children: React.ReactNode;
  units: string;
}

export function UnitsInput({ children, units }: UnitsInputProps): ReactElement {
  return (
    <div className='units-input'>
      {children}
      <p className='units-input-units'>{units}</p>
    </div>
  );
}
