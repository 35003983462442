import { Card, RangeSlider } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { Control, useController } from 'react-hook-form';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { useTranslation } from '@shared/i18n';
import { journeyConfig } from '@shared/journey/config';

interface RecommendationsRangeProps {
  control: Control<UpsertJourneyForm>;
}

export function RecommendationsRange({ control }: RecommendationsRangeProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.RECOMMENDATIONS_RANGE');

  const { field } = useController({ control, name: 'recommendationsCount' });
  const handleChange = (value: number): void => {
    if (value === 0) {
      field.onChange(1);
    } else {
      field.onChange(value);
    }
  };

  return (
    <Card>
      <Card.Section>
        <RangeSlider
          label={translate('TEXT_RECOMMENDATIONS_NUMBER', { value: field.value })}
          value={field.value}
          onChange={handleChange}
          prefix={<p>{journeyConfig.recommendationsCountMin}</p>}
          suffix={<p>{journeyConfig.recommendationsCountMax}</p>}
          step={1}
          min={0}
          max={journeyConfig.recommendationsCountMax}
          output
        />
      </Card.Section>
    </Card>
  );
}
