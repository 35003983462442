import { createReducer } from '@reduxjs/toolkit';
import { baseEntityStoreReducer, baseListedInitialState } from '@shared/base-listed-entity-store';
import { ProductsPagination } from '@shared/product';
import { journeySurveyActions } from './actions';
import { JourneySurveyState } from './state';

const initialState: JourneySurveyState = {
  ...baseListedInitialState,
  productsPagination: new ProductsPagination()
};

export const journeySurveyReducer = createReducer(initialState, (builder) => baseEntityStoreReducer(initialState, journeySurveyActions, builder)
  .addCase(journeySurveyActions.changeProductsSearchQuery, (state, { payload }) => {
    state.filters = {
      ...state.filters,
      title: payload.query ? payload.query : undefined
    };
  })
  .addCase(journeySurveyActions.loadProductsSuccess, (state, { payload: { products, ...pagination } }) => {
    state.isLoading = false;
    state.isRefreshing = false;
    state.itemIDs = state.productsPagination.hasNextPage
      ? state.itemIDs.concat(products.map((item) => item.id))
      : products.map((item) => item.id);
    state.productsPagination = pagination;
  }));
