import { PlanID } from '@shared/plan/models/enums';

export const subscriptionConfig: {
  [type in PlanID]: {
    price: number;
    recommendations: number;
    pricePerRecommendation: number;
  };
} = {
  1: {
    price: 19,
    recommendations: 50,
    pricePerRecommendation: 0
  },
  2: {
    price: 99,
    recommendations: 1000,
    pricePerRecommendation: 0
  },
  3: {
    price: 299,
    pricePerRecommendation: 0,
    recommendations: 1000
  },
  4: {
    recommendations: 50,
    pricePerRecommendation: 0,
    price: 0
  },
  5: {
    recommendations: 100,
    pricePerRecommendation: 0.05,
    price: 4.99
  },
  6: {
    recommendations: 1000,
    pricePerRecommendation: 0.04,
    price: 19
  },
  7: {
    recommendations: 1000,
    pricePerRecommendation: 0.01,
    price: 299
  }
};
