import { Expose, Type } from 'class-transformer';
import { QuizGeneratorProduct } from './quiz-generator-product';

export class QuizGeneratorRequest {
  @Expose()
  public shop: string;

  @Expose()
  public quizName?: string;

  @Expose()
  @Type(() => QuizGeneratorProduct)
  public products?: Array<QuizGeneratorProduct>;

  constructor(request?: Partial<QuizGeneratorRequest>) {
    Object.assign(this, request);
  }
}
