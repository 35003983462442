import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { authService } from '@shared/auth/service';
import { AuthActions, AuthSelectors } from '@shared/auth/store';
import { AppActions, storeRef } from '@store';

export async function refreshTokenInterceptor(error: AxiosError): Promise<AxiosRequestConfig> {
  if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
    const state = storeRef.getState();
    const isAuthenticated = AuthSelectors.isAuthenticated(state);

    if (isAuthenticated) {
      return lastValueFrom(
        authService.refreshToken().pipe(
          map(({ token }) => {
            storeRef.dispatch(AuthActions.saveToken({ token }));
            error.config.headers.Authorization = `Bearer ${token}`;

            return axios.request(error.config);
          }),
          catchError(() => {
            storeRef.dispatch(AppActions.noop());

            return throwError(() => error);
          })
        )
      );
    }
  }

  return Promise.reject(error);
}
