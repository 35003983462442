import { TextField, TextFieldProps } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { FormWarning } from '@shared/form-warning';

export interface FormInputProps<T = FieldValues> {
  name: Path<T>;
  control: Control<T>;
}

export function FormInput<T = FieldValues>({
  control,
  name,
  value,
  ...restProps
}: FormInputProps<T> & TextFieldProps): ReactElement {
  const { field, fieldState } = useController({ control, name });

  return (
    <TextField
      onBlur={field.onBlur}
      value={value !== undefined ? value : (field.value as string)}
      onChange={field.onChange}
      helpText={<FormWarning>{fieldState.error?.message}</FormWarning>}
      {...restProps}
    />
  );
}
