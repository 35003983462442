import { Expose } from 'class-transformer';
import { AnalyticsTimescale } from '../enums';

export class AnalyticsRequest {
  @Expose()
  public timescale: AnalyticsTimescale;

  constructor(request?: Partial<AnalyticsRequest>) {
    Object.assign(this, request);
  }
}
