import { Button } from '@shopify/polaris';
import React, { ReactElement, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from '@shared/i18n';
import { WeightExpressionString } from '../weight-expression-string';
import { WeightStatementFormProps, WeightStatementFrom } from '../weight-statement-form';
import './weight-statement-layout.scss';

type WeightStatementLayoutProps = Omit<WeightStatementFormProps, 'onFinishEditing' | 'onCancel'>;

export function WeightStatementLayout(props: WeightStatementLayoutProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_STATEMENT_LAYOUT');

  const { field: expressionsField } = useController({
    control: props.control,
    name: `questions.${props.questionIndex}.answers.${props.answerIndex}.weightStatements.${props.statementIndex}.expressions`
  });

  const { field: weightField } = useController({
    control: props.control,
    name: `questions.${props.questionIndex}.answers.${props.answerIndex}.weightStatements.${props.statementIndex}.weight`
  });

  const [isEditing, setIsEditing] = useState(!expressionsField.value[0]?.value);

  const toggleEditing = (): void => {
    setIsEditing(!isEditing);
  };

  return (
    <div className='weight-statement-layout'>
      {isEditing ? (
        <WeightStatementFrom
          {...props}
          onFinishEditing={toggleEditing}
        />
      ) : (
        <div className='weight-statement-layout-preview'>
          <div className='weight-statement-layout-preview-content'>
            <div className='weight-statement-layout-preview-header'>
              <p className='text-bold'>{translate('TEXT_STATEMENT', { number: props.statementIndex + 1 })}</p>
              {translate('TEXT_WEIGHT_100', { weight: weightField?.value })}
            </div>
            <WeightExpressionString weightExpressions={expressionsField.value} />
          </div>
          <div>
            <Button onClick={toggleEditing}>{translate('BUTTON_EDIT')}</Button>
          </div>
        </div>
      )}
    </div>
  );
}
