import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiService } from '@shared/api';
import { Media } from './models';

class MediaService {
  public create(formData: FormData): Observable<Media> {
    return apiService.post<Media>('/media', formData).pipe(map((response) => plainToClass(Media, response)));
  }

  public delete(id: number): Observable<void> {
    return apiService.delete<void>(`/media/${id}`);
  }
}

export const mediaService = new MediaService();
