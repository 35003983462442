import { Card } from '@shopify/polaris';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { CardHeader } from '@app/main/journey/shared/components/card-header';
import './statistic-card.scss';

interface StatisticCardProps {
  value: string | number;
  title: string;
  containerClassName?: string;
}

export function StatisticCard({ title, value, containerClassName }: StatisticCardProps): ReactElement {
  return (
    <Card>
      <Card.Section>
        <div className={clsx('statistic-card', containerClassName)}>
          <CardHeader title={title} />
          <div className={clsx('statistic-card-value', 'text-huge', 'text-bold')}>{value}</div>
        </div>
      </Card.Section>
    </Card>
  );
}
