import { EntityService } from '../base-entity/service';
import { Tag } from './models';

export class TagService extends EntityService<Tag> {
  constructor() {
    super({
      endpoint: 'plugin/facade/product-tags',
      entityName: 'tag'
    });
  }
}

export const tagsService = new TagService();
