import { values } from 'lodash';
import { nanoid } from 'nanoid';
import * as Yup from 'yup';
import { fontPickerConfig } from '@shared/font-picker';
import { i18n } from '@shared/i18n';
import {
  conditionType,
  questionType,
  TabPosition,
  tabPosition,
  urlType,
  weightExpressionComparisonType
} from '@shared/journey';
import { journeyConfig } from '@shared/journey/config';
import { FontWeight } from '@shared/journey/enums';
import { Answer } from '@shared/journey/models/answer';
import { Question } from '@shared/journey/models/question';
import { SettingUrl } from '@shared/journey/models/setting-url';
import { includeType } from '@shared/journey/types/include-type';
import { weightExpressionAttribute } from '@shared/journey/types/weight-attribute';
import { weightingExpressionCondition } from '@shared/journey/types/weighting-expression-condition';
import { FormProduct } from '@shared/product';
import { variables } from '@shared/styles';

interface FormQuestion extends Omit<Question, 'answers'> {
  answers: Array<Omit<Answer, 'image'>>;
}

export class UpsertJourneyForm {
  public name: string;
  public introductionTitle?: string;
  public introductionText: string;
  public isActive: boolean;
  public questions: Array<FormQuestion>;
  public products: Array<FormProduct>;
  public recommendationsCount: number;
  public recommendationsTitle: string;
  public recommendationsText?: string;
  public recommendationsCtaButtonText?: string;

  public themeTextFont?: string;
  public themeTitlesFont?: string;
  public themeButtonsFont?: string;

  public engagementTabColor?: string;
  public engagementTabTextColor?: string;
  public engagementTabPosition?: TabPosition;
  public engagementTabBorderRadius?: string;
  public engagementTabFont?: string;
  public engagementTabFontWeight?: FontWeight;
  public engagementTabFontSize?: string;
  public engagementTabText?: string;
  public engagementTabBubbleText?: string;
  public engagementTabBubbleFont?: string;
  public engagementTabBubbleFontWeight?: string;
  public engagementTabBubbleFontSize?: string;
  public engagementTabBubbleCancelText?: string;
  public engagementTabBubbleConfirmText?: string;
  public engagementTabBubbleButtonTextColor?: string;
  public engagementTabBubbleButtonBackgroundColor?: string;
  public engagementTabBubbleBorderColor?: string;

  public surveyTitleColor?: string;
  public surveyTitleFontWeight?: FontWeight;
  public surveyTitleFontSize?: string;

  public surveyBodyTextColor?: string;
  public surveyBodyBackgroundColor?: string;
  public surveyBodyFontSize?: string;
  public surveyBodyFontWeight?: FontWeight;

  public answerBackgroundColor?: string;
  public answerTextColor?: string;
  public answerBorderColor?: string;
  public answerFontSize?: string;
  public answerFontWeight?: FontWeight;
  public answerBorderRadius?: string;

  public buttonBackgroundColor?: string;
  public buttonBackgroundHoverColor?: string;
  public buttonTextColor?: string;
  public buttonFontSize?: string;
  public buttonFontWeight?: FontWeight;
  public buttonBorderRadius?: string;
  public nextButtonText?: string;
  public previousButtonText?: string;
  public restartButtonText?: string;
  public settingsUrls?: Array<SettingUrl>;
  public isAiGenerated?: boolean;
  public shouldUseShopStyling?: boolean;

  public static get validationSchema(): Yup.SchemaOf<UpsertJourneyForm> {
    const conditionSchema = Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.mixed().oneOf(values(conditionType)),
          value: Yup.string().nullable(),
          key: Yup.string()
        })
      )
      .nullable();

    const weightExpressionSchema = Yup.array().of(
      Yup.object().shape({
        attribute: Yup.mixed()
          .oneOf([weightExpressionAttribute])
          .required(i18n.t('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_EXPRESSION_FIELD.TEXT_VALIDATION_TYPE_ERROR')),
        value: Yup.mixed().when('attribute', {
          is: 'product_price',
          then: Yup.string()
            .matches(
              /^\d+(?:[.,]\d{1,2})?$/,
              i18n.t('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_EXPRESSION_FIELD.TEXT_PRICE_POSITIVE')
            )
            .required('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_EXPRESSION_FIELD.TEXT_PRICE_VALIDATION'),
          otherwise: Yup.string().required(
            i18n.t('MAIN.JOURNEY.SHARED.SURVEY.WEIGHT_EXPRESSION_FIELD.TEXT_VALIDATION_VALUE_ERROR')
          )
        }),
        comparisonType: Yup.mixed().oneOf([weightExpressionComparisonType]),
        includeType: Yup.mixed().oneOf([includeType]),
        condition: Yup.mixed()
          .oneOf([...weightingExpressionCondition, null])
          .nullable(),
        key: Yup.string()
      })
    );

    const weightStatementsSchema = Yup.array().of(
      Yup.object().shape({
        weight: Yup.number()
          .nullable()
          .typeError(i18n.t('MAIN.JOURNEY.SHARED.SURVEY.ANSWER_WEIGHT_FIELD.TEXT_VALIDATION_NUMERIC'))
          .nullable()
          .min(0)
          .max(100, i18n.t('MAIN.JOURNEY.SHARED.SURVEY.STATEMENT_WEIGHT.TEXT_VALIDATION_MAX_VALUE')),
        expressions: weightExpressionSchema,
        condition: conditionSchema,
        key: Yup.string()
      })
    );

    const answersSchema = Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required(i18n.t('COMMON.TEXT_VALIDATION_REQUIRED_FIELD')),
        weightStatements: weightStatementsSchema,
        imageID: Yup.number().nullable(),
        idWithinJourney: Yup.string()
      })
    );

    const validateMaxFontSize = (fontSize: string): boolean => (fontSize ? Number(fontSize) < 60 : true);

    return Yup.object().shape({
      name: Yup.string().required(i18n.t('COMMON.TEXT_VALIDATION_REQUIRED_FIELD')),
      introductionTitle: Yup.string(),
      introductionText: Yup.string().nullable(),
      isActive: Yup.bool(),
      isAiGenerated: Yup.bool().nullable(),
      shouldUseShopStyling: Yup.bool().nullable(),
      questions: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required(i18n.t('COMMON.TEXT_VALIDATION_REQUIRED_FIELD')),
          text: Yup.string().nullable(),
          type: Yup.mixed().oneOf([questionType]),
          answers: answersSchema,
          idWithinJourney: Yup.string(),
          condition: conditionSchema
        })
      ),
      products: Yup.array().of(
        Yup.object().shape({ image: Yup.string().nullable(), title: Yup.string(), shopifyID: Yup.number() })
      ),
      recommendationsTitle: Yup.string().nullable(),
      recommendationsText: Yup.string().nullable(),
      recommendationsCtaButtonText: Yup.string().nullable(),
      recommendationsCount: Yup.number().required(),
      themeTextFont: Yup.string().nullable(),
      themeTitlesFont: Yup.string().nullable(),
      themeButtonsFont: Yup.string().nullable(),

      engagementTabColor: Yup.string().nullable(),
      engagementTabTextColor: Yup.string().nullable(),
      engagementTabPosition: Yup.mixed().oneOf([tabPosition])
        .nullable(),
      engagementTabBorderRadius: Yup.string().nullable(),
      engagementTabFont: Yup.string().nullable(),
      engagementTabFontWeight: Yup.mixed().oneOf(values(FontWeight))
        .nullable(),
      engagementTabFontSize: Yup.string()
        .test('Font size test', i18n.t('COMMON.TEXT_FONT_SIZE_VALIDATION'), validateMaxFontSize)
        .nullable(),
      engagementTabText: Yup.string().nullable(),
      engagementTabBubbleText: Yup.string().nullable(),
      engagementTabBubbleFont: Yup.string().nullable(),
      engagementTabBubbleFontWeight: Yup.mixed().oneOf(values(FontWeight)),
      engagementTabBubbleFontSize: Yup.string()
        .test('Font size test', i18n.t('COMMON.TEXT_FONT_SIZE_VALIDATION'), validateMaxFontSize)
        .nullable(),
      engagementTabBubbleCancelText: Yup.string().nullable(),
      engagementTabBubbleConfirmText: Yup.string().nullable(),
      engagementTabBubbleButtonTextColor: Yup.string().nullable(),
      engagementTabBubbleButtonBackgroundColor: Yup.string().nullable(),
      engagementTabBubbleBorderColor: Yup.string().nullable(),

      surveyTitleColor: Yup.string().nullable(),
      surveyTitleFontWeight: Yup.mixed().oneOf(values(FontWeight)),
      surveyTitleFontSize: Yup.string()
        .test('Font size test', i18n.t('COMMON.TEXT_FONT_SIZE_VALIDATION'), validateMaxFontSize)
        .nullable(),

      surveyBodyTextColor: Yup.string().nullable(),
      surveyBodyFontWeight: Yup.mixed().oneOf(values(FontWeight)),
      surveyBodyBackgroundColor: Yup.string().nullable(),
      answerBorderRadius: Yup.string().nullable(),
      surveyBodyFontSize: Yup.string()
        .test('Font size test', i18n.t('COMMON.TEXT_FONT_SIZE_VALIDATION'), validateMaxFontSize)
        .nullable(),

      answerBackgroundColor: Yup.string().nullable(),
      answerTextColor: Yup.string().nullable(),
      answerBorderColor: Yup.string().nullable(),
      answerFontSize: Yup.string()
        .test('Font size test', i18n.t('COMMON.TEXT_FONT_SIZE_VALIDATION'), validateMaxFontSize)
        .nullable(),
      answerFontWeight: Yup.mixed().oneOf(values(FontWeight))
        .nullable(),

      buttonBackgroundColor: Yup.string().nullable(),
      buttonBackgroundHoverColor: Yup.string().nullable(),
      buttonTextColor: Yup.string().nullable(),
      buttonFontSize: Yup.string()
        .test('Font size test', i18n.t('COMMON.TEXT_FONT_SIZE_VALIDATION'), validateMaxFontSize)
        .nullable(),
      buttonFontWeight: Yup.mixed().oneOf(values(FontWeight)),
      buttonBorderRadius: Yup.string().nullable(),
      nextButtonText: Yup.string().nullable(),
      previousButtonText: Yup.string().nullable(),
      restartButtonText: Yup.string().nullable(),
      settingsUrls: Yup.array().of(
        Yup.object().shape({
          content: Yup.string().when('settingsUrls.type', {
            is: urlType[0],
            then: Yup.string().url(),
            otherwise: Yup.string()
          }),
          type: Yup.mixed().oneOf([urlType]),
          key: Yup.string()
        })
      ),
      condition: conditionSchema
    });
  }

  constructor(journey?: Partial<UpsertJourneyForm>) {
    this.name = journey?.name;
    this.isActive = journey ? journey.isActive : false;
    this.introductionText = journey?.introductionText || '';
    this.introductionTitle = journey?.introductionTitle || '';
    this.questions = journey?.questions || [];
    this.products = journey?.products || [];
    this.isAiGenerated = journey?.isAiGenerated || false;
    this.shouldUseShopStyling = journey?.shouldUseShopStyling || false;
    this.recommendationsTitle =
      journey?.recommendationsTitle ||
      i18n.t('MAIN.JOURNEY.SHARED.SURVEY.DEFAULT_RECOMMENDATIONS.TEXT_RECOMMENDATIONS_DEFAULT');
    this.recommendationsText = journey?.recommendationsText || '';
    this.recommendationsCtaButtonText =
      journey?.recommendationsCtaButtonText ||
      i18n.t('MAIN.JOURNEY.SHARED.SURVEY.DEFAULT_RECOMMENDATIONS.TEXT_CALL_TO_ACTION_DEFAULT');
    this.recommendationsCount = journey?.recommendationsCount || journeyConfig.defaultValues.recommendationsCount;

    this.themeTextFont = journey?.themeTextFont || fontPickerConfig.defaultFont;
    this.themeTitlesFont = journey?.themeTitlesFont || fontPickerConfig.defaultFont;
    this.themeButtonsFont = journey?.themeButtonsFont || fontPickerConfig.defaultFont;

    this.engagementTabColor = journey?.engagementTabColor || variables.colors.dark;
    this.engagementTabTextColor = journey?.engagementTabTextColor || variables.colors.white;
    this.engagementTabPosition = journey?.engagementTabPosition || 'right';
    this.engagementTabBorderRadius = journey?.engagementTabBorderRadius || variables.borderRadius.default;
    this.engagementTabFont = journey?.engagementTabFont || fontPickerConfig.defaultFont;
    this.engagementTabFontWeight = journey?.engagementTabFontWeight || journeyConfig.defaultValues.fontWeight;
    this.engagementTabFontSize = journey?.engagementTabFontSize || variables.fontSizes.default;
    this.engagementTabText =
      journey?.engagementTabText || i18n.t('MAIN.JOURNEY.SHARED.STYLING.ENGAGEMENT_CARD.TEXT_HELP_ME_CHOOSE');
    this.engagementTabBubbleText =
      journey?.engagementTabBubbleText || i18n.t('MAIN.JOURNEY.SHARED.STYLING.ENGAGEMENT_CARD.TEXT_NEED_HELP');
    this.engagementTabBubbleFont = journey?.engagementTabBubbleFont || fontPickerConfig.defaultFont;
    this.engagementTabBubbleFontWeight =
      journey?.engagementTabBubbleFontWeight || journeyConfig.defaultValues.fontWeight;
    this.engagementTabBubbleFontSize = journey?.engagementTabBubbleFontSize || variables.fontSizes.default;
    this.engagementTabBubbleCancelText =
      journey?.engagementTabBubbleCancelText || i18n.t('MAIN.JOURNEY.SHARED.STYLING.ENGAGEMENT_CARD.BUTTON_NO_THANKS');
    this.engagementTabBubbleConfirmText =
      journey?.engagementTabBubbleConfirmText || i18n.t('MAIN.JOURNEY.SHARED.STYLING.ENGAGEMENT_CARD.BUTTON_SURE');
    this.engagementTabBubbleButtonTextColor = journey?.engagementTabBubbleButtonTextColor || variables.colors.white;
    this.engagementTabBubbleButtonBackgroundColor =
      journey?.engagementTabBubbleButtonBackgroundColor || variables.colors.dark;
    this.engagementTabBubbleBorderColor = journey?.engagementTabBubbleBorderColor || variables.colors.dark;

    this.surveyBodyBackgroundColor = journey?.surveyBodyBackgroundColor || variables.colors.white;
    this.surveyTitleColor = journey?.surveyTitleColor || variables.colors.dark;
    this.surveyBodyTextColor = journey?.surveyBodyTextColor || variables.colors.dark;
    this.surveyBodyFontSize = journey?.surveyBodyFontSize || variables.fontSizes.small;
    this.surveyBodyFontWeight = journey?.surveyBodyFontWeight || journeyConfig.defaultValues.fontWeight;
    this.surveyTitleFontWeight = journey?.surveyTitleFontWeight || journeyConfig.defaultValues.fontWeight;
    this.surveyTitleFontSize = journey?.surveyTitleFontSize || variables.fontSizes.large;

    this.answerBackgroundColor = journey?.answerBackgroundColor || variables.colors.white;
    this.answerTextColor = journey?.answerTextColor || variables.colors.dark;
    this.answerBorderColor = journey?.answerBorderColor || variables.colors.darkBlue;
    this.answerFontSize = journey?.answerFontSize || variables.fontSizes.default;
    this.answerFontWeight = journey?.answerFontWeight || journeyConfig.defaultValues.fontWeight;
    this.answerBorderRadius = journey?.answerBorderRadius || variables.borderRadius.default;

    this.buttonBackgroundColor = journey?.buttonBackgroundColor || variables.colors.darkBlue;
    this.buttonBackgroundHoverColor = journey?.buttonBackgroundHoverColor || variables.colors.blue;
    this.buttonTextColor = journey?.buttonTextColor || variables.colors.white;
    this.buttonFontSize = journey?.buttonFontSize || variables.fontSizes.default;
    this.buttonFontWeight = journey?.buttonFontWeight || journeyConfig.defaultValues.fontWeight;
    this.buttonBorderRadius = journey?.buttonBorderRadius || variables.borderRadius.default;
    this.nextButtonText = journey?.nextButtonText || i18n.t('SHARED.CUSTOM_CARD.BUTTON_NEXT');
    this.previousButtonText = journey?.previousButtonText || i18n.t('SHARED.CUSTOM_CARD.BUTTON_PREVIOUS');
    this.restartButtonText =
      journey?.restartButtonText || i18n.t('MAIN.JOURNEY.SHARED.STYLING.SURVEY_FORM.TEXT_START_AGAIN');
    this.questions =
      journey?.questions?.map((question) => {
        return {
          ...question,
          text: question.text || '',
          answers: question?.answers?.map((answer) => ({
            ...answer,
            weightStatements:
              answer?.weightStatements?.map((weightStatement) => ({
                weight: weightStatement.weight,
                key: nanoid(),
                condition: weightStatement?.condition?.map((condition) => ({ ...condition, key: nanoid() })) || null,
                expressions: weightStatement.expressions.map((expression) => ({
                  ...expression,
                  attribute: expression.attribute || 'product_tag',
                  includeType: expression.includeType || 'include',
                  comparisonType: expression.comparisonType || 'contains',
                  key: nanoid()
                }))
              })) || [],
            idWithinJourney: answer.idWithinJourney || nanoid()
          })),
          idWithinJourney: question?.idWithinJourney || nanoid(),
          condition: question?.condition?.map((condition) => ({ ...condition, key: nanoid() })) || null
        };
      }) || [];
    this.settingsUrls = journey?.settingsUrls?.map((settingUrl) => ({
      ...settingUrl,
      key: nanoid()
    }));
  }
}
