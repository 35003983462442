export const configuration = {
  language: {
    default: 'en'
  },
  api: {
    refreshTokenEndpoint: 'auth/refresh'
  },
  supportEmail: 'support@helpmechoose.ai',
  links: {
    answerWeightInfo: 'https://www.helpmechoose.ai/knowledge-base/product-answer-weightings-how-it-works',
    knowledgeBase: 'https://www.helpmechoose.ai/knowledge-base-home',
    bookCall:
      'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ16atSvehvdYhJ4MAIiW9Z7OhS715w4j2UDnIuS0gxgv1zzRT1OCD4hR194Vc_ZWcjw_v125GUg',
    explainerVideo: 'https://www.youtube.com/embed/4FnTtyb4qBE'
  }
};
