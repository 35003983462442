import { BaseListedEntityFacade } from '@shared/base-listed-entity-store/facade';
import { Journey } from '@shared/journey';
import { overviewPageActions, OverviewPageState } from './shared/store';
import { overviewPageSelectors } from './shared/store/selectors';

class OverviewPageFacade extends BaseListedEntityFacade<
  OverviewPageState,
  Journey,
  typeof overviewPageActions,
  typeof overviewPageSelectors
> {
  constructor() {
    super(overviewPageActions, overviewPageSelectors);
  }

  public loadJourneys(page?: number): void {
    this.loadItems(page);
  }
}

export const overviewPageFacade = new OverviewPageFacade();
