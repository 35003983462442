import { BaseListedEntityFacade } from '@shared/base-listed-entity-store/facade';
import { Tag } from './models';
import { TagsState, tagsActions } from './store';
import { tagsSelectors } from './store/selectors';

class TagsFacade extends BaseListedEntityFacade<TagsState, Tag, typeof tagsActions, typeof tagsSelectors> {
  constructor() {
    super(tagsActions, tagsSelectors);
  }
}

export const tagsFacade = new TagsFacade();
