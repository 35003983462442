import { Link } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { i18n } from '@shared/i18n';
import './google-fonts-label.scss';

interface GoogleFontsLabelProps {
  label: string;
}

export function GoogleFontsLabel({ label }: GoogleFontsLabelProps): ReactElement {
  return (
    <div className='google-fonts-label'>
      {label}
      <span className='google-fonts-label-link'>
        {i18n.t('COMMON.TEXT_TAKEN_FROM')}{' '}
        <Link url={process.env.REACT_APP_GOOGLE_FONTS}>{i18n.t('COMMON.LINK_GOOGLE_FONTS')}</Link>
      </span>
    </div>
  );
}
