import { TextField } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import './color-picker.scss';

interface ColorPickerProps {
  label: string;
  onChange: (value: string) => void;
  color: string;
  onBlur?: () => void;
}

export function ColorPicker({ color, onChange, label, onBlur }: ColorPickerProps): ReactElement {
  const handleSelectColor = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value.toUpperCase());
  };

  const handleTypeColor = (value: string): void => {
    onChange(value.toUpperCase());
  };

  return (
    <div className='color-picker'>
      <input
        value={color}
        className='color-picker-input'
        onChange={handleSelectColor}
        type='color'
      />
      <div className='color-picker-field'>
        {label}
        <div className='color-picker-input-field'>
          <TextField
            onBlur={onBlur}
            onChange={handleTypeColor}
            value={color}
            label=''
            autoComplete='off'
          />
        </div>
      </div>
    </div>
  );
}
