import { Button, Modal } from '@shopify/polaris';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@shared/i18n';
import { ProfileSelectors } from '@shared/profile';

interface RecommendationsLimitModal {
  onUpgradePlanPress: () => void;
}

export function RecommendationsLimitModal({ onUpgradePlanPress }: RecommendationsLimitModal): ReactElement {
  const translate = useTranslation('SHARED.RECOMMENDATIONS_LIMIT');

  const [isModalShown, setIsModalShown] = useState(false);
  const profile = useSelector(ProfileSelectors.profile);

  const toggleModal = (): void => {
    setIsModalShown(!isModalShown);
  };

  const handleUpgradePlanClick = (): void => {
    toggleModal();
    onUpgradePlanPress();
  };

  useEffect(() => {
    if (profile?.isRecommendationLimitReached) {
      toggleModal();
    }
  }, [profile?.isRecommendationLimitReached]);

  return (
    <Modal
      title={translate('TEXT_RECOMMENDATIONS_LIMIT_TITLE')}
      open={isModalShown}
      onClose={toggleModal}>
      <Modal.Section>
        <Button
          onClick={handleUpgradePlanClick}
          primary>
          {translate('BUTTON_UPGRADE_YOUR_PLAN')}
        </Button>
      </Modal.Section>
    </Modal>
  );
}
