import { defineAction } from '@store/utils';
import { Analytics } from '../models/analytics';
import { AnalyticsRequest } from '../models/analytics-request';

export class AnalyticsActions {
  public static loadAnalytics = defineAction<AnalyticsRequest>(
    '[Analytics] Load Analytics'
  );

  public static loadAnalyticsSuccess = defineAction<Analytics>(
    '[Analytics] Load Analytics Success'
  );

  public static loadAnalyticsFailure = defineAction(
    '[Analytics] Load Analytics Failure'
  );
}
