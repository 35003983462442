import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@store';
import { AnalyticsState } from './state';

const selectFeature = (state: AppState): AnalyticsState => state.analytics;

export class AnalyticsSelectors {
  public static isLoading = createSelector(
    selectFeature,
    (state) => state.isLoading
  );

  public static analytics = createSelector(
    selectFeature,
    (state) => state.analytics
  );
}
