import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@store';
import { JourneyPageState } from './state';

const selectFeature = (state: AppState): JourneyPageState => state.journeyPage;

export class JourneyPageSelectors {
  public static isCreating = createSelector(
    selectFeature,
    (state) => state.isCreating
  );

  public static updatingJourneyID = createSelector(
    selectFeature,
    (state) => state.updatingJourneyID
  );
}
