import { Expose } from 'class-transformer';
import { ProfileRelations } from '../types';

export class ProfileRequest {
  @Expose({ name: 'with' })
  public relations?: Array<ProfileRelations>;

  constructor(request: Partial<ProfileRequest> = {}) {
    Object.assign(this, request);
  }
}
