import { Font } from '@samuelmeuli/font-manager';
import Picker from 'font-picker-react';
import React, { ReactElement } from 'react';
import { GoogleFontsLabel } from '@shared/google-fonts-label';
import { useTranslation } from '@shared/i18n';
import { fontPickerConfig } from './config';
import './font-picker.scss';

export const defaultFont = 'Roboto';

export enum FontPickerID {
  TEXT = 'text',
  TITLES = 'titles',
  BUTTONS = 'buttons',
  ENGAGEMENT = 'engagement',
  BUBBLE = 'bubble'
}

interface FontPickerProps {
  activeFontFamily: string;
  onChange: (fontFamily: string) => void;
  pickerId: FontPickerID;
  label?: string | React.ReactNode;
}

export function FontPicker({ activeFontFamily, onChange, pickerId, label }: FontPickerProps): ReactElement {
  const translate = useTranslation('SHARED.FONT_PICKER');
  const handleFontChange = (font: Font): void => {
    onChange(font.family);
  };

  return (
    <div className='font-picker'>
      {label || <GoogleFontsLabel label={translate('TEXT_FONT')} />}
      <Picker
        variants={fontPickerConfig.variants}
        onChange={handleFontChange}
        activeFontFamily={activeFontFamily || fontPickerConfig.defaultFont}
        pickerId={pickerId}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      />
    </div>
  );
}
