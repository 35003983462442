import { TextField, Card, ResourceList, Modal } from '@shopify/polaris';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ResourceListItem } from '@app/main/journey/shared/components/resource-list-item';
import { journeySurveyFacade } from '@app/main/journey/survey/facade';
import { useTranslation } from '@shared/i18n';
import { Product } from '@shared/product';
import { filterRegExp } from '@shared/regexp';
import { journeySurveyActions } from '../../store';

interface ProductsSelectProps {
  onToggleModal: () => void;
  isOpen: boolean;
  selectedProducts: Array<string>;
  onSelectProducts: (productIDs: Array<string>) => void;
}

export function ProductsSelect({
  onToggleModal,
  isOpen,
  selectedProducts,
  onSelectProducts
}: ProductsSelectProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.RECOMMENDATIONS_SELECT');
  const dispatch = useDispatch();
  const resourceName = {
    singular: translate('TEXT_PRODUCT'),
    plural: translate('TEXT_PRODUCTS')
  };
  const { items, isLoading, productsPagination } = journeySurveyFacade;

  const [searchQuery, setSearchQuery] = useState<string>();

  const filteredItems = items.filter((product) => searchQuery ? product.title.match(filterRegExp(searchQuery)) : true);

  const renderItem = (item: Product): React.ReactNode => (
    <ResourceListItem
      id={item.shopifyID}
      title={item.title}
      image={item.image}
    />
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(journeySurveyActions.resetFilter());
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isLoading && productsPagination.hasNextPage) {
      journeySurveyFacade.changeProductsSearchQuery(searchQuery);
    }
  }, [searchQuery]);

  return (
    <Modal
      open={isOpen}
      onClose={onToggleModal}
      title={translate('TEXT_SELECT_PRODUCTS')}
      primaryAction={{
        content: translate('BUTTON_ADD_PRODUCTS'),
        onAction: onToggleModal
      }}>
      <Modal.Section>
        <Card>
          <ResourceList<Product>
            loading={isLoading}
            selectable
            onSelectionChange={onSelectProducts}
            selectedItems={selectedProducts}
            resourceName={resourceName}
            items={filteredItems}
            renderItem={renderItem}
            filterControl={
              <TextField
                label=''
                value={searchQuery}
                onChange={setSearchQuery}
                autoComplete='off'
                labelHidden
              />
            }
          />
        </Card>
      </Modal.Section>
    </Modal>
  );
}
