import { Button, Card, Collapsible, FormLayout, Select } from '@shopify/polaris';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import React, { Fragment, ReactElement, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-down.svg';
import { CollapseButton } from '@shared/collapse-button';
import { FormInput } from '@shared/form-input/form-input';
import { useTranslation } from '@shared/i18n';
import { Answer, QuestionType } from '@shared/journey';
import { AppTooltip } from '@shared/tooltip/tooltip';
import { CardHeader } from '../../../../shared/components/card-header';
import { UpsertJourneyForm } from '../../../../shared/forms';
import { AnswerCard } from '../answer-card';
import './question-card.scss';
import { QuestionCondition } from '../question-condition';

interface QuestionCardProps {
  control: Control<UpsertJourneyForm>;
  index: number;
  onToggleIsDraggable: (isDraggable: boolean) => void;
  isDragging?: boolean;
}

export function QuestionCard({ control, index, onToggleIsDraggable, isDragging }: QuestionCardProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.QUESTION_CARD');

  const [isCollapsed, setIsCollapsed] = useState(false);

  const { field: typeField } = useController({ control, name: `questions.${index}.type` });
  const { field: questionsField } = useController({ control, name: 'questions' });
  const { field: answersField } = useController({ control, name: `questions.${index}.answers` });

  const handleSelectChange = (value: QuestionType): void => {
    if (value === 'text') {
      answersField.onChange([new Answer({ idWithinJourney: nanoid() })]);
    }
    if (typeField.value === 'text') {
      answersField.onChange([]);
    }
    typeField.onChange(value);
  };

  const handleDeleteQuestion = (): void => {
    questionsField.onChange(questionsField.value.filter((_, i) => i !== index));
  };

  const handleAddAnswer = (): void => {
    answersField.onChange((answersField.value || []).concat(new Answer({ idWithinJourney: nanoid() })));
  };

  const toggleCollapse = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  const options: Array<{ label: string; value: QuestionType }> = [
    { label: translate('TEXT_TEXT'), value: 'text' },
    { label: translate('TEXT_RADIO'), value: 'radio' },
    { label: translate('TEXT_CHECKBOX'), value: 'checkbox' }
  ];

  const handleDragAreaEnter = (): void => {
    onToggleIsDraggable(true);
  };

  const handleDragAreaLeave = (): void => {
    onToggleIsDraggable(false);
  };

  return (
    <Card>
      <Card.Header
        title={
          <div className='question-card-header'>
            <CardHeader
              title={translate('TEXT_QUESTION', { number: index + 1 })}
              iconContent={
                <CollapseButton
                  isRotate={isCollapsed}
                  onClick={toggleCollapse}
                />
              }
              actionsContent={
                <Fragment>
                  <Button
                    onClick={handleDeleteQuestion}
                    destructive
                    plain
                    removeUnderline>
                    {translate('BUTTON_DELETE')}
                  </Button>
                  <div
                    onMouseLeave={handleDragAreaLeave}
                    onMouseEnter={handleDragAreaEnter}
                    className='question-card-drag-handle'>
                    <AppTooltip content={translate('TEXT_DRAGGABLE_TOOLTIP')}>
                      <div className={clsx(isDragging && 'question-card-rotate')}>
                        <ArrowDown />
                      </div>
                    </AppTooltip>
                  </div>
                </Fragment>
              }
            />
          </div>
        }
      />
      <Collapsible
        id='basic-collapsible'
        expandOnPrint={true}
        open={!isCollapsed}>
        <Card.Section>
          <div className='question-card-form'>
            <FormLayout>
              <FormInput
                value={questionsField.value[index].title}
                control={control}
                name={`questions.${index}.title`}
                label={translate('TEXT_QUESTION_TITLE')}
                autoComplete='off'
              />
              <FormInput
                value={questionsField.value[index].text}
                control={control}
                name={`questions.${index}.text`}
                label={translate('TEXT_SUBTEXT')}
                autoComplete='off'
              />
              <Select
                label={translate('TEXT_QUESTION_TYPE')}
                options={options}
                onChange={handleSelectChange}
                value={questionsField.value[index].type}
              />
            </FormLayout>
          </div>
        </Card.Section>
        {typeField.value === 'text' ? (
          <div className='question-card-answer-input'>
            <FormInput
              value={answersField.value?.[0].text}
              control={control}
              name={`questions.${index}.answers.0.text`}
              label={translate('TEXT_PLACEHOLDER_TEXT')}
              autoComplete='off'
            />
          </div>
        ) : (
          <Fragment>
            {answersField.value?.map((answer, answerIndex) => (
              <AnswerCard
                questionIndex={index}
                answerIndex={answerIndex}
                control={control}
                key={answer.idWithinJourney}
              />
            ))}
            <Card.Section>
              <div className='question-card-add-answer'>
                <Button onClick={handleAddAnswer}>{translate('BUTTON_ADD_ANSWER')}</Button>
              </div>
            </Card.Section>
          </Fragment>
        )}
        {index !== 0 && (
          <QuestionCondition
            control={control}
            index={index}
          />
        )}
      </Collapsible>
    </Card>
  );
}
