import { Card } from '@shopify/polaris';
import React, { PropsWithChildren, ReactElement } from 'react';
import { CardHeader } from '@app/main/journey/shared/components/card-header';
import './info-card.scss';

interface InfoCardProps {
  title: string;
}

export function InfoCard({ title, children }: PropsWithChildren<InfoCardProps>): ReactElement {
  return (
    <Card>
      <Card.Section>
        <div className='info-card-title'>
          <CardHeader title={title} />
        </div>
        {children}
      </Card.Section>
    </Card>
  );
}
