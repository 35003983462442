import { Heading, Layout, Page } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { configuration } from '@configuration';
import { UpgradePlanResult, upgradePlanResult } from '@shared/billing';
import { useTranslation } from '@shared/i18n';
import { PlanID } from '@shared/plan/models/enums';
import { ProfileSelectors } from '@shared/profile';
import { BillingCard } from './shared/components/billing-card';
import { BillingSubscription } from './shared/components/billing-subscription';
import './billing.scss';

export function Billing(): ReactElement {
  const translate = useTranslation('MAIN.BILLING');

  const profile = useSelector(ProfileSelectors.profile);
  const navigate = useNavigate();
  const { result } = useParams<{ result: UpgradePlanResult }>();
  const handleGoBack = (): void => {
    navigate('/');
  };

  return (
    <Page
      title={translate('TEXT_BILLING')}
      breadcrumbs={profile?.plan ? [{ onAction: handleGoBack }] : undefined}
      primaryAction={
        !profile?.plan && !!profile ? (
          <p className='text-bold'>{translate('TEXT_PLAN_EXPIRED')}</p>
        ) : (
          <BillingSubscription />
        )
      }>
      <div className='billing page-layout'>
        <Layout>
          <Layout.Section fullWidth>
            <p className='text-largest text-center'>{translate('TEXT_TURN_MORE_VISITORS')}</p>
            <p className='text-largest text-center'>
              <span className='text-bold'>{translate('TEXT_14_DAYS')}</span> {translate('TEXT_WITH_ALL')}
            </p>
          </Layout.Section>
          {upgradePlanResult.includes(result) && (
            <Layout.Section fullWidth>
              {result === 'error' ? (
                <p className='billing-upgrade-error text-bold text-center'>{translate('TEXT_ERROR')}</p>
              ) : (
                <p className='billing-upgrade-success text-bold text-center'>{translate('TEXT_SUCCESS')}</p>
              )}
            </Layout.Section>
          )}
          <Layout.Section fullWidth>
            <div className='billing-cards'>
              <BillingCard planID={PlanID.FREE} />
              <BillingCard planID={PlanID.STARTER} />
              <BillingCard planID={PlanID.GROWTH} />
              <BillingCard planID={PlanID.SHOPIFY_PLUS} />
            </div>
          </Layout.Section>
          <Layout.Section fullWidth>
            <div className='billing-info'>
              <Heading>{translate('TEXT_CONTACT')}</Heading>
              <p className='billing-info-description'>
                {translate('TEXT_IN_CASE')}
                <span className='billing-info-email text-bold'> {configuration.supportEmail}</span>
              </p>
            </div>
          </Layout.Section>
        </Layout>
      </div>
    </Page>
  );
}
