import { BaseListedEntityActions } from '@shared/base-listed-entity-store';
import { TagsState } from './state';

class TagsActions extends BaseListedEntityActions<TagsState> {
  constructor() {
    super(
      'Tags'
    );
  }
}

export const tagsActions = new TagsActions();
