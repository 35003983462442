import { journeyPageReducer } from '@app/main/journey/shared/store';
import { journeySurveyReducer } from '@app/main/journey/survey/shared/store';
import { overviewPageReducer } from '@app/main/overview/shared/store';
import { analyticsReducer } from '@shared/analytics/store';
import { authReducer } from '@shared/auth/store';
import { entityStoreReducer } from '@shared/base-entity/store/reducer';
import { profileReducer } from '@shared/profile';
import { tagsReducer } from '@shared/tags/store';

export const rootReducer = {
  entityStore: entityStoreReducer,
  profile: profileReducer,
  overviewPage: overviewPageReducer,
  journeyPage: journeyPageReducer,
  auth: authReducer,
  journeySurvey: journeySurveyReducer,
  analytics: analyticsReducer,
  tags: tagsReducer
};
