import { FormLayout } from '@shopify/polaris';
import React, { Fragment, ReactElement, useMemo } from 'react';
import { Control } from 'react-hook-form';
import { UpsertJourneyForm } from '@app/main/journey/shared/forms';
import { FontPicker, FontPickerID } from '@shared/font-picker';
import { FormColorPicker } from '@shared/form-color-picker';
import { FormInput } from '@shared/form-input';
import { useTranslation } from '@shared/i18n';
import { CollapsedSection } from '../collapsed-section';
import { CollapsedStylingCard } from '../collapsed-styling-card';
import { ColorInput, StylingFormTextInput } from '../styling-card-form';

interface EngagementFormBubbleProps {
  control: Control<UpsertJourneyForm>;
  bubbleFont: string;
  onChangeBubbleFont: (font: string) => void;
}

export function EngagementFormBubble({
  control,
  bubbleFont,
  onChangeBubbleFont
}: EngagementFormBubbleProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.STYLING.ENGAGEMENT_FORM_BUBBLE');

  const bubbleFontPickerInput = useMemo(() => {
    return (
      <FontPicker
        key={FontPickerID.BUBBLE}
        onChange={onChangeBubbleFont}
        activeFontFamily={bubbleFont}
        pickerId={FontPickerID.BUBBLE}
      />
    );
  }, [bubbleFont]);

  const bubbleTextInputs: Array<StylingFormTextInput> = [
    { name: 'engagementTabBubbleCancelText', label: translate('TEXT_CANCEL_BUTTON_TEXT') },
    { name: 'engagementTabBubbleConfirmText', label: translate('TEXT_CONFIRM_BUTTON_TEXT') }
  ];

  const bubbleColorInputs: Array<ColorInput> = [
    { label: translate('TEXT_BUBBLE_BUTTONS_COLOR'), name: 'engagementTabBubbleButtonBackgroundColor' },
    { label: translate('TEXT_BUBBLE_BUTTONS_TEXT_COLOR'), name: 'engagementTabBubbleButtonTextColor' }
  ];

  return (
    <Fragment>
      <CollapsedStylingCard
        fontSizePath='engagementTabBubbleFontSize'
        fontWeightPath='engagementTabBubbleFontWeight'
        extraFields={[bubbleFontPickerInput]}
        control={control}
        title={translate('TEXT_BUBBLE_TITLE_STYLES')}
      />
      <CollapsedSection title={translate('TEXT_BUBBLE_BUTTONS')}>
        <FormLayout>
          {bubbleColorInputs?.map((input) => (
            <FormColorPicker
              key={input.label}
              control={control}
              label={input.label}
              name={input.name}
            />
          ))}
          {bubbleTextInputs?.map(({ name, label }) => (
            <FormInput
              key={name}
              label={label}
              name={name}
              control={control}
              autoComplete='off'
            />
          ))}
        </FormLayout>
      </CollapsedSection>
    </Fragment>
  );
}
