import { Card, Heading, Layout } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as UpgradePlanImage } from '@assets/images/upgrade-plan.svg';
import { useTranslation } from '@shared/i18n';
import { PlanID } from '@shared/plan/models/enums';
import { ProfileSelectors } from '@shared/profile';
import './upgrade-plan.scss';

export function UpgradePlan(): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.UPGRADE_PLAN');
  const profile = useSelector(ProfileSelectors.profile);

  const navigate = useNavigate();
  const handleUpgradePress = (): void => {
    navigate('./billing');
  };

  if (profile?.plan?.id === PlanID.SHOPIFY_PLUS) {
    return null;
  }

  return (
    <Layout>
      <Layout.Section>
        <div className='upgrade-plan-card'>
          <Card
            title={
              <div className='upgrade-plan-image-extra-space'>
                <Heading>{translate('TEXT_UPGRADE_PLAN')}</Heading>
              </div>
            }
            footerActionAlignment='left'
            secondaryFooterActions={[{ content: translate('TEXT_UPGRADE_NOW'), onAction: handleUpgradePress }]}>
            <div className='upgrade-plan-image-extra-space'>
              <Card.Section>{translate('TEXT_ENGAGE')}</Card.Section>
            </div>
            <div className='upgrade-plan-img'>
              <UpgradePlanImage />
            </div>
          </Card>
        </div>
      </Layout.Section>
    </Layout>
  );
}
