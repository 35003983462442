import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { CustomStylesProps } from '@shared/custom-card';
import { transformFontSize } from '@shared/journey/utils/transform-font-size';
import './page-title.scss';

export interface PageTitleProps {
  children: React.ReactNode;
  isMain?: boolean;
}

export function PageTitle({
  children,
  isMain,
  fontWeight,
  ...styles
}: PageTitleProps & CustomStylesProps): ReactElement {
  return (
    <h1
      style={{
        ...styles,
        fontSize: `${transformFontSize(styles?.fontSize)}px`,
        lineHeight: `${transformFontSize(styles?.fontSize)}px`
      }}
      className={clsx('page-title', `font-${fontWeight}`, isMain && 'main')}>
      {children}
    </h1>
  );
}
