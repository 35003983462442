import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as CaretDown } from '@assets/icons/caret-down-minor.svg';
import magicWandImage from '@assets/images/magic-wand.png';
import { configuration } from '@configuration';
import { useTranslation } from '@shared/i18n';
import { overviewPageActions } from '../../store';
import './welcome-card.scss';

export function WelcomeCard(): ReactElement {
  const translate = useTranslation('MAIN.OVERVIEW.SHARED.WELCOME_CARD');
  const dispatch = useDispatch();

  const handleOpenQuizGenerator = (): void => {
    dispatch(overviewPageActions.setIsQuizGeneratorActive({ isQuizGeneratorActive: true }));
  };

  const handleBookCallPress = (): void => {
    window.open(configuration.links.bookCall, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className='welcome-card'>
      <div className='welcome-card-ai-section'>
        <div className='welcome-card-title'>
          <p className='welcome-card-welcome'>{translate('TEXT_WELCOME_TO')}</p>
          <p className={clsx('text-bold', 'text-extra-large', 'text-white')}>{translate('TEXT_HELP_ME_CHOOSE_AI')}</p>
        </div>
        <div className='welcome-card-create-quiz'>
          <p className={clsx('text-larger')}>
            {translate('TEXT_GETTING_STARTED')}
            <br />
            {translate('TEXT_BUILD_QUIZ')}
            <br />
            {translate('TEXT_LET_AI')}
          </p>
          <button
            onClick={handleOpenQuizGenerator}
            className={clsx('welcome-card-create-quiz-button', 'welcome-card-button')}>
            {translate('BUTTON_CREATE_QUIZ')}
          </button>
          <p className='text-small'>
            {translate('TEXT_ALWAYS_FELL_FREE')}{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              className={clsx('welcome-card-get-tin-touch-link', 'text-bold')}
              href={configuration.links.bookCall}>
              {translate('TEXT_GET_IN_TOUCH')}
            </a>{' '}
            {translate('TEXT_WITH_US')} <br /> {translate('TEXT_IF_NEED_HAND')}
          </p>
        </div>
        <img
          className='welcome-card-magic-wand-image'
          src={magicWandImage}
        />
      </div>
      <div className='welcome-card-book-section'>
        <p className='text-small'>{translate('TEXT_BOOK_CALL_DESCRIPTION')}</p>
        <button
          onClick={handleBookCallPress}
          className={clsx('welcome-card-book-button', 'welcome-card-button')}>
          {translate('BUTTON_BOOK_CALL')}
        </button>
        <p className='text-small'>{translate('TEXT_KNOWLEDGE_BASE_DESCRIPTION')}</p>
        <div className={clsx('text-small', 'text-bold', 'welcome-card-knowledge')}>
          <CaretDown />
          <a
            target='_blank'
            rel='noopener noreferrer'
            className='welcome-card-knowledge-link'
            href={configuration.links.knowledgeBase}>
            {translate('TEXT_VISIT_KNOWLEDGE_BASE')}
          </a>
        </div>
      </div>
    </div>
  );
}
