import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import { CustomStylesProps } from '@shared/custom-card';
import './custom-button.scss';
import { transformFontSize } from '@shared/journey/utils/transform-font-size';

interface CustomButtonProps {
  text: string;
  onClick?: () => void;
  isOutline?: boolean;
  fullWidth?: boolean;
  isDisabled?: boolean;
  textClassName?: string;
  backgroundColorHover?: string;
}

export function CustomButton({
  text,
  onClick,
  isOutline,
  borderRadius,
  fontSize,
  fullWidth,
  isDisabled,
  textClassName,
  backgroundColorHover,
  backgroundColor,
  fontWeight,
  ...restProps
}: CustomButtonProps & CustomStylesProps): ReactElement {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  return (
    <button
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        borderRadius: `${borderRadius}px`,
        fontSize: `${transformFontSize(fontSize)}px`,
        backgroundColor: isHovering && backgroundColorHover ? backgroundColorHover : backgroundColor,
        ...restProps
      }}
      className={clsx(
        'custom-button',
        isOutline && 'outline-button',
        fullWidth && 'full-width',
        isDisabled && 'disabled',
        `font-${fontWeight}`,
        textClassName
      )}>
      {text}
    </button>
  );
}
