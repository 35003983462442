import { Card, EmptyState, Layout, ResourceList } from '@shopify/polaris';
import { uniqBy } from 'lodash';
import React, { Fragment, ReactElement, useState } from 'react';
import { CopyBlock, github } from 'react-code-blocks';
import { Control, useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { overviewPageActions } from '@app/main/overview/shared/store';
import image from '@assets/images/settings-empty-state.svg';
import { DeleteJourneyModal } from '@shared/delete-journey-modal';
import { useTranslation } from '@shared/i18n';
import { Journey } from '@shared/journey';
import { SettingUrl } from '@shared/journey/models/setting-url';
import { ProfileSelectors } from '@shared/profile';
import { storeRef } from '@store';
import { ResourceListItem } from '../shared/components/resource-list-item';
import { UpsertJourneyForm } from '../shared/forms';
import { AddSettingUrlModal } from './shared/components/add-setting-url-modal';
import { getCodeSnippet } from './shared/utils';
import './settings.scss';

export function JourneySettings(): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SETTINGS');

  const { id } = useParams();
  const navigate = useNavigate();
  const profile = useSelector(ProfileSelectors.profile);

  const [isShowAddUrlModal, setIsShowAddUrlModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const { control, editableJourney } = useOutletContext<{
    control: Control<UpsertJourneyForm>;
    editableJourney: Journey;
  }>();

  const { field: settingsUrlsField } = useController({ control, name: 'settingsUrls' });

  const toggleAddUrlModal = (): void => {
    setIsShowAddUrlModal(!isShowAddUrlModal);
  };

  const toggleDeleteModal = (): void => {
    setIsShowDeleteModal(!isShowDeleteModal);
  };

  const handleDeleteJourneySuccess = (): void => {
    navigate('/');
  };

  const handleDeleteJourney = (): void => {
    storeRef.dispatch(overviewPageActions.deleteItem({ id: Number(id), onSuccess: handleDeleteJourneySuccess }));
  };

  const handleAddSettingUrl = (settingUrl: SettingUrl): void => {
    settingsUrlsField.onChange(uniqBy((settingsUrlsField.value || []).concat(new SettingUrl(settingUrl)), 'content'));
  };

  const handleRemoveSettingUrl = (key: string | number): void => {
    settingsUrlsField.onChange(settingsUrlsField.value.filter((settingUrl) => settingUrl.key !== key));
  };

  const addPageAction = {
    content: translate('TEXT_ADD_PAGE_COLLECTION'),
    onAction: toggleAddUrlModal
  };

  const renderItem = (item: SettingUrl): React.ReactNode => (
    <ResourceListItem
      onRemoveClick={handleRemoveSettingUrl}
      id={item.key}
      title={item.content}
      subtitle={translate(item.type === 'page' ? 'TEXT_INDIVIDUAL_PAGE' : 'TEXT_COLLECTION')}
    />
  );

  return (
    <div className='settings'>
      <Layout>
        <Layout.AnnotatedSection
          title={translate('TEXT_URLS')}
          description={translate('TEXT_URLS_SUBTITLE')}>
          <div className='settings-urls'>
            <Card
              footerActionAlignment='left'
              secondaryFooterActions={settingsUrlsField.value?.length && [addPageAction]}
              sectioned>
              {settingsUrlsField.value?.length ? (
                <ResourceList<SettingUrl>
                  items={settingsUrlsField.value}
                  renderItem={renderItem}
                />
              ) : (
                <EmptyState
                  imageContained={true}
                  secondaryAction={addPageAction}
                  image={image}
                />
              )}
            </Card>
          </div>
        </Layout.AnnotatedSection>
        {!isNaN(Number(id)) && (
          <Fragment>
            <Layout.AnnotatedSection
              title={translate('TEXT_SCRIPT_TAG_AND_KEY')}
              description={translate('TEXT_COPY')}>
              <div className='settings-script'>
                <Card>
                  <Card.Section>
                    <CopyBlock
                      theme={github}
                      text={getCodeSnippet(profile?.name, editableJourney?.uniqueID)}
                      language='javascript'
                      showLineNumbers={false}
                      wrapLines
                    />
                  </Card.Section>
                </Card>
              </div>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title={translate('TEXT_DELETE')}
              description={translate('TEXT_COMPLETELY_DELETE')}>
              <div className='settings-delete'>
                <Card
                  sectioned
                  primaryFooterAction={{
                    content: translate('TEXT_DELETE'),
                    destructive: true,
                    onAction: toggleDeleteModal
                  }}
                  footerActionAlignment='left'
                  title={translate('TEXT_DELETE_THIS')}
                />
              </div>
            </Layout.AnnotatedSection>
          </Fragment>
        )}
      </Layout>
      <AddSettingUrlModal
        onAddSettingUrl={handleAddSettingUrl}
        onCloseModal={toggleAddUrlModal}
        isActive={isShowAddUrlModal}
      />
      <DeleteJourneyModal
        onCloseModal={toggleDeleteModal}
        isActive={isShowDeleteModal}
        onDeleteClick={handleDeleteJourney}
      />
    </div>
  );
}
