import { isAnyOf } from '@reduxjs/toolkit';
import { plainToInstance } from 'class-transformer';
import { catchError, filter, of, switchMap, debounceTime, map } from 'rxjs';
import { baseListedEntityEpics } from '@shared/base-listed-entity-store/store/epics';
import { ProductsPaginationResponse } from '@shared/product/models/products-pagination-response';
import { productService } from '@shared/product/service';
import { Epics } from '@store/types';
import { journeySurveyActions } from './actions';
import { journeySurveySelectors } from './selectors';

export const journeySurveyEpics: Epics = {
  ...baseListedEntityEpics(journeySurveyActions, journeySurveySelectors, productService),

  loadItems: (action$, state$) => action$.pipe(
    filter(isAnyOf(journeySurveyActions.loadItems, journeySurveyActions.refreshItems)),
    switchMap((action) => productService
      .searchProducts({
        ...(journeySurveySelectors.filters(state$.value) || {}),
        ...('payload' in action ? action.payload : {})
      })
      .pipe(
        map((response) => journeySurveyActions.loadProductsSuccess(plainToInstance(ProductsPaginationResponse, response))),
        catchError((error) => of(journeySurveyActions.loadItemsFailure(error)))
      ))
  ),

  changeProductsSearchQuery: (action$) => action$.pipe(
    filter(journeySurveyActions.changeProductsSearchQuery.match),
    debounceTime(400),
    map(() => journeySurveyActions.loadItems({ page: 1 }))
  )
};
