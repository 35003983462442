import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';

export class ProductsPaginationRequest extends PaginationRequest {
  @Expose({ name: 'after_cursor' })
  public afterCursor?: string;

  @Expose({ name: 'title' })
  public title?: string;

  constructor(params?: Partial<ProductsPaginationRequest>) {
    super(params);
    Object.assign(this, params);
  }
}
