import { Button, ButtonGroup } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { AnalyticsTimescale } from '@shared/analytics/enums';
import { useTranslation } from '@shared/i18n';

export interface PeriodTabsProps {
  onPeriodClick?: (timescale: AnalyticsTimescale) => void;
  activePeriod?: AnalyticsTimescale;
}

export function PeriodTabs({ activePeriod, onPeriodClick }: PeriodTabsProps): ReactElement {
  const translate = useTranslation('SHARED.ANALYTICS.PERIOD_TABS');

  const handleWeekClick = (): void => {
    onPeriodClick(AnalyticsTimescale.WEEK);
  };

  const handleMonthClick = (): void => {
    onPeriodClick(AnalyticsTimescale.MONTH);
  };

  const handleThreeMonthClick = (): void => {
    onPeriodClick(AnalyticsTimescale.THREE_MONTH);
  };

  return (
    <ButtonGroup segmented>
      <Button
        onClick={handleWeekClick}
        pressed={activePeriod === AnalyticsTimescale.WEEK}>
        {translate('BUTTON_WEEK')}
      </Button>
      <Button
        onClick={handleMonthClick}
        pressed={activePeriod === AnalyticsTimescale.MONTH}>
        {translate('BUTTON_MONTH')}
      </Button>
      <Button
        onClick={handleThreeMonthClick}
        pressed={activePeriod === AnalyticsTimescale.THREE_MONTH}>
        {translate('BUTTON_THREE_MONTH')}
      </Button>
    </ButtonGroup>
  );
}
