import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@shared/i18n';
import { ProfileSelectors } from '@shared/profile';
import './recommendations-limit-banner.scss';

interface RecommendationsLimitBannerProps {
  onUpgradeClick: () => void;
}

export function RecommendationsLimitBanner({ onUpgradeClick }: RecommendationsLimitBannerProps): ReactElement {
  const translate = useTranslation('SHARED.RECOMMENDATIONS_LIMIT_BANNER');

  const isCloseToRecommendationLimit = useSelector(ProfileSelectors.isCloseToRecommendationLimit);

  return isCloseToRecommendationLimit ? (
    <div className='recommendations-limit-banner'>
      {translate('TEXT_CLOSE_TO_LIMIT')}
      <button
        onClick={onUpgradeClick}
        className='button-primary-plain'>
        {translate('BUTTON_UPGRADE')}
      </button>
      {translate('TEXT_INCREASE')}
    </div>
  ) : null;
}
