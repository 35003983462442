import { PlanID } from './models/enums';

export const planConfig = {
  [PlanID.STARTER]: {
    grade: 1
  },
  [PlanID.GROWTH]: {
    grade: 2
  },
  [PlanID.SHOPIFY_PLUS]: {
    grade: 3
  },
  [PlanID.FREE]: {
    grade: 0
  },
  [PlanID.ARCHIVED_STARTER]: {
    grade: 0
  },
  [PlanID.ARCHIVED_GROWTH]: {
    grade: 0
  },
  [PlanID.ARCHIVED_PRO]: {
    grade: 0
  }
};
