import clsx from 'clsx';
import { findIndex, flattenDeep } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from '@shared/i18n';
import { Answer, Question } from '@shared/journey';
import { Condition } from '@shared/journey/models/condition';
import './condition-string.scss';

interface ConditionStringProps {
  conditionArray: Array<Condition>;
  questions: Array<Question>;
}

export function ConditionString({ conditionArray, questions }: ConditionStringProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.SURVEY.QUESTION_CONDITION.CONDITION_STRING');

  const findIndexOfQuestion = (idWithinJourney: string): number => {
    return findIndex(questions, { idWithinJourney });
  };

  const answers = useMemo(() => flattenDeep(questions.map((question) => question.answers)), [questions]);

  const findAnswer = (idWithinJourney: string): Answer => answers.find((answer) => answer.idWithinJourney === idWithinJourney);

  return (
    <p className='condition-string'>
      {conditionArray?.map((condition, index) => {
        switch (condition.type) {
          case 'answerTo': {
            const indexOfQuestion = findIndexOfQuestion(condition.value);

            return (
              <span
                key={condition.key}
                className={clsx(index === 0 && 'condition-string-capitalize', 'condition-string-answer-text')}>
                {translate('TEXT_THE_ANSWER_TO')}
                {indexOfQuestion !== -1 ? indexOfQuestion + 1 : ''}{' '}
                <span className='text-bold'>{questions?.[indexOfQuestion]?.title}</span>
              </span>
            );
          }
          case 'has':
          case 'doesNotHave':
            return (
              <span key={condition.key}>
                {' '}
                {translate(condition.type === 'has' ? 'TEXT_HAS' : 'TEXT_DOES_NOT_HAVE')}{' '}
                <span className='text-bold'>{findAnswer(condition?.value)?.text}</span>
              </span>
            );
          case 'and':
            return <span key={condition.key}>{` ${translate('TEXT_AND')} `}</span>;
          case 'or':
            return <span key={condition.key}>{` ${translate('TEXT_OR')} `}</span>;
          default:
            return null;
        }
      })}
    </p>
  );
}
