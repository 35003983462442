import { Expose, Type } from 'class-transformer';
import { Media } from '@shared/media';
import { WeightStatement } from './weight-statement';

export class Answer {
  @Expose()
  public text: string;

  @Expose({ name: 'weight_statements' })
  @Type(() => WeightStatement)
  public weightStatements?: Array<WeightStatement>;

  @Expose({ name: 'id_within_journey' })
  public idWithinJourney: string;

  @Expose({ name: 'image_id' })
  public imageID?: number;

  @Expose({ toClassOnly: true })
  public image?: Media;

  constructor(answer?: Partial<Answer>) {
    Object.assign(this, answer);
  }
}
