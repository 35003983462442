import { FormLayout } from '@shopify/polaris';
import React, { ReactElement } from 'react';
import { Control, Path } from 'react-hook-form';
import { FormColorPicker } from '@shared/form-color-picker';
import { FormInput } from '@shared/form-input';
import { FormSelect } from '@shared/form-select';
import { i18n, useTranslation } from '@shared/i18n';
import { FontWeight } from '@shared/journey/enums';
import { UnitsInput } from '@shared/units-input';
import { UpsertJourneyForm } from '../../../../shared/forms';
import './styling-card-form.scss';

export interface ColorInput {
  label: string;
  name: Path<UpsertJourneyForm>;
}

export interface StylingFormTextInput {
  name: Path<UpsertJourneyForm>;
  label: string;
}

export interface StylingCardSectionProps {
  colorInputs?: Array<ColorInput>;
  control: Control<UpsertJourneyForm>;
  extraFields?: Array<ReactElement>;
  fontSizePath?: Path<UpsertJourneyForm>;
  fontWeightPath?: Path<UpsertJourneyForm>;
  borderRadiusPath?: Path<UpsertJourneyForm>;
  textInputs?: Array<StylingFormTextInput>;
}

export function StylingCardForm({
  colorInputs,
  control,
  extraFields,
  fontSizePath,
  fontWeightPath,
  borderRadiusPath,
  textInputs
}: StylingCardSectionProps): ReactElement {
  const translate = useTranslation('MAIN.JOURNEY.SHARED.STYLING.STYLING_CARD_FORM');

  const fontWeightOptions: Array<{ label: string; value: FontWeight }> = [
    { label: translate('TEXT_THIN'), value: FontWeight.THIN },
    { label: translate('TEXT_LIGHT'), value: FontWeight.LIGHT },
    { label: translate('TEXT_REGULAR'), value: FontWeight.REGULAR },
    { label: translate('TEXT_MEDIUM'), value: FontWeight.MEDIUM },
    { label: translate('TEXT_BOLD'), value: FontWeight.BOLD }
  ];

  return (
    <FormLayout>
      {colorInputs?.map((input) => (
        <FormColorPicker
          key={input.label}
          control={control}
          label={input.label}
          name={input.name}
        />
      ))}
      {extraFields?.map((field) => field)}
      <div className='inline-form-group'>
        {!!fontSizePath && (
          <UnitsInput units={i18n.t('COMMON.TEXT_PX')}>
            <FormInput<UpsertJourneyForm>
              min={1}
              autoComplete='off'
              type='number'
              name={fontSizePath}
              control={control}
              label={translate('TEXT_FONT_SIZE')}
            />
          </UnitsInput>
        )}
        {!!fontWeightPath && (
          <div className='col-50'>
            <FormSelect<UpsertJourneyForm>
              label={translate('TEXT_FONT_WEIGHT')}
              options={fontWeightOptions}
              control={control}
              name={fontWeightPath}
            />
          </div>
        )}
      </div>
      {!!borderRadiusPath && (
        <div className='styling-card-form-border-input'>
          <UnitsInput units={i18n.t('COMMON.TEXT_PX')}>
            <FormInput<UpsertJourneyForm>
              min={1}
              autoComplete='off'
              type='number'
              name={borderRadiusPath}
              control={control}
              label={translate('TEXT_BORDER_RADIUS')}
            />
          </UnitsInput>
        </div>
      )}
      {textInputs?.map(({ name, label }) => (
        <FormInput
          key={name}
          label={label}
          name={name}
          control={control}
          autoComplete='off'
        />
      ))}
    </FormLayout>
  );
}
