import { createReducer } from '@reduxjs/toolkit';
import { baseEntityStoreReducer, baseListedInitialState } from '@shared/base-listed-entity-store';
import { overviewPageActions } from './actions';
import { OverviewPageState } from './state';

const initialState: OverviewPageState = {
  ...baseListedInitialState,
  isQuizGeneratorActive: false,
  filters: { perPage: 50 } //TODO clarify with customer how many journeys to show and how to load more.
};

export const overviewPageReducer = createReducer(initialState, (builder) => baseEntityStoreReducer(initialState, overviewPageActions, builder).addCase(
  overviewPageActions.setIsQuizGeneratorActive,
  (state, { payload }) => {
    state.isQuizGeneratorActive = payload.isQuizGeneratorActive;
  }
));
