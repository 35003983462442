import { Card } from '@shopify/polaris';
import { DateTime } from 'luxon';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { formatDate } from '@shared/format-date';
import './analytics-chart.scss';

interface ChartDataItem {
  value: number;
  date: DateTime;
}

interface AxisLabelProps {
  x?: number;
  y?: number;
  payload?: { value: number; index: number };
  index?: number;
}

interface AnalyticsChartProps {
  data: Array<ChartDataItem>;
  title: string;
}

export function AnalyticsChart({ data, title }: AnalyticsChartProps): ReactElement {
  const xAxisInterval = useMemo(() => {
    return Math.floor(data?.length / 4);
  }, [data?.length]);

  const getFormattedDate = useCallback((value: ChartDataItem): string => formatDate(value.date), [data]);

  const LabelX = ({ x, y, payload }: AxisLabelProps): ReactElement => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          textAnchor={payload.index === 0 ? 'start' : payload.index === data?.length - 1 ? 'end' : 'middle'}
          x={0}
          y={0}>
          {payload.value}
        </text>
      </g>
    );
  };

  const LabelY = ({ x, y, payload, index }: AxisLabelProps): ReactElement => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          textAnchor='end'
          alignmentBaseline={index === 0 ? 'after-edge' : 'central'}
          x={0}
          y={0}>
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>): ReactElement => {
    if (active && payload && payload.length) {
      return (
        <div className='analytics-chart-custom-tooltip'>
          {label}
          <div className='analytics-chart-custom-tooltip-value'>
            <div className='analytics-chart-custom-tooltip-value-badge' />
            {payload[0].value}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Card>
      <Card.Section>
        <p className='analytics-chart-title'>{title}</p>
        <ResponsiveContainer
          width='100%'
          height={289}>
          <LineChart
            data={data}
            margin={{
              top: 40,
              right: 0,
              left: -10,
              bottom: 0
            }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              interval={xAxisInterval <= 1 ? 0 : xAxisInterval}
              axisLine={false}
              tickLine={false}
              tickMargin={20}
              dataKey={getFormattedDate}
              tick={<LabelX />}
            />
            <YAxis
              tickCount={3}
              tickMargin={10}
              axisLine={false}
              tickLine={false}
              tick={<LabelY />}
            />
            <Tooltip content={CustomTooltip} />
            <Line
              dot={false}
              strokeWidth={2}
              dataKey='value'
              stroke='#008060'
            />
          </LineChart>
        </ResponsiveContainer>
      </Card.Section>
    </Card>
  );
}
